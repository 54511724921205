import {tv} from 'tailwind-variants'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/design-system/foundations/Select'
import {cn} from '~/design-system/utils'
import HgIcon from '../HgIcon'

export type HgDropdownOption = {
  value: string
  label: string
}

type HgDropdownProps = {
  options: HgDropdownOption[]
  onChange: (value: string) => void
  value?: string
  position?: 'popper' | 'item-aligned'
  classNames?: {
    trigger?: string
    content?: string
    items?: string
    value?: string
  }
  onOptionKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
  autoFocusOnClose?: boolean
}

const hgDropdownVariants = tv({
  slots: {
    content:
      'flex cursor-default justify-center overflow-hidden rounded-lg border border-solid border-border-input bg-surface-input p-s1 text-text-default arcadia-body-3',
    trigger:
      'group flex h-40 items-center gap-s2 rounded-lg border border-solid border-border-input bg-surface-input px-12 py-8 text-text-subdued outline-2 arcadia-body-3 hover:bg-surface-input-hover hover:shadow-[0px_1px_8px_0px_rgba(111,115,150,0.10)] focus-visible:bg-surface-input-active focus-visible:outline-border-focus',
  },
  variants: {
    position: {
      'popper': {
        content: 'w-[--radix-select-trigger-width] rounded-t-none border-t-0',
        trigger: 'data-[state=open]:rounded-b-none',
      },
      'item-aligned': '',
    },
  },
})

export default function HgDropdown({
  options,
  onChange,
  value,
  position,
  classNames,
  onOptionKeyDown,
  autoFocusOnClose,
}: HgDropdownProps) {
  const {trigger, content} = hgDropdownVariants({position})
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger
        icon={
          <HgIcon
            iconType="chevron-down"
            size="small"
            className="text-icon-default duration-300 ease-in-out group-data-[state=open]:rotate-180 motion-safe:transition-[transform]"
          />
        }
        className={trigger({className: classNames?.trigger})}
      >
        <SelectValue className={classNames?.value} />
      </SelectTrigger>
      <SelectContent
        className={content({className: classNames?.content})}
        position={position}
        scrollDownButtonIcon={<HgIcon iconType="chevron-down" size="small" />}
        scrollUpButtonIcon={<HgIcon iconType="chevron-up" size="small" />}
        onCloseAutoFocus={event => {
          if (!autoFocusOnClose) {
            event.preventDefault()
          }
        }}
      >
        {options.map(option => (
          <SelectItem
            indicatorIcon={
              <HgIcon
                className="absolute left-s2 inline-flex items-center"
                iconType="checkmark"
                size="small"
              />
            }
            value={option.value}
            key={option.value}
            onKeyDown={onOptionKeyDown}
            className={cn(
              'h-36 relative flex items-center rounded-lg px-s3 py-8 pl-s5 -outline-offset-2 hover:bg-surface-input-hover focus:shadow-[inset_0px_0px_0px_2px_var(--border-focus)] focus:outline-0',
              classNames?.items
            )}
          >
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
