const externalForceFrag = /* glsl */ `
precision mediump float;

uniform float u_mouseForce;     // The strength of the mouse interaction
uniform vec2 u_px;              // Size of a pixel in texture coordinates
uniform vec2 u_diff;            // The difference in mouse position between frames
varying vec2 vUv;               // Texture coordinates, varying for each pixel
uniform vec2 u_clickPosition;   // The position of the mouse click in the texture

void main() {
    vec2 force;

    // Calculate boundary scaling factors to reduce force near the edges
    float edgeDistanceX = min(vUv.x, 1.0 - vUv.x);
    float edgeDistanceY = min(vUv.y, 1.0 - vUv.y);
    float edgeScale = min(edgeDistanceX / u_px.x, edgeDistanceY / u_px.y);

    // Check if there's a click event and create a ripple effect
    if (length(u_clickPosition - vec2(-1.0)) > 0.001) {
        // Calculating ripple effect based on click position
        vec2 clickDiff = vUv - u_clickPosition;
        float distanceFromClick = length(clickDiff);
        vec2 direction = normalize(clickDiff);
        float rippleRadius = 1.0;
        float rippleStrength = length(u_px) * 0.05;
        float distanceFactor = smoothstep(0.0, rippleRadius, distanceFromClick);
        distanceFactor = 1.0 - distanceFactor;
        force = direction * distanceFactor * rippleStrength * u_mouseForce * edgeScale;
    } else {
        // Default force calculation based on mouse movement
        force.x = u_diff.x / 2.0 * u_mouseForce;
        force.y = u_diff.y / 2.0 * u_mouseForce;
        // Creating a circular area of influence around the cursor
        vec2 circle = (vUv - 0.5) * 2.0;
        float d = 1.0 - min(length(circle), 1.0);
        d *= d;
        force *= d;
    }

    // Set the fragment color to the calculated force
    gl_FragColor = vec4(force, 0.0, 1.0);
}
`

export default externalForceFrag
