import {tv, type VariantProps} from 'tailwind-variants'
import HgIcon, {type HgIconType} from '../HgIcon'

const hgListItem = tv({
  slots: {
    base: 'block',
    item: 'flex w-full flex-col items-center border-t border-border-subdued pt-s6 arcadia-subheading-5 md:flex-row-reverse md:justify-between',
  },
  variants: {
    topSpacing: {
      s0: {
        base: 'pt-s0',
      },
      s6: {
        base: 'pt-s6',
      },
      s9: {
        base: 'pt-s9',
      },
    },
  },
  defaultVariants: {
    topSpacing: 's0',
  },
})

type HgListItemVariants = VariantProps<typeof hgListItem>
type HgListItemPropsBase = HgListItemVariants & {content: React.ReactNode}
export type HgListItemProps =
  | (HgListItemPropsBase & {
      symbolIconType: HgIconType
    })
  | (HgListItemPropsBase & {symbolText: React.ReactNode})

export default function HgListItem(props: HgListItemProps) {
  const {content, topSpacing} = props
  const {base, item} = hgListItem({topSpacing})

  const renderListItemSymbol = () => {
    if ('symbolText' in props) {
      return <div className="text-text-default">{props.symbolText}</div>
    }
    return (
      <HgIcon
        size="large"
        className="h-[2rem] text-icon-default"
        iconType={props.symbolIconType || 'success'}
      />
    )
  }

  return (
    <li className={base()}>
      <div className={item()}>
        {renderListItemSymbol()}
        <div className="mt-s4 text-center text-text-subdued md:mt-0 md:text-left">
          {content}
        </div>
      </div>
    </li>
  )
}
