import {TableCell, TableRow} from '~/design-system/foundations/Table'
import {
  HgIndexTableRow,
  type HgIndexTableRowProps,
  HgTextBlock,
} from '~/design-system/hg/components'
import {type DatabaseTableRowsProps} from '../types'

export default function DatabaseTableRows({
  rows,
  lastColumnAlignment,
  variant,
  hasError,
  page,
  perPage,
}: Pick<DatabaseTableRowsProps, 'lastColumnAlignment' | 'variant' | 'hasError'> & {
  page: string
  perPage: string
  rows: HgIndexTableRowProps[]
}) {
  if (hasError) {
    throw new Error('Error rendering database table rows')
  }
  const intPage = parseInt(page)
  const intPerPage = parseInt(perPage)

  if (!rows.length) {
    return (
      <TableRow className="border-b-0">
        <TableCell>
          <HgTextBlock
            title="No matches"
            body="Try adjusting or removing filters"
            alignment="center"
            topPadding="s9"
          />
        </TableCell>
      </TableRow>
    )
  }
  const dataToDisplay =
    perPage === 'All'
      ? rows
      : rows.slice((intPage - 1) * intPerPage, intPage * intPerPage)

  return dataToDisplay.map(row => (
    <HgIndexTableRow
      key={row.id}
      lastColumnAlignment={lastColumnAlignment}
      variant={variant}
      {...row}
    />
  ))
}
