import {useId} from 'react'
import HgTextInput, {type HgTextInputProps} from '../HgTextInput'

type HgRangeInputTextInputProps = Omit<HgTextInputProps, 'label'> & {
  label?: React.ReactNode
}

export type HgRangeInputProps = {
  label: React.ReactNode
  minTextInputProps?: HgRangeInputTextInputProps
  maxTextInputProps?: HgRangeInputTextInputProps
}

export default function HgRangeInput({
  label,
  maxTextInputProps,
  minTextInputProps,
}: HgRangeInputProps) {
  const rangeLabelId = useId()

  return (
    <div className="group/container grid gap-s3 arcadia-body-3">
      <label
        className="text-text-subdued group-focus-within/container:text-text-default group-hover/container:text-text-default group-has-[:disabled]/container:text-text-disabled"
        id={rangeLabelId}
      >
        {label}
      </label>
      <div role="group" aria-labelledby={rangeLabelId} className="flex gap-s2">
        <HgTextInput
          label="From"
          placeholder="Min"
          type="number"
          min={0}
          {...minTextInputProps}
        />
        <HgTextInput
          label="To"
          placeholder="Max"
          type="number"
          {...maxTextInputProps}
        />
      </div>
    </div>
  )
}
