export type HgResultsWindowProps = {
  windowStart: number
  windowEnd: number
  resultsLength: number
}
export default function HgResultsWindow({
  windowStart: start,
  windowEnd: end,
  resultsLength: total,
}: HgResultsWindowProps) {
  if (total === 0) {
    return null
  }
  return (
    <span className="text-text-default arcadia-body-3">
      {start.toString().padStart(2, '0')}–{end.toString().padStart(2, '0')} of{' '}
      {total.toString().padStart(2, '0')}
    </span>
  )
}
