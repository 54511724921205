import {type VariantProps, tv} from 'tailwind-variants'
import {HgAspectRatio} from '~/design-system/hg/components'
import styles from './styles.module.css'

export type HgHeroCinematicProps = {
  type: 'cinematic'
  backgroundImage: React.ReactElement
  maskImage: React.ReactElement
  mobileMaskImage: React.ReactElement
}

const cinematicImageVariants = tv({
  slots: {
    container:
      'z-[2] col-start-1 col-end-auto row-start-1 row-end-auto hidden h-full min-h-lvh w-full min-w-full sm:block',
    imageWrapper:
      'h-full sm:aspect-h-5 sm:aspect-w-4 lg:aspect-h-2 lg:aspect-w-3 3xl:aspect-h-9 3xl:aspect-w-16',
    imageSlot:
      'w-full rounded-none [&_img]:h-full [&_img]:min-h-[900px] [&_img]:w-full [&_img]:min-w-full [&_img]:object-cover',
  },
  variants: {
    background: {
      true: {
        container: 'z-[3]',
        imageSlot: styles.backgroundImage,
      },
    },
    mobile: {
      true: {
        container: 'block sm:hidden',
        imageWrapper: 'aspect-h-16 aspect-w-9 h-full',
      },
    },
    fade: {
      true: {
        container:
          'opacity-1 z-[3] transition-opacity duration-500 group-hover:opacity-0',
      },
    },
  },
})

function CinematicImage({
  background,
  image,
  mobile,
  fade,
}: {
  image: React.ReactElement
} & VariantProps<typeof cinematicImageVariants>) {
  const {container, imageSlot, imageWrapper} = cinematicImageVariants({
    background,
    mobile,
    fade,
  })

  return (
    <div className={container()}>
      <HgAspectRatio
        classNames={{wrapper: imageWrapper(), assetWrapper: imageSlot()}}
      >
        {image}
      </HgAspectRatio>
    </div>
  )
}

function CinematicMaskLayer(
  props: Pick<HgHeroCinematicProps, 'maskImage' | 'backgroundImage'>
) {
  return (
    <>
      <CinematicImage image={props.maskImage} />
      <CinematicImage background image={props.backgroundImage} />
    </>
  )
}

/*
 *  Since we cannot simply target the `mask-image` CSS property because it is not animatable,
 *  we can duplicate the background image and fade it out on hover to reveal the mask effect layer.
 */
function CinematicFadeLayer(props: Pick<HgHeroCinematicProps, 'backgroundImage'>) {
  return <CinematicImage fade image={props.backgroundImage} />
}

function CinematicMobileLayer(props: Pick<HgHeroCinematicProps, 'mobileMaskImage'>) {
  return <CinematicImage mobile image={props.mobileMaskImage} />
}

export default function CinematicVariant(props: HgHeroCinematicProps) {
  return (
    <>
      <CinematicMaskLayer
        maskImage={props.maskImage}
        backgroundImage={props.backgroundImage}
      />
      <CinematicFadeLayer backgroundImage={props.backgroundImage} />
      <CinematicMobileLayer mobileMaskImage={props.mobileMaskImage} />
    </>
  )
}
