import {type PropsWithChildren} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {type OmitStrict} from 'type-zoo'
import {gridItem} from '~/design-system/foundations'

const unorderedListVariants = tv({
  base: 'list-inside text-text-default tiempos-body-2 [&_li_+_li]:mt-4 md:[&_li_+_li]:mt-8 lg:[&_li_+_li]:mt-12 [&_li_>_ul]:mt-4 md:[&_li_>_ul]:mt-8 lg:[&_li_>_ul]:mt-12',
  variants: {
    nestedLevel: {
      0: '-ms-s1 list-["⬩⠀"]',
      1: 'list-["▫⠀"] ps-[calc(var(--s3)+var(--s1))]',
      2: 'list-["-⠀"] ps-[calc((var(--s3)*2)+var(--s1))]',
    },
  },
})

type HgUnorderedListNestedLevel = NonNullable<
  VariantProps<typeof unorderedListVariants>['nestedLevel']
>

export type HgUnorderedListProps = PropsWithChildren<{
  className?: string
  nestedLevel: HgUnorderedListNestedLevel
}>

export const HgUnorderedList = ({
  children,
  className,
  nestedLevel,
}: HgUnorderedListProps) => {
  return (
    <ul className={unorderedListVariants({nestedLevel, className})}>{children}</ul>
  )
}

export const HgArticleWYSIWYGUnorderedList = ({
  nestedLevel,
  ...props
}: OmitStrict<HgUnorderedListProps, 'className'>) => {
  return (
    <HgUnorderedList
      {...props}
      nestedLevel={nestedLevel || 0}
      className={gridItem({size: 'heading'})}
    />
  )
}
