/* eslint-disable react/no-unknown-property */
import faceVert from '../shaders/face.vert'
import divergenceFrag from '../shaders/divergence.frag'
import {
  TIME_SCALE,
  type ShaderPassProps,
} from '~/design-system/hg/components/StableFluidBackground/types'
import {useFrame} from '@react-three/fiber'

export default function Divergence({cellScale, fbos}: ShaderPassProps) {
  useFrame(({gl, scene, camera}) => {
    gl.setRenderTarget(fbos.div)
    gl.render(scene, camera)
    gl.setRenderTarget(null)
  })

  return (
    <mesh>
      <planeGeometry args={[2.0, 2.0]} />
      <shaderMaterial
        vertexShader={faceVert}
        fragmentShader={divergenceFrag}
        uniforms={{
          u_boundarySpace: {
            value: cellScale,
          },
          u_velocity: {
            value: fbos.vel_1?.texture,
          },
          u_px: {
            value: cellScale,
          },
          u_dt: {
            value: TIME_SCALE,
          },
        }}
      />
    </mesh>
  )
}
