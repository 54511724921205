import {useId} from 'react'
import {Input, Label, type InputProps} from '~/design-system/foundations'

export type HgTextInputProps = {
  label: React.ReactNode
  secondaryLabel?: string
} & Omit<InputProps, 'id'>

export default function HgTextInput({
  label,
  secondaryLabel,
  ...props
}: HgTextInputProps) {
  const id = useId()
  return (
    <div className="group flex max-w-[256px] flex-col arcadia-body-3 [&:hover_input:not(:disabled)]:bg-surface-input-hover [&:hover_input:not(:disabled)]:shadow-[0_1px_8px_0_rgba(111,115,150,0.1)]">
      <Label
        htmlFor={id}
        className="pb-s1 text-text-subdued group-focus-within:text-text-default group-hover:text-text-default group-has-[:disabled]:text-text-disabled"
      >
        {label}
      </Label>
      <Input
        className="h-40 w-full rounded-lg border border-solid border-border-input bg-surface-input px-12 py-8 text-text-default outline-border-focus placeholder:text-text-subdued focus-visible:bg-surface-input-active focus-visible:outline-2 disabled:bg-surface-input-disabled"
        id={id}
        {...props}
      />
      {secondaryLabel && (
        <Label htmlFor={id} className="pt-s1 text-text-subdued">
          {secondaryLabel}
        </Label>
      )}
    </div>
  )
}
