const colorFrag = /* glsl */ `
precision mediump float;
uniform sampler2D u_velocity;
uniform sampler2D u_gradientMap;
uniform vec3 u_backgroundColor;
varying vec2 vUv;

void main(){
    vec2 vel = texture2D(u_velocity, vUv).xy;
    float len = length(vel);
    vel = vel * 0.5 + 0.5;

    vec3 gradientColor = texture2D(u_gradientMap, vel).rgb;
    vec3 color = mix(u_backgroundColor, gradientColor, len);

    // if no gradientMap was provided/it's using the default gradientMap, use the velocity to color the background
    if (gradientColor.rgb == vec3(1.0)) {
        color = mix(u_backgroundColor, vec3(vel, 1.0), len);
    }

    gl_FragColor = vec4(color,  1.0);
}
`

export default colorFrag
