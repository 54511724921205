'use client'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {usePathname} from 'next/navigation'
import {forwardRef} from 'react'
import HgTextLink from '../HgTextLink'
import {type HgNavigationBaseLinkProps} from './types'

export type HgNavigationExternalLinkProps = HgNavigationBaseLinkProps & {
  closeNav?: () => void
  isExternal?: boolean
}

export const HgNavigationExternalLink = forwardRef<
  HTMLAnchorElement,
  HgNavigationExternalLinkProps
>(({href, label, closeNav, isExternal, ...linkProps}, ref) => {
  const pathname = usePathname()
  const isActive = href === pathname

  return (
    <NavigationMenu.Link onClick={closeNav} asChild active={isActive}>
      <HgTextLink {...linkProps} href={href} newTab variant="external" ref={ref}>
        {label}
      </HgTextLink>
    </NavigationMenu.Link>
  )
})

HgNavigationExternalLink.displayName = 'HgNavigationExternalLink'
