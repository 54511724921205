const divergenceFrag = /* glsl */ `
precision mediump float;

uniform sampler2D u_velocity;
uniform float u_dt;
uniform vec2 u_px;
varying vec2 vUv;

void main(){
    float x0 = texture2D(u_velocity, vUv-vec2(u_px.x, 0)).x;
    float x1 = texture2D(u_velocity, vUv+vec2(u_px.x, 0)).x;
    float y0 = texture2D(u_velocity, vUv-vec2(0, u_px.y)).y;
    float y1 = texture2D(u_velocity, vUv+vec2(0, u_px.y)).y;
    float divergence = (x1-x0 + y1-y0) / 2.0;

    gl_FragColor = vec4(divergence / u_dt);
}
`

export default divergenceFrag
