'use client'
import {type LinkProps as NextLinkProps} from 'next/link'
import HgTextLink from '../HgTextLink'

type HgFooterLinkProps = Pick<NextLinkProps, 'href'> & {
  analyticsEvent?: {
    id: string
    name: string
  } | null
}

type HgFooterLinks = {
  id: string
  label: string
  link: HgFooterLinkProps
}

type HgFooterSection = {
  id: string
  title: string
  links: HgFooterLinks[]
}

type HgFooterContentProps = Omit<HgFooterSection, 'id'>

export type HgFooterProps = {
  sections: HgFooterSection[]
}

const HgFooterContent = ({title, links}: HgFooterContentProps) => {
  return (
    <div className="grid grid-cols-1 items-start gap-s3">
      <span className="text-subdued arcadia-body-3">{title}</span>
      {links.map(link => (
        <HgTextLink
          href={link.link.href}
          key={link.id}
          analyticsEventName={link.link.analyticsEvent?.name}
          variant="default"
        >
          <span>{link.label}</span>
        </HgTextLink>
      ))}
    </div>
  )
}

const HgFooter = ({sections}: HgFooterProps) => {
  return (
    <footer className="col-span-full lg:col-span-12 lg:col-start-3">
      <div className="grid w-full grid-cols-2 items-start gap-x-s6 gap-y-s9 md:grid-cols-3">
        {sections.map(section => (
          <HgFooterContent
            title={section.title}
            links={section.links}
            key={section.id}
          />
        ))}
      </div>
    </footer>
  )
}

export default HgFooter
