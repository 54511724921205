export const fontSize = {
  'xxs': '0.625rem', // 10px
  'xs': '.75rem', // 12px
  'sm': '0.875rem', // 14px
  'base': '1rem', // 16px
  'md': '1.125rem', // 18px
  'lg': '1.313rem', // 21px
  'xl': '1.5rem', // 24px
  '2xl': '1.75rem', // 28px
  '3xl': '2rem', // 32px
  '4xl': '2.25rem', // 36px
  '5xl': '2.625rem', // 42px
  '6xl': '3rem', // 48px
  '7xl': '3.438rem', // 55px
  '8xl': '3.938rem', // 63px
  '9xl': '4.563rem', // 73px
  '10xl': '5.25rem', // 84px
  '11xl': '6rem', // 96px
  '12xl': '7.938rem', // 127px
}
