'use client'
import {Carousel, CarouselContent, CarouselItem} from '~/design-system/foundations'
import {useCarousel} from '~/design-system/foundations/Carousel'
import {HgUiControls} from '~/design-system/hg/components'
import HgTestimonial, {
  type HgTestimonialDetailProps,
} from '~/design-system/hg/components/HgTestimonial'

export type HgDetailTestimonialSliderTestimonialProps = Omit<
  HgTestimonialDetailProps,
  'variant' | 'topSpacing' | 'alignment'
>

export type HgDetailTestimonialSliderProps = {
  testimonials: HgDetailTestimonialSliderTestimonialProps[]
}

function HgDetailTestimonialInner(props: {
  testimonials: HgDetailTestimonialSliderTestimonialProps[]
}) {
  const {testimonials} = props

  const {scrollNext, scrollPrev, currentIndex} = useCarousel()

  return (
    <>
      <CarouselContent>
        {testimonials.map((testimonial, index) => (
          <CarouselItem key={index}>
            <HgTestimonial
              {...testimonial}
              topSpacing="s0"
              variant="detail"
              alignment="left"
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      {testimonials.length > 1 && (
        <div className="pt-s6">
          <HgUiControls
            onBack={scrollPrev}
            onForward={scrollNext}
            currentIndex={currentIndex}
            numPages={testimonials.length}
          />
        </div>
      )}
    </>
  )
}

export default function HgDetailTestimonialSlider(
  props: HgDetailTestimonialSliderProps
) {
  const {testimonials} = props
  return (
    <div>
      <Carousel opts={{loop: true}}>
        <HgDetailTestimonialInner testimonials={testimonials} />
      </Carousel>
    </div>
  )
}
