import {get} from 'lodash'
import {type AuraColorToken, colors} from './colors'

export function getHexValueFromToken(token?: AuraColorToken): string | undefined {
  if (!token) return

  const tokenKeys = token.split('-')

  if (
    tokenKeys.includes('magic') &&
    (tokenKeys.includes('600') || tokenKeys.includes('400'))
  ) {
    tokenKeys.push('DEFAULT')
  }
  return get(colors, tokenKeys)
}
