import React from 'react'
import grid, {gridItem} from '~/design-system/foundations/Grid'
import HgTextBlock, {
  type HgTextBlockProps,
} from '~/design-system/hg/components/HgTextBlock'
import {type PartialPartial} from '~/utils/types'
import {HgAspectRatio} from '../../components'

export type HgFullImageProps = {
  assetTwoOneRatio: React.ReactNode
  assetFourFiveRatio: React.ReactNode
} & PartialPartial<Pick<HgTextBlockProps, 'buttonProps' | 'title'>, 'title'>

export default function HgFullImage({
  assetTwoOneRatio,
  assetFourFiveRatio,
  title,
  ...textBlockProps
}: HgFullImageProps) {
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <HgAspectRatio
          aspectRatio="2:1"
          topSpacing="s9"
          classNames={{
            wrapper: gridItem({
              size: 'max',
              class: 'hidden sm:inline-flex',
            }),
          }}
        >
          {assetTwoOneRatio}
        </HgAspectRatio>
        <HgAspectRatio
          aspectRatio="4:5"
          topSpacing="s9"
          classNames={{
            wrapper: gridItem({
              size: 'max',
              class: 'sm:hidden',
            }),
          }}
        >
          {assetFourFiveRatio}
        </HgAspectRatio>
        {title && (
          <div className="col-span-full mt-s4 md:col-span-6 md:col-start-7 lg:col-span-6 lg:col-start-9">
            <HgTextBlock title={title} {...textBlockProps} alignment="left" />
          </div>
        )}
      </div>
    </div>
  )
}
