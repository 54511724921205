'use client'

import {usePathname, useSearchParams} from 'next/navigation'
import {useCallback} from 'react'

const useQueryStringGenerator = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const createQueryString = useCallback(
    (values: Record<string, string | string[]>) => {
      const params = new URLSearchParams(searchParams.toString())

      for (const [name, value] of Object.entries(values)) {
        if (!value) {
          params.delete(name)
          continue
        }

        if (Array.isArray(value)) {
          value.forEach(v => {
            params.append(name, v)
          })
          continue
        }

        params.set(name, value)
      }

      return `${pathname}?${params.toString()}`
    },
    [searchParams, pathname]
  )

  return createQueryString
}

export default useQueryStringGenerator
