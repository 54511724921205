import {useEffect, useRef, useState, useCallback} from 'react'
import HgCheckboxInput, {type HgCheckboxInputProps} from '../../../HgCheckboxInput'
import HgRadioButtonInput, {
  type HgRadioButtonInputProps,
} from '../../../HgRadioButtonInput'
import HgRangeInput, {type HgRangeInputProps} from '../../../HgRangeInput'
import {TabsContent} from '~/design-system/foundations/Tabs'
import {type TabsContentProps} from '@radix-ui/react-tabs'
import {cn} from '~/design-system/utils'
import {useHgFilterTabContext} from '../..'
import {type HgFilterCheckboxTabState, type HgFilterRangeTabState} from '../../types'
import HgSearchInput from '../../../HgSearchInput'

type HgFilterOptionsCheckboxProps = {
  type: 'checkbox'
} & HgCheckboxInputProps

type HgFilterOptionsRadioInputProps = {
  type: 'radio'
} & HgRadioButtonInputProps

type HgFilterOptionsRangeInputProps = {
  type: 'range'
} & HgRangeInputProps

type HgFilterOptionsVariantProps =
  | HgFilterOptionsCheckboxProps
  | HgFilterOptionsRadioInputProps
  | HgFilterOptionsRangeInputProps

export type HgFilterOptionsProps = HgFilterOptionsVariantProps & {
  tabValue: string
  tabsContentProps?: Omit<TabsContentProps, 'value' | 'className'>
  className?: string
}

function NotFoundText({searchValue}: {searchValue: string}) {
  return (
    <span className="mt-s3 flex text-text-subdued arcadia-body-3">
      No matches for “{searchValue}” found.
    </span>
  )
}

export default function HgFilterOptions({
  className,
  tabValue,
  ...props
}: HgFilterOptionsProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [hasOverflow, setHasOverflow] = useState(false)
  const {tabState, setTabState} = useHgFilterTabContext()
  const [isVisible, setIsVisible] = useState(false)

  const checkOverflow = useCallback(() => {
    if (containerRef.current) {
      const {clientHeight, scrollHeight} = containerRef.current
      setHasOverflow(clientHeight < scrollHeight)
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      {threshold: 0}
    )

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isVisible) {
      checkOverflow()
    }
  }, [isVisible, checkOverflow])

  const [searchValue, setSearchValue] = useState('')
  function renderOptions() {
    switch (props.type) {
      case 'checkbox': {
        const {options, ...rest} = props
        const filteredOptions = options.filter(option =>
          option.label.toLowerCase().includes(searchValue.toLowerCase())
        )
        const checkboxTabState = tabState[tabValue] as
          | HgFilterCheckboxTabState
          | undefined
        return (
          <>
            {filteredOptions.length === 0 ? (
              <NotFoundText searchValue={searchValue} />
            ) : (
              <HgCheckboxInput
                {...rest}
                classNames={{
                  optionsContainer: cn(
                    'px-s3 pb-s3',
                    hasOverflow && 'overflow-y-auto'
                  ),
                  label: 'p-s3 pb-0',
                }}
                options={filteredOptions.map(option => {
                  return {
                    ...option,
                    checked: checkboxTabState?.includes(option.value) ?? false,
                    onCheckedChange: checked => {
                      setTabState(prev => {
                        const tabState =
                          (prev[tabValue] as HgFilterCheckboxTabState | undefined) ??
                          []
                        if (checked) {
                          return {
                            ...prev,
                            [tabValue]: [...tabState, option.value],
                          }
                        }
                        return {
                          ...prev,
                          [tabValue]: tabState.filter(v => v !== option.value),
                        }
                      })
                    },
                  }
                })}
              />
            )}
          </>
        )
      }
      case 'radio': {
        const {options, ...rest} = props
        const filteredOptions = options.filter(option =>
          option.label.toLowerCase().includes(searchValue.toLowerCase())
        )
        return (
          <>
            {filteredOptions.length === 0 ? (
              <NotFoundText searchValue={searchValue} />
            ) : (
              <HgRadioButtonInput
                {...rest}
                classNames={{
                  optionsContainer: cn(
                    'px-s3 pb-s3',
                    hasOverflow && 'overflow-y-auto'
                  ),
                  label: 'p-s3 pb-0',
                }}
                options={filteredOptions.map(option => ({
                  ...option,
                  selected: option.value === tabState[tabValue],
                }))}
                onValueChange={value => {
                  setTabState(prev => {
                    return {
                      ...prev,
                      [tabValue]: value,
                    }
                  })
                }}
              />
            )}
          </>
        )
      }
      case 'range': {
        const {minTextInputProps, maxTextInputProps, ...rest} = props
        const rangeTabState = tabState[tabValue] as HgFilterRangeTabState | undefined
        return (
          <div className="p-s3 pb-0">
            <HgRangeInput
              {...rest}
              minTextInputProps={{
                ...minTextInputProps,
                value: rangeTabState?.min ?? '',
                onChange: e => {
                  const textValue = e.target.value
                  setTabState(prev => {
                    return {
                      ...prev,
                      [tabValue]: {
                        ...(prev[tabValue] as HgFilterRangeTabState),
                        min: textValue,
                      },
                    }
                  })
                },
              }}
              maxTextInputProps={{
                ...maxTextInputProps,
                min: (tabState[tabValue] as HgFilterRangeTabState | undefined)?.min,
                value: rangeTabState?.max ?? '',
                onChange: e => {
                  const textValue = e.target.value
                  setTabState(prev => {
                    return {
                      ...prev,
                      [tabValue]: {
                        ...(prev[tabValue] as HgFilterRangeTabState),
                        max: textValue,
                      },
                    }
                  })
                },
              }}
            />
          </div>
        )
      }
    }
  }

  return (
    <TabsContent
      ref={containerRef}
      className={cn(
        'invisible pb-0 data-[state=active]:visible',
        className,
        hasOverflow && 'grid grid-rows-[auto_minmax(0,1fr)]'
      )}
      value={tabValue}
      forceMount
      {...props.tabsContentProps}
    >
      {hasOverflow && (
        <div className="p-s3 pb-0">
          <HgSearchInput
            placeholder="Search"
            onChange={setSearchValue}
            value={searchValue}
          />
        </div>
      )}
      {renderOptions()}
    </TabsContent>
  )
}
