import {type ReactNode} from 'react'
import {TextTag, type TextTagProps} from '~/design-system/foundations'
import {cn, unreachableCase} from '~/design-system/utils'
import HgBulletedList, {type HgBulletedListProps} from '../HgBulletedList'
import {HgButtonLink, type HgButtonLinkProps} from '../HgButton'
import HgCaption, {type HgCaptionProps} from '../HgCaption'
import HgStatistic, {type HgStatisticProps} from '../HgStatistic'
import HgTestimonial, {type HgTestimonialCompactProps} from '../HgTestimonial'

type FeaturedTextAdditionProps =
  | {
      additionType: 'caption'
      additionProps: HgCaptionProps
    }
  | {
      additionType: 'list'
      additionProps: HgBulletedListProps
    }
  | {
      additionType: 'statistic'
      additionProps: HgStatisticProps
    }
  | {
      additionType: 'testimonial'
      additionProps: Omit<HgTestimonialCompactProps, 'variant'>
    }

const FeaturedTextAddition = ({
  additionType,
  additionProps,
}: FeaturedTextAdditionProps) => {
  switch (additionType) {
    case 'caption':
      return <HgCaption {...additionProps} />
    case 'list':
      return <HgBulletedList {...additionProps} />
    case 'statistic':
      return <HgStatistic {...additionProps} />
    case 'testimonial':
      return <HgTestimonial {...additionProps} variant="compact" />
    default:
      unreachableCase(additionType)
  }
}

export type FeaturedTextBaseProps = {
  title: ReactNode
  titleTag?: TextTagProps['tag']
  buttonProps?: HgButtonLinkProps
  className?: string
}

export type HgFeaturedTextProps =
  | FeaturedTextBaseProps
  | (FeaturedTextBaseProps & {
      body: string | React.ReactNode
    })
  | (FeaturedTextBaseProps & FeaturedTextAdditionProps)

const HgFeaturedText = ({
  title,
  buttonProps,
  titleTag,
  className,
  ...props
}: HgFeaturedTextProps) => {
  if ('body' in props) {
    const {body} = props
    return (
      <div className={cn('align-start flex flex-col', className)}>
        <TextTag tag={titleTag} className="text-text-default arcadia-subheading-5">
          {title}
        </TextTag>
        {body && <p className="mt-s2 text-text-subdued arcadia-body-2">{body}</p>}
        {buttonProps && (
          <HgButtonLink className="mt-s5" variant="frosted" {...buttonProps} />
        )}
      </div>
    )
  }

  if ('additionType' in props && 'additionProps' in props) {
    return (
      <div className={cn('align-start flex flex-col', className)}>
        <TextTag tag={titleTag} className="text-text-default arcadia-subheading-5">
          {title}
        </TextTag>
        {buttonProps && (
          <HgButtonLink className="mt-s5" variant="frosted" {...buttonProps} />
        )}
        <div className="mb-16 mt-s7 w-full border-t border-border-subdued" />
        <FeaturedTextAddition {...props} />
      </div>
    )
  }

  return (
    <div className={cn('align-start flex flex-col', className)}>
      <TextTag tag={titleTag} className="text-text-default arcadia-subheading-5">
        {title}
      </TextTag>
      {buttonProps && (
        <HgButtonLink className="mt-s5" variant="frosted" {...buttonProps} />
      )}
    </div>
  )
}

export default HgFeaturedText
