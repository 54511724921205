import SyntaxHighlighter from 'react-syntax-highlighter'
import {atelierSulphurpoolLight} from 'react-syntax-highlighter/dist/esm/styles/hljs'
import {cn} from '~/design-system/utils'
import {type OmitStrict} from 'type-zoo'
import {gridItem} from '~/design-system/foundations'

type HgCodeBlockProps = {
  code: string
  language?: string
  className?: string
}

export const HgCodeBlock = ({code, language, className}: HgCodeBlockProps) => {
  return (
    <div
      className={cn(
        'rounded border border-solid border-neutral-base-300 bg-neutral-base-150 p-s3',
        className
      )}
    >
      <SyntaxHighlighter
        customStyle={{
          background: 'var(--neutral-base-150)',
          padding: '0px',
        }}
        codeTagProps={{className: 'bg-inherit'}}
        language={language}
        style={atelierSulphurpoolLight}
        wrapLongLines
        wrapLines
      >
        {code}
      </SyntaxHighlighter>
    </div>
  )
}

export const HgArticleWYSIWYGCodeBlock = (
  props: OmitStrict<HgCodeBlockProps, 'className'>
) => {
  return <HgCodeBlock {...props} className={gridItem({size: 'heading'})} />
}
