const advectionFrag = /* glsl */ `
precision mediump float;

uniform sampler2D u_velocity;  // Texture storing the velocity of each point in the fluid
uniform float u_dt;           // Time step, representing a small change in time
uniform vec2 u_fboSize;       // Size of the Frame Buffer Object, essentially the size of the texture
uniform vec2 u_px;            // Represents the size of a pixel in the texture
varying vec2 vUv;             // Texture coordinates, varying for each pixel

void main() {
    // Calculate the ratio of the FBO to maintain aspect ratio
    vec2 ratio = max(u_fboSize.x, u_fboSize.y) / u_fboSize;

    // The current position in texture coordinates
    vec2 spot_new = vUv;

    // Retrieve the velocity at the current position
    vec2 vel_old = texture2D(u_velocity, vUv).xy;

    // Calculate the position in the past based on the current velocity
    vec2 spot_old = spot_new - vel_old * u_dt * ratio;

    // Get the velocity at the old position
    vec2 vel_new1 = texture2D(u_velocity, spot_old).xy;

    // Predict a new position based on the old velocity
    vec2 spot_new2 = spot_old + vel_new1 * u_dt * ratio;

    // Calculate the error between the predicted and current position
    vec2 error = spot_new2 - spot_new;

    // Correct the position by half the error to approximate the correct position
    vec2 spot_new3 = spot_new - error / 2.0;

    // Get the velocity at this corrected position
    vec2 vel_2 = texture2D(u_velocity, spot_new3).xy;

    // Calculate the old position based on the corrected position
    vec2 spot_old2 = spot_new3 - vel_2 * u_dt * ratio;

    // Get the new velocity at this old position
    vec2 newVel2 = texture2D(u_velocity, spot_old2).xy;

    // Set the fragment color to the new velocity with no change in alpha
    gl_FragColor = vec4(newVel2, 0.0, 0.0);
}

`
export default advectionFrag
