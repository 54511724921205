import {tv} from 'tailwind-variants'

const chevronVariants = tv({
  slots: {
    container: 'relative h-[14px] w-[14px] [&_svg]:h-full [&_svg]:w-full',
    bar: 'origin-[0_0] translate-y-8 stroke-icon-default stroke-[4px] transition-colors transition-transform duration-300 ease-in-out group-data-[state=open]:-translate-y-8 group-data-[state=open]:stroke-icon-emphasized motion-reduce:transition-none',
  },
  variants: {
    position: {
      left: {
        bar: 'rotate-[45deg] group-data-[state=open]:-rotate-[45deg]',
      },
      right: {
        bar: '-rotate-[45deg] group-data-[state=open]:rotate-[45deg]',
      },
    },
  },
})

export default function HgChevronIndicator({className}: {className?: string}) {
  const {container, bar} = chevronVariants()
  return (
    <div className={container({className})}>
      <svg
        width="40"
        height="40"
        version="1.1"
        viewBox="-20 -20 40 40"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
      >
        <line
          strokeLinecap="round"
          className={bar({position: 'left'})}
          x1="-20"
          y1="0"
          x2="0"
          y2="0"
        />
        <line
          strokeLinecap="round"
          className={bar({position: 'right'})}
          x1="20"
          y1="0"
          x2="0"
          y2="0"
        />
      </svg>
    </div>
  )
}
