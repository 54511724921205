import {gridItem} from '~/design-system/foundations'
import {HgAspectRatio} from '~/design-system/hg/components'

export type HgHeroDefaultProps = {
  type: 'default'
  assetTwoOneRatio: React.ReactNode
  assetFourFiveRatio: React.ReactNode
}

export default function DefaultVariant(props: HgHeroDefaultProps) {
  return (
    <>
      <HgAspectRatio
        aspectRatio="2:1"
        topSpacing="s9"
        classNames={{
          wrapper: gridItem({size: 'max', className: 'hidden sm:block'}),
        }}
      >
        {props.assetTwoOneRatio}
      </HgAspectRatio>
      <HgAspectRatio
        aspectRatio="4:5"
        topSpacing="s9"
        classNames={{
          wrapper: gridItem({size: 'max', className: 'sm:hidden'}),
        }}
      >
        {props.assetFourFiveRatio}
      </HgAspectRatio>
    </>
  )
}
