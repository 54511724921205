import {lowerCase, orderBy} from 'lodash'
import {matchSorter} from 'match-sorter'
import {isValidSortOrder, type ValidSortOrder} from '~/app/_utils/isValidSortOrder'

const getSortedData = (
  data: Array<Record<string, any>>,
  sortBy: string,
  sortableProperties: string[]
) => {
  if (!data.length) return data

  const sortIteratees: Array<(o: Record<string, any>) => any> = []
  const sortOrders: ValidSortOrder[] = []

  sortBy.split(',').forEach(sortValue => {
    const [property, order] = sortValue.split('_')
    const normalizedOrder = (order || '').toLocaleLowerCase()

    if (isValidSortOrder(normalizedOrder) && sortableProperties.includes(property)) {
      sortIteratees.push((o: Record<string, any>) => {
        // Make string sorting case-insensitive
        if (typeof o[property] === 'string') {
          return lowerCase(o[property])
        }
        return o[property]
      })
      sortOrders.push(normalizedOrder)
    }
  })

  return orderBy(data, sortIteratees, sortOrders)
}

const isFilterEmpty = (value: any): boolean => {
  if (Array.isArray(value)) return value.length === 0
  if (typeof value === 'object' && value !== null) {
    return Object.values(value).every(v => v === '' || v === undefined)
  }
  return value === '' || value === undefined
}

const normalizeValue = (value: any): string => {
  if (value === null || value === undefined) {
    return ''
  }
  return String(value).toLowerCase().trim()
}

const getValueFromPath = (item: any, path: string) => {
  return path.split('.').reduce((acc, part) => acc?.[part], item)
}
const compareValues = (itemValue: any, filterValue: any, compareType: string) => {
  switch (compareType) {
    case 'includes':
      if (Array.isArray(itemValue)) {
        return itemValue.some((item: any) => filterValue.includes(item))
      }
      return String(itemValue)
        .toLowerCase()
        .includes(String(filterValue).toLowerCase())
    case 'equals':
      return normalizeValue(itemValue) === normalizeValue(filterValue)
    case 'range': {
      const min =
        filterValue.min !== '' && filterValue.min !== undefined
          ? parseFloat(filterValue.min)
          : null
      const max =
        filterValue.max !== '' && filterValue.max !== undefined
          ? parseFloat(filterValue.max)
          : null
      const value = parseFloat(itemValue)

      if (min === null && max === null) return true
      if (min === null && max !== null) return value <= max
      if (min !== null && max === null) return value >= min
      if (min !== null && max !== null) return value >= min && value <= max
      return true
    }
    case 'booleanEquals':
      return itemValue === (filterValue === 'true')
    default:
      return true
  }
}

const applyFilters = (
  data: Array<Record<string, any>>,
  filters: Record<string, any>,
  filterMap: Record<string, {getValue: string; compare: string; type: string}>
) => {
  return data.filter(item => {
    return Object.entries(filters).every(([key, filterValue]) => {
      const mapEntry = filterMap[key]
      if (!mapEntry || isFilterEmpty(filterValue)) {
        return true
      }

      const itemValue = getValueFromPath(item, mapEntry.getValue)

      if (mapEntry.type === 'checkbox') {
        // For checkbox filters (additive)
        if (Array.isArray(filterValue)) {
          if (filterValue.length === 0) return true
          // Split the combined string into an array of individual values
          const selectedValues = filterValue[0].split(',')
          // Check if any of the selected values match the item's value
          return selectedValues.some((value: any) =>
            compareValues(itemValue, value, mapEntry.compare)
          )
        }
        return true
      } else if (mapEntry.type === 'range') {
        // For range filters
        return compareValues(itemValue, filterValue, mapEntry.compare)
      } else if (mapEntry.type === 'radio') {
        // For radio filters
        if (Array.isArray(filterValue) && filterValue.length > 0) {
          return compareValues(itemValue, filterValue[0], mapEntry.compare)
        }
      }

      return true
    })
  })
}

export function getFilteredData({
  data,
  query,
  sortBy,
  filters,
  filterMap,
  sortableProperties,
}: {
  data: Array<Record<string, any>>
  query: string
  sortBy: string
  filters: Record<string, any>
  filterMap: Record<string, {getValue: string; compare: string; type: string}>
  sortableProperties: string[]
}) {
  let filteredData = data

  // Apply filters
  filteredData = applyFilters(filteredData, filters, filterMap)

  // Apply search
  if (query) {
    const searchKeys = Object.values(filterMap).map(entry => entry.getValue)
    filteredData = matchSorter(filteredData, query, {
      keys: searchKeys,
    })
  }

  // Apply sorting
  if (sortBy) {
    filteredData = getSortedData(filteredData, sortBy, sortableProperties)
  }

  return filteredData
}
