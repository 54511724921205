import grid, {gridItem} from '~/design-system/foundations/Grid'
import {HgTabs} from '~/design-system/hg/components'
import {TabSlide, type TabSlideProps} from './TabSlide'

export {type TabSlideProps}

export type HgTabSliderProps = {
  slides: TabSlideProps[]
}

function TabSlides({slides}: {slides: TabSlideProps[]}) {
  return (
    <div className={gridItem({size: 'max'})}>
      <HgTabs
        tabs={slides.map(slide => ({
          key: slide.key,
          label: slide.label,
          content: <TabSlide slide={slide} />,
        }))}
        topSpacing="s6"
      />
    </div>
  )
}

export default function HgTabSlider({slides}: HgTabSliderProps) {
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <TabSlides slides={slides} />
      </div>
    </div>
  )
}
