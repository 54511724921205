'use client'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {usePathname} from 'next/navigation'
import {forwardRef, type ReactNode} from 'react'
import {LinkWrapper} from '~/design-system/foundations'
import HgIcon from '../HgIcon'
import {textLink} from '../HgTextLink'
import {type HgNavigationBaseLinkProps} from './types'

export type HgNavigationLinkProps = HgNavigationBaseLinkProps & {
  description?: string | ReactNode
  closeNav?: () => void
}

export const HgNavigationLink = forwardRef<HTMLAnchorElement, HgNavigationLinkProps>(
  ({href, label, description, closeNav, ...linkProps}, ref) => {
    const pathname = usePathname()
    const isActive = href === pathname
    const {base, linkChildren, withArrow} = textLink({
      type: 'withArrow',
    })

    return (
      <div className="flex flex-col items-start justify-start space-y-s2">
        <NavigationMenu.Link onClick={closeNav} asChild active={isActive}>
          <LinkWrapper
            {...linkProps}
            href={href}
            className="group flex flex-col gap-8 rounded outline-2 outline-offset-4 outline-border-focus focus-visible:outline"
            ref={ref}
          >
            <div className={base({})}>
              <p className={linkChildren()}>{label}</p>
              <HgIcon
                iconType="arrow-right"
                size="regular"
                className={withArrow()}
              />
            </div>
            {description && (
              <p className="peer text-text-subdued arcadia-body-3">{description}</p>
            )}
          </LinkWrapper>
        </NavigationMenu.Link>
      </div>
    )
  }
)
HgNavigationLink.displayName = 'HgNavigationLink'
