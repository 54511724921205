'use client'
import {useEffect, useRef} from 'react'
import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  type ButtonProps,
} from '~/design-system/foundations'
import {useCarousel} from '~/design-system/foundations/Carousel'
import grid, {gridItem} from '~/design-system/foundations/Grid'
import {
  HgAspectRatio,
  HgCaption,
  HgUiControls,
  type HgIconType,
} from '~/design-system/hg/components'

export type HgSlideshowItem = {
  assetTwoOneRatio: React.ReactNode
  assetFourFiveRatio: React.ReactNode
  iconType: HgIconType
  title: React.ReactNode
  subhead: React.ReactNode
  id: string
}
type HgSlideshowProps = {
  carouselItems: HgSlideshowItem[]
}

function HgSlideshowContent({
  iconType,
  subhead,
  title,
  assetTwoOneRatio,
  assetFourFiveRatio,
  ...buttonProps
}: HgSlideshowItem & Omit<ButtonProps, 'title'>) {
  return (
    <Button
      {...buttonProps}
      className="flex flex-1 cursor-default flex-col gap-8 text-left opacity-70 transition-opacity duration-300 ease-out hover:opacity-100 aria-[current=true]:opacity-100 sm:cursor-pointer"
    >
      <HgCaption title={title} body={subhead} iconType={iconType} titleTag="h3" />
    </Button>
  )
}

function HgSlideshowInner({items}: {items: HgSlideshowProps['carouselItems']}) {
  const {
    scrollTo,
    currentIndex,
    api,
    scrollNext,
    scrollPrev,
    isUsingKeyboardNavigation,
  } = useCarousel()
  const slideShowImageRef = useRef<HTMLDivElement>(null)
  const innerContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!innerContainerRef.current) return
    if (isUsingKeyboardNavigation) {
      // since there are both desktop only and mobile only pagination instances, a simple querySelector will return even hidden elements
      // we can simply focus both of them since only one will be visible at a time
      const currentButtons = innerContainerRef.current.querySelectorAll(
        'button[aria-current=true]'
      ) as NodeListOf<HTMLButtonElement> | undefined

      currentButtons?.forEach(button => {
        button.focus()
      })
    }
  }, [currentIndex, isUsingKeyboardNavigation])

  return (
    <div ref={innerContainerRef}>
      <CarouselContent className="-ml-s4">
        {items.map((item, index) => (
          <CarouselItem key={index} className="pl-s4" aria-describedby={item.id}>
            <div className="flex shrink-0" ref={slideShowImageRef}>
              <HgAspectRatio aspectRatio="4:5" classNames={{wrapper: 'sm:hidden'}}>
                {item.assetFourFiveRatio}
              </HgAspectRatio>
              <HgAspectRatio
                aspectRatio="2:1"
                classNames={{wrapper: 'hidden sm:inline-flex'}}
              >
                {item.assetTwoOneRatio}
              </HgAspectRatio>
            </div>
            <div className="mt-s6 flex sm:hidden">
              <HgSlideshowContent {...item} aria-current={index === currentIndex} />
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <div className="mt-s6 hidden gap-s4 sm:flex">
        {items.map((item, index) => (
          <HgSlideshowContent
            key={`desktop-content-${index}`}
            {...item}
            aria-current={index === currentIndex}
            onClick={() => {
              scrollTo(index)
              api?.plugins().autoplay?.stop()
            }}
          />
        ))}
      </div>
      <div className="mt-s6 flex items-center gap-s4">
        <HgUiControls
          onBack={() => {
            scrollPrev()
            api?.plugins().autoplay?.stop()
          }}
          onForward={() => {
            scrollNext()
            api?.plugins().autoplay?.stop()
          }}
          numPages={items.length}
          currentIndex={currentIndex}
        />
      </div>
    </div>
  )
}

export default function HgSlideshow({carouselItems}: HgSlideshowProps) {
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <Carousel
          className={gridItem({size: 'max', class: 'mt-s9'})}
          opts={{loop: true}}
        >
          <HgSlideshowInner items={carouselItems} />
        </Carousel>
      </div>
    </div>
  )
}
