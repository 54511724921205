import {type ReactNode} from 'react'
import {grid} from '~/design-system/foundations'

const HgArticleWYSIWYGContainer = ({children}: {children: ReactNode}) => {
  return (
    <div className="w-full">
      <div
        className={grid({
          className:
            'mx-auto [&_ol_+_ul]:my-4 md:[&_ol_+_ul]:my-8 lg:[&_ol_+_ul]:my-12',
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default HgArticleWYSIWYGContainer
