export {default as HgDetailBody} from './_components/HgDetailBody'
export {default as HgDetailContent} from './_components/HgDetailContent'
export {default as HgDetailLinkGroup} from './_components/HgDetailLinkGroup'
export {default as HgDetailList} from './_components/HgDetailList'
export {default as HgDetailTagGroup} from './_components/HgDetailTagGroup'
export {
  default as HgDetailTestimonialSlider,
  type HgDetailTestimonialSliderTestimonialProps,
} from './_components/HgDetailTestimonialSlider'
export {default as HgDetailTitle} from './_components/HgDetailTitle'
