import {
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
} from '~/design-system/foundations'
import HgIcon, {type HgIconType} from '../HgIcon'

export type HgHoverCardProps = {
  trigger: React.ReactNode
  title: React.ReactNode
  description: React.ReactNode
  additionalInfo?: Array<{
    iconType: HgIconType
    text: string
  }>
  sideOffset?: number
  collisionPadding?: number
}

const HgHoverCard = ({
  trigger,
  title,
  description,
  additionalInfo,
  sideOffset = 8,
  collisionPadding = 72,
}: HgHoverCardProps) => {
  return (
    <HoverCardRoot openDelay={300}>
      <HoverCardTrigger>{trigger}</HoverCardTrigger>
      <HoverCardContent
        side="top"
        align="start"
        sideOffset={sideOffset}
        collisionPadding={collisionPadding}
        className="p-5 z-[1] -ms-s3 flex w-fit max-w-[320px] flex-col gap-s3 rounded-xl bg-surface-elevated bg-surface-elevated p-s3 shadow-[0px_10px_16px_0px_rgba(28,28,35,0.02),0px_6px_10px_0px_rgba(28,28,35,0.04),0px_0px_3px_0px_rgba(28,28,35,0.09)] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade data-[state=open]:transition-all"
      >
        <div className="flex flex-col gap-s1">
          <p className="text-text-default arcadia-heading-9">{title}</p>
          <p className="text-text-subdued arcadia-body-3">{description}</p>
        </div>
        {additionalInfo && Boolean(additionalInfo?.length) && (
          <div className="flex flex-col space-y-s2">
            {additionalInfo.map((info, index) => (
              <div
                key={index}
                className="flex items-center justify-start gap-s2 text-text-subdued"
              >
                <HgIcon size="small" iconType={info?.iconType} />
                <p className="arcadia-body-3">{info?.text}</p>
              </div>
            ))}
          </div>
        )}
      </HoverCardContent>
    </HoverCardRoot>
  )
}

export default HgHoverCard
