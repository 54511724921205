import * as React from 'react'

const Pagination = ({className, ...props}: React.ComponentProps<'nav'>) => (
  <nav role="navigation" aria-label="pagination" className={className} {...props} />
)
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({className, ...props}, ref) => <ul ref={ref} className={className} {...props} />)
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(
  ({className, ...props}, ref) => <li ref={ref} className={className} {...props} />
)
PaginationItem.displayName = 'PaginationItem'

export {Pagination, PaginationContent, PaginationItem}
