export const spacing = {
  0: '0px',
  1: '1px',
  2: '2px',
  4: '4px',
  8: '8px',
  12: '12px',
  16: '16px',
  20: '20px',
  24: '24px',
  32: '32px',
  40: '40px',
  48: '48px',
  56: '56px',
  64: '64px',
  72: '72px',
  80: '80px',
  96: '96px',
  112: '112px',
  128: '128px',
  144: '144px',
  160: '160px',
  176: '176px',
  192: '192px',
  208: '208px',
  224: '224px',
  240: '240px',
}

type SpacingTokens =
  | 's0'
  | 's1'
  | 's2'
  | 's3'
  | 's4'
  | 's5'
  | 's6'
  | 's7'
  | 's8'
  | 's9'
  | 's10'
  | 's11'
  | 's12'

type SemanticSpacingRecord = Record<SpacingTokens, string>

// NB: if a new semantic spacing token is added here, ensure it is also added to src/app/(new)/globals.css
export const semanticSpacingToCssVarMap: SemanticSpacingRecord = {
  s0: 'var(--s0)',
  s1: 'var(--s1)',
  s2: 'var(--s2)',
  s3: 'var(--s3)',
  s4: 'var(--s4)',
  s5: 'var(--s5)',
  s6: 'var(--s6)',
  s7: 'var(--s7)',
  s8: 'var(--s8)',
  s9: 'var(--s9)',
  s10: 'var(--s10)',
  s11: 'var(--s11)',
  s12: 'var(--s12)',
}
