import * as React from 'react'
import {Slot} from '@radix-ui/react-slot'

export type ButtonProps = {
  /**
   * Ability to merge parent props onto its immediate child: https://www.radix-ui.com/primitives/docs/utilities/slot
   */
  asChild?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({asChild = false, ...props}, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp ref={ref} {...props} />
  }
)

Button.displayName = 'Button'

export default Button
