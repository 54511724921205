'use client'

import {type OmitStrict} from 'type-zoo'
import {type Tab} from '~/design-system/foundations'
import {useTabsContext} from '~/design-system/foundations/Tabs'
import {HgAspectRatio, type HgTextBlockProps} from '~/design-system/hg/components'
import {HgTextBlock} from '../../components'

export type TabSlideProps = {
  assetThreeTwoRatio: React.ReactElement
  assetSixteenFiveRatio: React.ReactElement
} & OmitStrict<Tab, 'content'> &
  Pick<HgTextBlockProps, 'title' | 'body' | 'buttonProps'>

export function TabSlide({slide}: {slide: TabSlideProps}) {
  const {assetThreeTwoRatio, assetSixteenFiveRatio, key, ...textBlockProps} = slide
  const {currentTab} = useTabsContext()

  return (
    <div className="grid grid-cols-12 gap-x-s4">
      <HgAspectRatio
        aspectRatio="3:2"
        classNames={{wrapper: 'col-span-full sm:hidden'}}
      >
        {currentTab === slide.key && assetThreeTwoRatio}
      </HgAspectRatio>
      <HgAspectRatio
        aspectRatio="16:5"
        classNames={{wrapper: 'col-span-full hidden sm:inline-block'}}
      >
        {currentTab === slide.key && assetSixteenFiveRatio}
      </HgAspectRatio>
      <div className="col-span-full sm:col-span-6 sm:col-start-4">
        <HgTextBlock key={key} {...textBlockProps} topPadding="s4" />
      </div>
    </div>
  )
}
