import * as React from 'react'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({type, ...props}, ref) => {
    return <input type={type} ref={ref} {...props} />
  }
)
Input.displayName = 'Input'

export default Input

export const EmailInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({...props}, ref) => <Input type="email" ref={ref} {...props} />
)
EmailInput.displayName = 'EmailInput'
