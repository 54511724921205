import {useCallback, useEffect, useState} from 'react'
import {screens} from '~/design-system/hg/tokens'

/* from https://www.netlify.com/blog/2020/12/05/building-a-custom-react-media-query-hook-for-more-responsive-apps/ */
/**
 * A hook wrapper of window.matchMedia api that indicates whether the viewport on the page matches the given css media query
 * @param query a standard css media query ex: (max-width: 767px)
 */
export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(() => {
    return typeof window !== 'undefined' && window.matchMedia(query).matches
  })

  const matchChangeHandler = useCallback((event: MediaQueryListEvent) => {
    setMatches(event.matches)
  }, [])

  useEffect(() => {
    const media = window.matchMedia(query)

    // set the state immediately every time this effect re-runs
    // that way, if/when the query changes, we'll change our state immediately
    setMatches(media.matches)

    if (typeof media.addEventListener === 'function') {
      media.addEventListener('change', matchChangeHandler)
      // Keeping this deprecated method in order to support older Safari versions;
      // see here: https://mercurytechnologies.slack.com/archives/CJVUE3QL9/p1666039361385029
    } else if (typeof media.addListener === 'function') {
      media.addListener(matchChangeHandler)
    }
    return () => {
      if (typeof media.removeEventListener === 'function') {
        media.removeEventListener('change', matchChangeHandler)
      } else if (typeof media.removeListener === 'function') {
        media.removeListener(matchChangeHandler)
      }
    }
  }, [query, matchChangeHandler])

  return matches
}

export function usePrefersReducedMotion() {
  return useMediaQuery('(prefers-reduced-motion: reduce)')
}

export function useIsSmallScreenOrWider() {
  return useMediaQuery(`(min-width: ${screens.sm})`)
}

export function useIsLargerThanMobile() {
  return useMediaQuery(`(min-width: ${screens.md})`)
}
