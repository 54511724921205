/* eslint-disable react/no-unknown-property */
import {
  type ThreeRenderTarget,
  type ShaderPassProps,
} from '~/design-system/hg/components/StableFluidBackground/types'
import * as THREE from 'three'
import faceVert from '../shaders/face.vert'
import poissonFrag from '../shaders/poisson.frag'
import {type ShaderMaterialProps, extend, useFrame} from '@react-three/fiber'
import {useRef} from 'react'
import {shaderMaterial} from '@react-three/drei'

type PoissonMaterialProps = {
  u_boundarySpace: THREE.Vector2
  u_pressure: THREE.Texture | undefined
  u_divergence: THREE.Texture | undefined
  u_px: THREE.Vector2
}

const PoissonMaterial = shaderMaterial(
  {
    u_boundarySpace: new THREE.Vector2(),
    u_pressure: new THREE.Texture(),
    u_divergence: new THREE.Texture(),
    u_px: new THREE.Vector2(),
  },
  faceVert,
  poissonFrag
)

extend({PoissonMaterial})

export default function Poisson({
  fbos,
  cellScale,
  pressureRef,
  poissonIterations,
}: ShaderPassProps & {
  pressureRef: React.MutableRefObject<THREE.RawShaderMaterial | null>
  poissonIterations: number
}) {
  const shaderRef = useRef<THREE.RawShaderMaterial | null>(null)

  useFrame(({gl, scene, camera}) => {
    if (!shaderRef.current || !pressureRef.current) return
    let pIn: ThreeRenderTarget = null
    let pOut: ThreeRenderTarget = null

    for (let i = 0; i < poissonIterations; i++) {
      if (i % 2 === 0) {
        pIn = fbos.pressure_0
        pOut = fbos.pressure_1
      } else {
        pIn = fbos.pressure_1
        pOut = fbos.pressure_0
      }

      shaderRef.current.uniforms.u_pressure.value = pIn?.texture
      gl.setRenderTarget(pOut)
      gl.render(scene, camera)
      gl.setRenderTarget(null)
    }
    pressureRef.current.uniforms.u_pressure.value = pOut?.texture
  })

  return (
    <mesh>
      <planeGeometry args={[2.0, 2.0]} />
      <poissonMaterial
        ref={shaderRef}
        u_boundarySpace={cellScale}
        u_divergence={fbos.div?.texture}
        u_pressure={fbos.pressure_0?.texture}
        u_px={cellScale}
      />
    </mesh>
  )
}

/*
React Three Fiber (R3F) utilizes the full catalog of ThreeJS classes. However, it lets you utilize the ThreeJS classes declaratively by denoting them with camelCase to differentiate them from custom user made components. This abstraction strips away a lot of the boiler plate with creating a scene in ThreeJS. Check out https://docs.pmnd.rs/react-three-fiber/getting-started/your-first-scene#adding-a-mesh-component for a good example of this abstraction.

R3F also lets you define your own custom ThreeJS components by using its `extend` function which can be seen at the top of this file. This effectively adds it to the catalog of ThreeJS components at your disposal (ExternalForceMaterial becomes <externalForceMaterial /> declaratively). Unfortunately, TypeScript can't keep up with this, so we must add a type definition for this new custom camelCase component.

Additionally, the `shaderMaterial()` function from @react-three/drei lets me create a reactive shader material declaratively which makes it easier to adjust the parameters using controls.

https://docs.pmnd.rs/react-three-fiber/tutorials/typescript#extend-usage
*/
declare module '@react-three/fiber' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ThreeElements {
    poissonMaterial: Omit<
      ShaderMaterialProps,
      'vertexShader' | 'fragmentShader' | 'uniforms'
    > &
      PoissonMaterialProps
  }
}
