import * as THREE from 'three'
import {useEffect, useRef} from 'react'
import {useFrame} from '@react-three/fiber'

export default function useMouse(canvasRef: HTMLCanvasElement | null) {
  const coords = useRef(new THREE.Vector2())
  const oldCoords = useRef(new THREE.Vector2())
  const diff = useRef(new THREE.Vector2())
  const clickPosition = useRef(new THREE.Vector2(-1, -1))
  const mouseDownTimerRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (!canvasRef) return
    canvasRef.addEventListener('pointermove', onPointerMove)
    canvasRef.addEventListener('pointerdown', onPointerDown)
    canvasRef.addEventListener('pointerup', onPointerUp)

    function setCoords(x: number, y: number) {
      if (!canvasRef) {
        return
      }

      const rect = canvasRef.getBoundingClientRect()
      coords.current.set(
        ((x - rect.left) / rect.width) * 2 - 1,
        -((y - rect.top) / rect.height) * 2 + 1
      )
    }

    function onPointerMove(e: PointerEvent) {
      if (!canvasRef) return
      canvasRef.style.touchAction = 'none'
      setCoords(e.clientX, e.clientY)
    }

    function onPointerDown(e: PointerEvent) {
      if (!canvasRef) return
      clearTimeout(mouseDownTimerRef.current)

      const bounds = canvasRef.getBoundingClientRect()
      const x = (e.clientX - bounds.left) / bounds.width
      const y = 1.0 - (e.clientY - bounds.top) / bounds.height
      clickPosition.current.set(x, y)
      setCoords(e.clientX, e.clientY)

      mouseDownTimerRef.current = setTimeout(() => {
        clickPosition.current.set(-1, -1)
      }, 50)
    }

    function onPointerUp(e: PointerEvent) {
      clearTimeout(mouseDownTimerRef.current)
      clickPosition.current.set(-1, -1)
    }

    return () => {
      canvasRef.removeEventListener('pointermove', onPointerMove)
      canvasRef.removeEventListener('pointerdown', onPointerDown)
      canvasRef.removeEventListener('pointerup', onPointerUp)
    }
  }, [canvasRef])

  useFrame(() => {
    diff.current.subVectors(coords.current, oldCoords.current)
    oldCoords.current.copy(coords.current)

    if (oldCoords.current.x === 0 && oldCoords.current.y === 0) {
      diff.current.set(0, 0)
    }
  })

  return {
    coords: coords.current,
    diff: diff.current,
    clickPosition: clickPosition.current,
  }
}
