'use client'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {HgNavigationExternalLink} from './HgNavigationExternalLink'
import {HgNavigationLink} from './HgNavigationLink'
import {type HgNavigationSubMenuProps} from './types'

export const HgNavigationSubMenu = ({
  title,
  links,
  menuLabel,
  closeNav,
  ...subMenuProps
}: HgNavigationSubMenuProps) => (
  <NavigationMenu.Sub orientation="vertical" {...subMenuProps}>
    {title && <p className="mb-12 text-text-subdued arcadia-body-3">{title}</p>}
    <NavigationMenu.List className="flex w-[308px] max-w-[308px] flex-1 flex-col items-start justify-start space-y-12">
      {links.slice(0, 10).map((link, index) => {
        if (!link) return null
        const {isExternal, ...linkProps} = link
        return (
          <NavigationMenu.Item
            key={index}
            value={`subMenu-${menuLabel ?? ''}-${index}`}
          >
            {link.isExternal ? (
              <HgNavigationExternalLink closeNav={closeNav} {...linkProps} />
            ) : (
              <HgNavigationLink closeNav={closeNav} {...linkProps} />
            )}
          </NavigationMenu.Item>
        )
      })}
    </NavigationMenu.List>
  </NavigationMenu.Sub>
)
