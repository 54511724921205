'use client'

import * as React from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import {cn} from '~/design-system/utils'

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({className, ...props}, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn(
        'flex',
        props.orientation === 'vertical' && 'flex-col',
        className
      )}
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

export type RadioGroupItemProps = {
  indicator: React.ReactNode
} & React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({className, indicator, ...props}, ref) => {
  return (
    <RadioGroupPrimitive.Item ref={ref} className={className} {...props}>
      <RadioGroupPrimitive.Indicator
        asChild
        className="flex items-center justify-center"
      >
        {indicator}
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export {RadioGroup, RadioGroupItem}
