import {tv} from 'tailwind-variants'
import {TabsTrigger} from '~/design-system/foundations/Tabs'
import HgIcon from '../../../HgIcon'

export type HgFilterTabProps = {
  label: React.ReactNode
  tabValue: string
  focused?: boolean
  hovered?: boolean
  active?: boolean
}

const hgFilterTabVariants = tv({
  base: 'flex min-w-[232px] justify-between rounded-lg bg-surface-elevated p-s3 text-text-subdued outline-1 outline-border-focus backdrop-blur-[20px] arcadia-ui-1 hover:bg-surface-elevated-hover hover:text-text-default focus-visible:outline data-[state=active]:bg-surface-elevated-active data-[state=active]:text-text-default',
  variants: {
    focused: {true: 'outline'},
    hovered: {true: 'bg-surface-elevated-hover text-text-default'},
    active: {
      true: 'bg-surface-elevated-active text-text-default hover:bg-surface-elevated-active hover:text-text-default',
    },
  },
})

export default function HgFilterTab({
  label,
  tabValue,
  ...variantProps
}: HgFilterTabProps) {
  return (
    <TabsTrigger className={hgFilterTabVariants(variantProps)} value={tabValue}>
      {label}
      <HgIcon iconType="chevron-right" size="regular" />
    </TabsTrigger>
  )
}
