'use client'

import {tv, type VariantProps} from 'tailwind-variants'
import {type SortType} from '~/app/_utils/isValidSortOrder'
import {Button} from '~/design-system/foundations'
import {HgIcon} from '~/design-system/hg/components'
import {unreachableCase} from '~/design-system/utils'

const sortButtonVariants = tv({
  slots: {
    button:
      'group flex h-16 max-h-16 w-16 max-w-16 items-center justify-center rounded p-[2px] pt-[3px] outline-2 -outline-offset-2 outline-border-focus transition-opacity duration-300 hover:opacity-100 focus-visible:opacity-100 focus-visible:outline',
    sortIcon:
      'transition-color max-w-[10px] text-icon-subdued transition-transform duration-300 group-active:text-icon-default',
  },
  variants: {
    sortOrder: {
      asc: {
        button: 'opacity-100',
        sortIcon: 'text-icon-default',
      },
      desc: {
        button: 'opacity-100',
        sortIcon: 'text-icon-default',
      },
      none: {
        button: 'opacity-0',
        sortIcon: 'rotate-180',
      },
    },
    nextSortOrder: {
      asc: '',
      desc: '',
      none: '',
    },
  },
  compoundVariants: [
    {
      sortOrder: 'asc',
      nextSortOrder: 'desc',
      class: {
        sortIcon: 'rotate-180',
      },
    },
    {
      sortOrder: 'desc',
      nextSortOrder: 'asc',
      class: {
        sortIcon: 'rotate-180',
      },
    },
  ],
})

type HgSortButtonVariantProps = VariantProps<typeof sortButtonVariants>
export type SortOrder = NonNullable<HgSortButtonVariantProps['sortOrder']>

export type HgSortButtonProps = {
  handleSort?: (sortOrder: SortOrder) => void
  sortOrder: SortOrder
  sortType: SortType
  className?: string
}

const STRING_SORT_ORDER: Record<SortOrder, SortOrder> = {
  asc: 'desc',
  desc: 'none',
  none: 'asc',
}

const NUMBER_OR_BOOLEAN_OR_DATE_SORT_ORDER: Record<SortOrder, SortOrder> = {
  asc: 'none',
  desc: 'asc',
  none: 'desc',
}

const HgSortButton = ({
  handleSort,
  sortOrder,
  sortType,
  className,
}: HgSortButtonProps) => {
  const getNextSortOrder = (sortType: SortType, sortOrder: SortOrder) => {
    switch (sortType) {
      case 'string':
        return STRING_SORT_ORDER[sortOrder]
      case 'boolean':
      case 'date':
      case 'number':
        return NUMBER_OR_BOOLEAN_OR_DATE_SORT_ORDER[sortOrder]
      default:
        unreachableCase(sortType)
    }
  }

  const initialSortOrder = getNextSortOrder(sortType, 'none')

  const {button, sortIcon} = sortButtonVariants({
    sortOrder,
    nextSortOrder: getNextSortOrder(sortType, sortOrder),
  })

  const handleClick = () => {
    const nextSortOrder = getNextSortOrder(sortType, sortOrder)

    if (!handleSort || !nextSortOrder) return

    handleSort(nextSortOrder)
  }

  return (
    <Button className={button({class: className})} onClick={handleClick}>
      <HgIcon
        className={sortIcon()}
        iconType={initialSortOrder === 'asc' ? 'caret-down' : 'caret-up'}
        size="regular"
      />
      <span className="sr-only">Sort {getNextSortOrder(sortType, sortOrder)}</span>
    </Button>
  )
}

export default HgSortButton
