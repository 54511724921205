import {type PropsWithChildren} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'

// TODO: Replace these with the design system values once they are ready
const containedCustomShadow =
  'shadow-[0px_0px_3px_0px_rgba(0,0,0,0.05),0px_8px_12px_0px_rgba(0,0,0,0.05),0px_12px_20px_0px_rgba(0,0,0,0.05)]'
const containedCustomBorderRadius = 'rounded-[12px]'

const hgDisclaimerWrapper = tv({
  base: '',
  variants: {
    topSpacing: {
      s0: 'pt-s0',
    },
    bottomSpacing: {
      s0: 'pb-s0',
      s5: 'pb-s5',
    },
  },
  defaultVariants: {
    topSpacing: 's0',
    bottomSpacing: 's0',
  },
})

const hgDisclaimer = tv({
  base: 'block arcadia-body-5',
  variants: {
    variant: {
      default: 'text-text-subdued',
      contained: `bg-surface-elevated px-s4 py-s3 text-center text-text-default backdrop-blur-[20px] ${containedCustomBorderRadius} ${containedCustomShadow}`,
    },
    centered: {
      true: 'text-center',
    },
  },
  defaultVariants: {
    variant: 'default',
    centered: false,
  },
})

type HgDisclaimerVariants = VariantProps<typeof hgDisclaimer>
type HgDisclaimerWrapperVariants = VariantProps<typeof hgDisclaimerWrapper>
export type HgDisclaimerProps = HgDisclaimerVariants & HgDisclaimerWrapperVariants

export default function HgDisclaimer(props: PropsWithChildren<HgDisclaimerProps>) {
  const {children, ...disclaimerProps} = props
  return (
    <div className={hgDisclaimerWrapper(disclaimerProps)}>
      <small className={hgDisclaimer(disclaimerProps)}>{children}</small>
    </div>
  )
}
