'use client'

import {useRouter} from 'next/navigation'
import {grid, gridItem} from '~/design-system/foundations'
import useQueryStringGenerator from '~/design-system/hooks/useQueryStringGenerator'
import {cn} from '~/design-system/utils'
import {HgItemsPerPage} from '../../components'
import HgResultsWindow from '../../components/HgResultsWindow'
import {usePaginationProps, type HgPaginationBlockProps} from '../HgPaginationBlock'

export default function HgItemsPerPageBlock({
  data,
  controlProps,
  className,
}: HgPaginationBlockProps) {
  const router = useRouter()

  const queryStringGenerator = useQueryStringGenerator()
  const {windowStart, windowEnd, itemsPerPage} = usePaginationProps({
    data,
    controlProps,
  })

  return (
    <div className={cn(grid({class: 'mx-auto w-full'}), className)}>
      <div
        className={gridItem({
          size: 'max',
          className: 'flex items-center justify-between',
        })}
      >
        <HgItemsPerPage
          perPage={itemsPerPage || controlProps?.itemsPerPage || 25}
          onChange={perPage => {
            controlProps?.onChange?.(perPage)
            router.push(queryStringGenerator({page: '', perPage: `${perPage}`}), {
              scroll: false,
            })
          }}
        />
        <HgResultsWindow
          resultsLength={data.length}
          windowStart={windowStart}
          windowEnd={windowEnd}
        />
      </div>
    </div>
  )
}
