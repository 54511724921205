'use client'
import {useEffect, useRef, useState} from 'react'
import {type VariantProps, tv} from 'tailwind-variants'
import {grid, gridItem} from '~/design-system/foundations'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '~/design-system/foundations/Table'
import HgIcon, {type HgIconType} from '~/design-system/hg/components/HgIcon'
import {safeUnreachableCase} from '~/utils/unreachableCase'

type HgTableTextCell = {
  type: 'text'
  body: React.ReactNode
}

type HgTableIconCell = {
  type: 'icon'
  body: HgIconType
}

type HgTableEmptyCell = {
  type: 'empty'
}

export type HgTableCellType = HgTableTextCell | HgTableIconCell | HgTableEmptyCell

export type HgTableRow = HgTableCellType[]

type HgTableVariantProps = VariantProps<typeof hgTableVariants>

export type HgTableProps = {
  caption?: React.ReactNode
  subtitle?: React.ReactNode
  columnTitles?: HgTableRow | null
  rows: HgTableRow[]
} & HgTableVariantProps

function HgTableCell({cell}: {cell: HgTableCellType}) {
  switch (cell.type) {
    case 'text':
      return cell.body
    case 'icon':
      return (
        <HgIcon size="regular" iconType={cell.body} className="text-icon-default" />
      )
    case 'empty':
      return null
    default:
      return safeUnreachableCase(cell, null)
  }
}

const hgTableVariants = tv({
  variants: {
    lastColumnAlignment: {
      left: '',
      right: {
        wrapper: '[&_td:last-of-type]:text-right [&_th:last-of-type]:text-right',
      },
    },
  },
  slots: {
    wrapper:
      '[&_t relative border-border-default after:pointer-events-none after:absolute after:bottom-0 after:right-0 after:top-0 after:w-[200px] after:bg-gradient-to-l after:from-background-default after:transition-opacity data-[scroll=false]:after:opacity-0 ',
    cellContent: 'min-w-[156px] py-s3 pr-s3',
    tableRow: 'grid grid-flow-col grid-cols-[repeat(auto-fit,minmax(156px,1fr))]',
  },
})

export default function HgTable({
  rows,
  caption,
  columnTitles,
  subtitle,
  lastColumnAlignment,
}: HgTableProps) {
  const {wrapper, cellContent, tableRow} = hgTableVariants({
    lastColumnAlignment,
  })
  const lastColumnRef = useRef<HTMLTableCellElement | null>(null)
  const wrapperRef = useRef<HTMLTableElement | null>(null)
  const [hasScroll, setHasScroll] = useState(false)

  useEffect(() => {
    if (!lastColumnRef.current) return
    const observer = new IntersectionObserver(
      ([entry]) => {
        setHasScroll(!entry.isIntersecting)
      },
      {threshold: 0.6, root: wrapperRef.current}
    )
    observer.observe(lastColumnRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto pt-s9'})}>
        {subtitle && (
          <h2
            className={gridItem({
              size: 'max',
              className: 'pb-s4 text-text-default arcadia-subheading-5',
            })}
          >
            {subtitle}
          </h2>
        )}
        <div
          className={gridItem({
            size: 'max',
            className: wrapper(),
          })}
          data-scroll={hasScroll}
          ref={wrapperRef}
        >
          <Table>
            <TableHeader className="text-text-emphasized arcadia-heading-9">
              {columnTitles && (
                <TableRow className={tableRow()}>
                  {columnTitles.map((column, index) => (
                    <TableHead key={index} className={cellContent()}>
                      <HgTableCell cell={column} />
                    </TableHead>
                  ))}
                </TableRow>
              )}
            </TableHeader>
            <TableBody className="text-text-default arcadia-body-2">
              {rows.map((column, rowIndex) => (
                <TableRow key={rowIndex} className={tableRow()}>
                  {column.map((cell, columnIndex) => (
                    <TableCell
                      key={columnIndex}
                      className={cellContent()}
                      ref={
                        columnIndex === column.length - 1 &&
                        rowIndex === rows.length - 1
                          ? lastColumnRef
                          : null
                      }
                    >
                      <HgTableCell cell={cell} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        {caption && (
          <small className="col relative col-span-full mt-s3 w-full text-left arcadia-body-3 before:absolute before:-left-[1ch] before:content-['*'] md:col-span-6 lg:col-start-3 3xl:col-span-4 3xl:col-start-3">
            {caption}
          </small>
        )}
      </div>
    </div>
  )
}
