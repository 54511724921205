import type * as THREE from 'three'

export const TIME_SCALE = 0.009

export type ThreeRenderTarget = THREE.WebGLRenderTarget<THREE.Texture> | null
export type FrameBufferObject = {
  vel_0: ThreeRenderTarget
  vel_1: ThreeRenderTarget

  // for calc pressure
  div: ThreeRenderTarget

  // for calc poisson equation
  pressure_0: ThreeRenderTarget
  pressure_1: ThreeRenderTarget
}

export type ShaderPassProps = {
  cellScale: THREE.Vector2
  fbos: FrameBufferObject
}

export type StableFluidBackgroundSettings = {
  /**
   * Strength of the mouse's pushing effect
   *
   * Recommended: number between **1 – 200**
   */
  mouseForce?: number
  /**
   * Area of the mouse force
   *
   * Recommended: number between **1 – 200**
   */
  cursorSize?: number
  /**
   * How much the liquid ripples
   *
   * Recommended: number between **1 – 32**
   *
   * @default 32
   */
  poissonIterations?: number
  /**
   * Resolution of the canvas
   *
   * Recommended: number between **0 – 1**
   *
   * *Warning: High resolutions *will* impact performance. Be sure to strike a balance between the size of the canvas and the provided resolution.*
   * @default 1
   */
  resolution?: number
}
