import {type PropsWithChildren} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {type OmitStrict} from 'type-zoo'
import {gridItem} from '~/design-system/foundations'

const orderedListVariants = tv({
  base: 'list-inside text-text-default tiempos-body-2 [&_li_+_li]:mt-4 md:[&_li_+_li]:mt-8 lg:[&_li_+_li]:mt-12 [&_li_>_ol]:mt-4 md:[&_li_>_ol]:mt-8 lg:[&_li_>_ol]:mt-12',
  variants: {
    nestedLevel: {
      0: 'list-decimal',
      1: 'list-[lower-alpha] ps-s3',
      2: 'list-[lower-roman] ps-[calc(var(--s3)*2)]',
    },
  },
})

type HgOrderedListNestedLevel = NonNullable<
  VariantProps<typeof orderedListVariants>['nestedLevel']
>

export type HgOrderedListProps = PropsWithChildren<{
  className?: string
  nestedLevel: HgOrderedListNestedLevel
}>

export const HgOrderedList = ({
  children,
  className,
  nestedLevel,
}: HgOrderedListProps) => {
  return (
    <ol className={orderedListVariants({nestedLevel, className})}>{children}</ol>
  )
}

export const HgArticleWYSIWYGOrderedList = ({
  nestedLevel,
  ...props
}: OmitStrict<HgOrderedListProps, 'className'>) => {
  return (
    <HgOrderedList
      {...props}
      nestedLevel={nestedLevel || 0}
      className={gridItem({size: 'heading'})}
    />
  )
}
