import {cn} from '~/design-system/utils'
import HgIcon from '../HgIcon'

type HgLoadingDotsProps = {
  className?: string
}

/**
 * This component renders 3 animated dots that indicate a loading state.
 */
const HgLoadingDots = ({className}: HgLoadingDotsProps) => {
  return (
    <div className={cn('flex items-center text-icon-default', className)}>
      <span className="sr-only">Loading...</span>
      <div className="mt-[10px] flex flex-row space-x-12">
        <HgIcon
          iconType="placeholder"
          size="small"
          className={
            'h-[0.375rem] w-[0.375rem] animate-wave fill-current text-inherit'
          }
        />
        <HgIcon
          iconType="placeholder"
          size="small"
          className="h-[0.375rem] w-[0.375rem] animate-wave fill-current text-inherit animation-delay-100"
        />
        <HgIcon
          iconType="placeholder"
          size="small"
          className="h-[0.375rem] w-[0.375rem] animate-wave fill-current text-inherit animation-delay-200"
        />
      </div>
    </div>
  )
}

export default HgLoadingDots
