export type BackgroundColor =
  | 'background-default'
  | 'background-secondary'
  | 'background-frosted'

export type SurfaceColor =
  | 'surface-default'
  | 'surface-default-hover'
  | 'surface-default-active'
  | 'surface-emphasized'
  | 'surface-emphasized-hover'
  | 'surface-emphasized-active'
  | 'surface-frosted'
  | 'surface-default-active'
  | 'surface-frosted'
  | 'surface-frosted-hover'
  | 'surface-frosted-active'
  | 'surface-primary'
  | 'surface-primary-hover'
  | 'surface-primary-active'
  | 'surface-primary-disabled'
  | 'surface-elevated'
  | 'surface-elevated-hover'
  | 'surface-elevated-active'
  | 'surface-input'
  | 'surface-input-hover'
  | 'surface-input-active'
  | 'surface-input-disabled'
  | 'surface-inverted'

export type TextColor =
  | 'text-default'
  | 'text-subdued'
  | 'text-emphasized'
  | 'text-primary'
  | 'text-on-primary'
  | 'text-on-inverted'
  | 'text-error'
  | 'text-disabled'

export type IconColor =
  | 'icon-default'
  | 'icon-subdued'
  | 'icon-emphasized'
  | 'icon-primary'
  | 'icon-on-primary'
  | 'icon-disabled'
  | 'icon-on-inverted'
  | 'icon-error'

export type BorderColor =
  | 'border-default'
  | 'border-subdued'
  | 'border-emphasized'
  | 'border-primary'
  | 'border-focus'
  | 'border-error'
  | 'border-input'
  | 'border-frosted'

type SemanticColor =
  | BackgroundColor
  | SurfaceColor
  | TextColor
  | IconColor
  | BorderColor

export type SemanticColorRecord = Record<SemanticColor, string>

// NB: if a new semantic color token is added here, ensure it is also added to src/app/(new)/globals.css
export const semanticColorToCssVarMap: SemanticColorRecord = {
  'background-default': 'var(--background-default)',
  'background-secondary': 'var(--background-secondary)',
  'background-frosted': 'var(--background-frosted)',

  'surface-default': 'var(--surface-default)',
  'surface-default-hover': 'var(--surface-default-hover)',
  'surface-default-active': 'var(--surface-default-active)',
  'surface-emphasized': 'var(--surface-emphasized)',
  'surface-emphasized-hover': 'var(--surface-emphasized-hover)',
  'surface-emphasized-active': 'var(--surface-emphasized-active)',
  'surface-frosted': 'var(--surface-frosted)',
  'surface-frosted-hover': 'var(--surface-frosted-hover)',
  'surface-frosted-active': 'var(--surface-frosted-active)',
  'surface-primary': 'var(--surface-primary)',
  'surface-primary-hover': 'var(--surface-primary-hover)',
  'surface-primary-active': 'var(--surface-primary-active)',
  'surface-primary-disabled': 'var(--surface-primary-disabled)',
  'surface-elevated': 'var(--surface-elevated)',
  'surface-elevated-hover': 'var(--surface-elevated-hover)',
  'surface-elevated-active': 'var(--surface-elevated-active)',
  'surface-input': 'var(--surface-input)',
  'surface-input-hover': 'var(--surface-input-hover)',
  'surface-input-active': 'var(--surface-input-active)',
  'surface-input-disabled': 'var(--surface-input-disabled)',
  'surface-inverted': 'var(--surface-inverted)',

  'text-default': 'var(--text-default)',
  'text-subdued': 'var(--text-subdued)',
  'text-emphasized': 'var(--text-emphasized)',
  'text-primary': 'var(--text-primary)',
  'text-on-primary': 'var(--text-on-primary)',
  'text-on-inverted': 'var(--text-on-inverted)',
  'text-error': 'var(--text-error)',
  'text-disabled': 'var(--text-disabled)',

  'icon-default': 'var(--icon-default)',
  'icon-subdued': 'var(--icon-subdued)',
  'icon-emphasized': 'var(--icon-emphasized)',
  'icon-on-primary': 'var(--icon-on-primary)',
  'icon-primary': 'var(--icon-primary)',
  'icon-disabled': 'var(--icon-disabled)',
  'icon-on-inverted': 'var(--icon-on-inverted)',
  'icon-error': 'var(--icon-error)',

  'border-default': 'var(--border-default)',
  'border-subdued': 'var(--border-subdued)',
  'border-emphasized': 'var(--border-emphasized)',
  'border-primary': 'var(--border-primary)',
  'border-focus': 'var(--border-focus)',
  'border-error': 'var(--border-error)',
  'border-input': 'var(--border-input)',
  'border-frosted': 'var(--border-frosted)',
}
