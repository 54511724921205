import {forwardRef} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {LinkWrapper, type LinkWrapperProps} from '~/design-system/foundations'
import HgIcon from '../HgIcon'

export type HgTextLinkVariant = VariantProps<typeof textLink>['type']

export type HgTextLinkProps = LinkWrapperProps & {
  children: React.ReactNode | string
  variant?: HgTextLinkVariant
  analyticsEventName?: string
  // These "force" states are solely for use in storybook to be able to see the different link states
  forceHover?: boolean
  forceFocusVisible?: boolean
  forceActive?: boolean
}

export const textLink = tv({
  slots: {
    base: `inline-flex w-full max-w-fit items-center rounded text-text-default underline-offset-4 outline-2 outline-offset-4 outline-border-focus hover:text-text-subdued hover:transition-colors hover:duration-300 hover:ease-out focus-visible:no-underline focus-visible:outline active:text-text-emphasized`,
    linkChildren: '',
    withArrow: 'ml-8',
    externalArrow: 'ml-8',
  },
  variants: {
    type: {
      default: {
        base: 'arcadia-ui-1 hover:underline hover:decoration-border-default active:underline active:decoration-border-emphasized',
        withArrow: 'hidden',
        externalArrow: 'hidden',
      },
      withArrow: {
        base: 'group decoration-border-default arcadia-ui-1 active:decoration-border-emphasized',
        withArrow:
          'opacity-0 transition-opacity duration-300 ease-out arcadia-ui-1 group-hover:opacity-100 group-focus-visible:opacity-100 group-active:opacity-100',
        externalArrow: 'hidden',
      },
      inline: {
        base: 'underline decoration-border-subdued hover:decoration-border-default active:decoration-border-emphasized',
        withArrow: 'hidden',
        externalArrow: 'hidden',
      },
      disclaimer: {
        base: 'inline text-inherit underline decoration-border-default active:decoration-border-emphasized',
        withArrow: 'hidden',
        externalArrow: 'hidden',
      },
      external: {
        base: 'group arcadia-ui-1 hover:underline active:underline active:decoration-border-emphasized',
        linkChildren: 'text-nowrap',
        withArrow: 'hidden',
      },
    },
    forceHover: {true: 'text-text-subdued decoration-border-default'},
    forceFocusVisible: {
      true: 'outline',
    },
    forceActive: {true: 'text-text-emphasized decoration-border-emphasized'},
  },
  compoundVariants: [
    {
      type: 'default',
      forceHover: true,
      class: {base: 'underline'},
    },
    {
      type: 'default',
      forceActive: true,
      class: {base: 'underline'},
    },
    {
      type: 'withArrow',
      forceHover: true,
      class: {withArrow: 'opacity-100'},
    },
    {
      type: 'withArrow',
      forceFocusVisible: true,
      class: {withArrow: 'opacity-100'},
    },
    {
      type: 'withArrow',
      forceActive: true,
      class: {withArrow: 'opacity-100'},
    },
    {
      type: 'external',
      forceHover: true,
      class: {base: 'underline'},
    },
    {
      type: 'external',
      forceActive: true,
      class: {base: 'underline'},
    },
  ],
  defaultVariants: {
    type: 'default',
  },
})

const HgTextLink = forwardRef<HTMLAnchorElement, HgTextLinkProps>(
  (
    {
      children,
      className,
      forceHover = false,
      forceFocusVisible = false,
      forceActive = false,
      variant = 'default',
      analyticsEventName,
      ...props
    },
    ref
  ) => {
    const {base, externalArrow, linkChildren, withArrow} = textLink({
      type: variant,
      forceHover,
      forceFocusVisible,
      forceActive,
    })

    return (
      <LinkWrapper
        data-analytics-event-name={analyticsEventName}
        {...props}
        ref={ref}
        className={base({class: className})}
      >
        <span className={linkChildren()}>{children}</span>
        <HgIcon
          iconType="arrow-up-right-angle"
          size="regular"
          className={externalArrow()}
        />
        <HgIcon iconType="arrow-right" size="regular" className={withArrow()} />
      </LinkWrapper>
    )
  }
)
HgTextLink.displayName = 'HgTextLink'

export default HgTextLink
