'use client'

import React, {useEffect, useRef, useState} from 'react'
import {tv} from 'tailwind-variants'
import {grid, gridItem} from '~/design-system/foundations'
import {Table, TableBody, TableRow} from '~/design-system/foundations/Table'
import {useIsLargerThanMobile} from '~/design-system/hooks/useMediaQuery'
import {cn} from '~/design-system/utils'
import HgPricingTableCell, {
  type HgPricingTableCellType,
} from './_components/PricingTableCell'
import HgStickyPricingHeader from './_components/PricingTableStickyHeader'

export type HgPricingTableRowContent = HgPricingTableCellType[]

export type HgPricingTableSection = {
  sectionTitle: Extract<HgPricingTableCellType, {type: 'section-title'}>
  rows: HgPricingTableRowContent[]
}

export type HgPricingTableSections = HgPricingTableSection[]

export type HgPricingTableProps = {
  sections: HgPricingTableSections
  planTitles: string[]
}

export type HgPricingPlanSelection = {
  primaryPlan: string
  secondaryPlan: string | ''
}

const hgTableVariants = tv({
  base: 'relative border-border-default text-text-default',
  slots: {
    table: 'flex flex-col border-t-0',
    tableWrapper: 'overflow-visible pb-s9',
    wrapper: 'mx-auto w-full',
    gridItem: 'max',
    tableBody:
      'relative w-full divide-y pb-s9 text-text-default arcadia-body-2 last:pb-0',
    tableRow:
      'grid grid-cols-2 gap-s4 pb-s6 pt-s3 md:grid-flow-col md:grid-cols-[repeat(4,minmax(150px,1fr))] md:p-0',
  },
})

const hgStickySectionTitleVariants = tv({
  base: 'sticky top-[var(--height)] z-20 flex grid w-full grid-cols-1 content-center items-center bg-background-default py-s4 text-left duration-[350ms] arcadia-heading-9 md:top-navbar-height md:col-span-1 md:grid-cols-[repeat(4,minmax(150px,1fr))] md:border-b-0 md:bg-transparent-transparent md:p-0',
})

type HgPricingTableSectionTitleProps = {
  sectionTitle: HgPricingTableCellType
  headerHeight: number
}

const HgPricingTableSectionTitle: React.FC<HgPricingTableSectionTitleProps> = ({
  sectionTitle,
  headerHeight,
}) => {
  return (
    <TableRow
      className={cn(hgStickySectionTitleVariants())}
      style={{'--height': `${headerHeight}px`} as React.CSSProperties}
    >
      <HgPricingTableCell cell={sectionTitle} />
    </TableRow>
  )
}

type HgPricingTableRowProps = {
  row: HgPricingTableRowContent
  selectedPlans: HgPricingPlanSelection
  isNotMobile: boolean
  planOptions: Array<{value: string; label: string}>
}

export const HgPricingTableRow: React.FC<HgPricingTableRowProps> = ({
  row,
  selectedPlans,
  isNotMobile,
  planOptions,
}) => {
  const {tableRow} = hgTableVariants()
  const [visibleCells, setVisibleCells] = useState<HgPricingTableCellType[]>(row)

  useEffect(() => {
    const getPlanIndex = (planId: string | null): number | null => {
      if (!planId) return null
      const index = planOptions.findIndex(option => option.value === planId)
      return index === -1 ? null : index + 1
    }

    const getVisibleRowCells = (
      row: HgPricingTableRowContent
    ): HgPricingTableCellType[] => {
      if (isNotMobile) return row
      const rowTitleCell = row[0]
      const selectedIndices = [
        selectedPlans.primaryPlan,
        selectedPlans.secondaryPlan,
      ]
        .map(getPlanIndex)
        .filter((index): index is number => index !== null)
      const planCells = selectedIndices.map(index => row[index])
      return [rowTitleCell, ...planCells]
    }

    setVisibleCells(getVisibleRowCells(row))
  }, [row, selectedPlans, isNotMobile, planOptions])

  return (
    <TableRow className={tableRow()}>
      {visibleCells.map((cell, columnIndex) => (
        <HgPricingTableCell
          cell={cell}
          key={columnIndex}
          oneColumn={!selectedPlans.secondaryPlan}
        />
      ))}
    </TableRow>
  )
}

export default function HgPricingTable({sections, planTitles}: HgPricingTableProps) {
  const {tableWrapper, table, tableBody, wrapper} = hgTableVariants()

  const isNotMobile = useIsLargerThanMobile()

  const planOptions = planTitles.map((title, index) => ({
    value: `plan-${index + 1}`,
    label: title,
  }))

  const [selectedPlans, setSelectedPlans] = useState<HgPricingPlanSelection>({
    primaryPlan: planOptions[0].value,
    secondaryPlan: planOptions[1]?.value || '',
  })

  const handleSelectionChange = (selection: HgPricingPlanSelection) => {
    setSelectedPlans(selection)
  }

  const headerRef = useRef<HTMLTableSectionElement>(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    const header = headerRef.current
    if (!header) return

    const updateHeaderHeight = ([entry]: ResizeObserverEntry[]) => {
      const height = entry.borderBoxSize?.[0]?.blockSize || entry.contentRect.height
      setHeaderHeight(height)
    }

    const resizeObserver = new ResizeObserver(updateHeaderHeight)
    resizeObserver.observe(header)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <div
          className={gridItem({
            size: 'max',
            className: wrapper(),
          })}
        >
          <Table
            classNames={{
              table: table(),
              wrapper: tableWrapper(),
            }}
          >
            <HgStickyPricingHeader
              ref={headerRef}
              planTitles={planTitles}
              selectedPlans={selectedPlans}
              onSelectionChange={handleSelectionChange}
              planOptions={planOptions}
            />
            {sections.map((section, idx) => (
              <TableBody key={idx} className={tableBody()}>
                <HgPricingTableSectionTitle
                  sectionTitle={section.sectionTitle}
                  headerHeight={headerHeight}
                />
                {section.rows.map((row, rowIndex) => (
                  <HgPricingTableRow
                    key={rowIndex}
                    row={row}
                    selectedPlans={selectedPlans}
                    isNotMobile={isNotMobile}
                    planOptions={planOptions}
                  />
                ))}
              </TableBody>
            ))}
          </Table>
        </div>
      </div>
    </div>
  )
}
