import {type ReactNode} from 'react'
import {type OmitStrict} from 'type-zoo'
import {cn} from '~/design-system/utils'

type HgPullQuoteProps = {
  quote: ReactNode
  author?: ReactNode
  context?: ReactNode
  className?: string
}

export const HgPullQuote = ({
  quote,
  author,
  context,
  className,
}: HgPullQuoteProps) => {
  return (
    <div
      aria-hidden="true"
      className={cn('flex flex-col items-center space-y-s3 text-center', className)}
    >
      <p className="text-text-default tiempos-heading-2">{quote}</p>
      {author && (
        <p className="text-text-default arcadia-body-2">
          {author}
          {context && ', '}
          {context}
        </p>
      )}
    </div>
  )
}

export const HgArticleWYSIWYGPullQuote = (
  props: OmitStrict<HgPullQuoteProps, 'className'>
) => {
  return (
    <HgPullQuote
      {...props}
      className="col-span-full grid lg:col-span-12 lg:col-start-3"
    />
  )
}
