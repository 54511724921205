import {tv} from 'tailwind-variants'

type HamburgerProps = {
  navOpen: boolean
}

const hamburgerVariants = tv({
  slots: {
    container: 'relative h-[24px] w-[21px]',
    bar: 'pointer-events-none absolute left-0 top-[50%] h-2 w-full rounded-sm bg-icon-default transition-[transform,opacity] duration-300 group-hover:bg-icon-subdued group-active:bg-icon-emphasized',
  },
  variants: {
    position: {
      top: {
        bar: '-translate-y-8',
      },
      middle: {
        bar: '',
      },
      bottom: {
        bar: 'translate-y-8',
      },
    },
    navOpen: {
      true: '',
    },
  },
  compoundVariants: [
    {
      position: 'top',
      navOpen: true,
      class: {
        bar: 'translate-y-0 rotate-[45deg]',
      },
    },
    {
      position: 'middle',
      navOpen: true,
      class: {
        bar: '-translate-x-1/4 opacity-0',
      },
    },
    {
      position: 'bottom',
      navOpen: true,
      class: {
        bar: 'translate-y-0 -rotate-[45deg]',
      },
    },
  ],
})

export default function HgHamburger({navOpen}: HamburgerProps) {
  const {container, bar} = hamburgerVariants({navOpen})
  return (
    <div className={container()}>
      <span className={bar({position: 'top'})} />
      <span className={bar({position: 'middle'})} />
      <span className={bar({position: 'bottom'})} />
    </div>
  )
}
