import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {env} from '~/config/env.mjs'

function getBugsnagClient() {
  if (env.NEXT_PUBLIC_BUGSNAG_NO_REPORT) return null

  // next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
  // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
  // more details on NEXT_PHASE
  if (process.env.NEXT_PHASE !== 'phase-production-build') {
    return Bugsnag.start({
      apiKey: env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      appVersion: process.env.BUGSNAG_APP_VERSION,
      // you can add 'development' here to see Bugsnags from dev
      // (they are separated out on the dashboard from prod errors, and don't go to Slack/email by default)
      enabledReleaseStages: ['production', 'development', 'staging'],
      releaseStage: env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE,
      plugins: [new BugsnagPluginReact()],

      onError(event) {
        // The request was blocked somewhere (e.g. adblocker, pihole, etc)
        if (
          ['invalid_request', 'delay_connect', 'unauthorized'].includes(
            event.originalError
          )
        ) {
          return false
        }

        if (event.originalError instanceof Error) {
          const bannedMessages = ['Load failed', 'Illegal invocation']

          if (bannedMessages.includes(event.originalError.message)) {
            return false
          }
        }

        // Some tracking script is throwing JSON errors, we don't really care about it.
        if (typeof event.originalError === 'object') {
          if ('isTrusted' in event.originalError) {
            return false
          }
          if ('isCanceled' in event.originalError) {
            return false
          }
          if (
            'type' in event.originalError &&
            event.originalError.type === 'AbortError'
          ) {
            return false
          }
        }

        return true
      },

      // By default this will exit the program which kills the server. We don't want that.
      // https://github.com/bugsnag/bugsnag-js/blob/e993336c071d80760e6c8c963e47e8bed09ea526/packages/node/src/config.js#L34-L41
      onUncaughtException(err, event, logger) {
        logger.error(
          `Uncaught exception. Err: ${String(err)}. Event: ${String(event)}`
        )
      },
    })
  }

  return null
}

export const bugsnagClient = getBugsnagClient()
