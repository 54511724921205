import {gridItem} from '~/design-system/foundations'
import {cn} from '~/design-system/utils'

export const HgDivider = () => {
  return (
    <div className={'flex w-full justify-center'}>
      {/* cspell:disable-next-line */}
      <hr className='h-[12px] min-h-[12px] w-[44px] min-w-[44px] bg-icon-subdued [mask-image:_url("data:image/svg+xml,%3Csvg%20width%3D%2244%22%20height%3D%2212%22%20viewBox%3D%220%200%2044%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.34314%22%20y%3D%222%22%20width%3D%228%22%20height%3D%228%22%20fill%3D%22currentColor%22%20%2F%3E%3Crect%20x%3D%2222%22%20y%3D%220.342773%22%20width%3D%228%22%20height%3D%228%22%20transform%3D%22rotate(45%2022%200.342773)%22%20fill%3D%22currentColor%22%20%2F%3E%3Crect%20x%3D%2235.6569%22%20y%3D%222%22%20width%3D%228%22%20height%3D%228%22%20fill%3D%22currentColor%22%20%2F%3E%3C%2Fsvg%3E")]' />
    </div>
  )
}

export const HgArticleWYSIWYGDivider = ({className}: {className?: string}) => (
  <div className={gridItem({size: 'max', className: cn('pt-s7', className)})}>
    <HgDivider />
  </div>
)
