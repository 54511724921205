'use client'

import {RadioGroup} from '~/design-system/foundations/Radio'
import {useId} from 'react'
import {type RadioGroupProps} from '@radix-ui/react-radio-group'
import {cn} from '~/design-system/utils'
import HgRadioButton, {type HgRadioButtonProps} from '../HgRadioButton'
import {Label} from '~/design-system/foundations'

export type HgRadioButtonInputProps = {
  options: HgRadioButtonProps[]
  value?: string
  label: React.ReactNode
  secondaryLabel?: React.ReactNode
  classNames?: {
    container?: string
    optionsContainer?: string
    label?: string
  }
} & RadioGroupProps

export default function HgRadioButtonInput({
  options,
  label,
  secondaryLabel,
  classNames,
  ...props
}: HgRadioButtonInputProps) {
  const labelId = useId()
  const secondaryLabelId = useId()
  return (
    <div
      className={cn(
        'flex h-full flex-col text-text-subdued hover:text-text-default',
        classNames?.container
      )}
    >
      <Label className={cn('arcadia-body-3', classNames?.label)} id={labelId}>
        {label}
      </Label>
      <RadioGroup
        aria-labelledby={labelId + (secondaryLabelId ? ` ${secondaryLabelId}` : '')}
        orientation="vertical"
        className={cn('gap-s3 pt-s3', classNames?.optionsContainer)}
        {...props}
      >
        {options.map(option => (
          <HgRadioButton key={option.label} {...option} />
        ))}
      </RadioGroup>
      {secondaryLabel && (
        <Label className="text-text-subdued arcadia-body-3" id={secondaryLabelId}>
          {secondaryLabel}
        </Label>
      )}
    </div>
  )
}
