import {Slot} from '@radix-ui/react-slot'
import {type PropsWithChildren} from 'react'
import {cn} from '~/design-system/utils'

export type HgImageProps = PropsWithChildren<{
  className?: string
}>

const HgImage = ({children, className}: HgImageProps) => {
  return (
    <Slot className={cn('h-full w-full object-cover', className)}>{children}</Slot>
  )
}

export default HgImage
