import React from 'react'
import {grid, gridItem} from '~/design-system/foundations'
import {HgBreadcrumb, type HgBreadcrumbProps} from '~/design-system/hg/components'

const HgDetailBreadcrumbs: React.FC<HgBreadcrumbProps> = ({breadcrumbs}) => {
  return (
    <div className="w-full pt-s9">
      <div className={grid({className: 'mx-auto'})}>
        <div
          className={gridItem({
            size: 'max',
          })}
        >
          <HgBreadcrumb breadcrumbs={breadcrumbs} />
        </div>
      </div>
    </div>
  )
}

export default HgDetailBreadcrumbs
