const faceVert = /* glsl */ `
precision mediump float;

varying vec2 vUv;
uniform vec2 u_px;
uniform vec2 u_boundarySpace;


void main(){
    vec3 pos = position;
    vec2 scale = 1.0 - u_boundarySpace * 2.0;
    pos.xy = pos.xy * scale;
    vUv = vec2(0.5)+(pos.xy)*0.5;
    gl_Position = vec4(pos, 1.0);
}
`

export default faceVert
