import {useEffect, useState, type RefObject} from 'react'

type ObserverOptions = {
  once?: boolean
} & IntersectionObserverInit

// https://dev.to/jmalvarez/check-if-an-element-is-visible-with-react-hooks-27h8
export function useIsVisible(
  ref: RefObject<any>,
  observerOptions: ObserverOptions = {}
) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (!ref.current) return
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)

      if (observerOptions.once && entry.isIntersecting) {
        observer.disconnect()
      }
    }, observerOptions)

    observer.observe(ref?.current)
    return () => {
      observer.disconnect()
    }
  }, [ref, observerOptions])

  return isIntersecting
}
