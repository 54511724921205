import * as HoverCardPrimitive from '@radix-ui/react-hover-card'
import React from 'react'

const HoverCardRoot = HoverCardPrimitive.Root

const HoverCardTrigger = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Trigger>
>(({children, ...props}, ref) => (
  <HoverCardPrimitive.Trigger asChild ref={ref} {...props}>
    {children}
  </HoverCardPrimitive.Trigger>
))

HoverCardTrigger.displayName = HoverCardPrimitive.Trigger.displayName

const HoverCardContent = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content>
>(({className, children, ...props}, ref) => (
  <HoverCardPrimitive.Portal>
    <HoverCardPrimitive.Content ref={ref} className={className} {...props}>
      {children}
    </HoverCardPrimitive.Content>
  </HoverCardPrimitive.Portal>
))

HoverCardContent.displayName = HoverCardPrimitive.Content.displayName

export {HoverCardContent, HoverCardRoot, HoverCardTrigger}
