import React from 'react'

export type HgLongformProps = {
  body: string | React.ReactNode
}

const HgLongform = ({body}: HgLongformProps) => {
  return (
    <p className="whitespace-pre-line text-left text-text-subdued arcadia-body-2">
      {body}
    </p>
  )
}

export default HgLongform
