'use client'

import {debounce} from 'lodash'
import {usePathname, useRouter, useSearchParams} from 'next/navigation'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {tv} from 'tailwind-variants'
import {Button, grid} from '~/design-system/foundations'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '~/design-system/foundations/Dialog'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/design-system/foundations/Popover'
import {HgButton, HgFilter, HgSearchInput} from '../../components'
import {
  type HgFilterRangeTabState,
  type HgFilterTabPropsWithContent,
  type HgFilterTabStateRecord,
} from '../../components/HgFilter/types'
import {textLink} from '../../components/HgTextLink'
import {type HgDatabaseTableProps} from '../HgDatabaseTable/types'
import ExpandableTagGroup from './_components/ExpandableTagGroup'
import {formatFilterLabel} from './_utils/formatTagLabels'

export type FilterDisplayConfig = HgDatabaseTableProps['filterDisplayConfig']

export type HgFilterSearchProps = {
  filterTabs: HgFilterTabPropsWithContent[]
  filterDisplayConfig: FilterDisplayConfig
  isSticky?: boolean
  className?: string
}

const HgFilterSearchVariants = tv({
  slots: {
    wrapper: 'w-full',
    gridStyles: 'mx-auto gap-y-s4 pt-s9 md:gap-y-s3',
    filterButtonWrapper:
      'order-2 col-span-full flex items-center gap-s4 md:order-1 md:col-span-8 md:gap-s3 lg:col-start-3',
    filterCountWrapper:
      'flex min-h-32 items-center gap-s3 border-l border-border-frosted pl-s3 arcadia-ui-1 lg:h-32',
    searchInput:
      'order-1 col-span-full md:order-2 md:col-span-4 md:col-start-9 lg:col-start-11',
    popoverContent: 'z-[5] hidden md:block md:h-[436px] md:w-full',
    dialogContent:
      'bg-background bg-background fixed inset-0 z-50 overflow-hidden p-0 shadow-none md:hidden',
  },
  variants: {
    isSticky: {
      true: {
        wrapper:
          // filter module has top padding of `s9`, so position needs to account for that
          'sticky top-[calc(var(--s3)-(var(--s9)-var(--navbar-height)))] z-[2] bg-background-default',
      },
    },
  },
})

function searchParamsUpdated({
  previous,
  current,
  excludedParams = [],
}: {
  previous: URLSearchParams
  current: URLSearchParams
  excludedParams?: string[]
}) {
  const previousEntries = Array.from(previous.entries()).filter(
    ([key]) => !excludedParams.includes(key)
  )

  const currentEntries = Array.from(current.entries()).filter(
    ([key]) => !excludedParams.includes(key)
  )

  if (previousEntries.length !== currentEntries.length) {
    return true
  }

  for (const [key, value] of currentEntries) {
    if (!previous.has(key) || previous.get(key) !== value) {
      return true
    }
  }
}

export default function HgFilterSearch({
  filterTabs,
  filterDisplayConfig,
  isSticky,
  className,
}: HgFilterSearchProps) {
  const {base} = textLink({type: 'inline'})
  const searchParams = useSearchParams()
  const router = useRouter()
  const pathname = usePathname()
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const memoizedFilterTabs = useMemo(() => filterTabs, [filterTabs])

  // Initialize filter state from URL params
  const initializeFilterState = useCallback(() => {
    const initialState: HgFilterTabStateRecord = {}
    memoizedFilterTabs.forEach(tab => {
      if (tab.type === 'checkbox' || tab.type === 'radio') {
        const paramValue = searchParams.get(tab.tabValue)
        if (paramValue) {
          initialState[tab.tabValue] =
            tab.type === 'radio' ? paramValue : paramValue.split(',')
        }
      } else if (tab.type === 'range') {
        const minValue = searchParams.get(`${tab.tabValue}-min`)
        const maxValue = searchParams.get(`${tab.tabValue}-max`)
        if (minValue || maxValue) {
          initialState[tab.tabValue] = {min: minValue || '', max: maxValue || ''}
        }
      }
    })
    return initialState
  }, [memoizedFilterTabs, searchParams])

  const [filterState, setFilterState] = useState<HgFilterTabStateRecord>(
    initializeFilterState()
  )

  // Update URL when filter state changes
  const updateURLParams = useCallback(
    (newState: HgFilterTabStateRecord) => {
      const newParams = new URLSearchParams(searchParams)

      // Remove existing filter params
      filterTabs.forEach(tab => {
        newParams.delete(tab.tabValue)
        newParams.delete(`${tab.tabValue}-min`)
        newParams.delete(`${tab.tabValue}-max`)
      })

      // Add new filter params
      Object.entries(newState).forEach(([key, value]) => {
        const tab = filterTabs.find(t => t.tabValue === key)
        if (!tab) return

        switch (tab.type) {
          case 'checkbox':
            if (Array.isArray(value) && value.length > 0) {
              newParams.set(key, value.join(','))
            }
            break
          case 'radio':
            if (value) {
              newParams.set(key, value as string)
            }
            break
          case 'range': {
            const rangeValue = value as HgFilterRangeTabState
            if (rangeValue.min) newParams.set(`${key}-min`, rangeValue.min)
            if (rangeValue.max) newParams.set(`${key}-max`, rangeValue.max)
            break
          }
        }
      })

      // Update query param
      if (searchQuery) {
        newParams.set('query', searchQuery)
      } else {
        newParams.delete('query')
      }

      if (
        searchParamsUpdated({
          previous: searchParams,
          current: newParams,
          excludedParams: ['page'],
        })
      ) {
        newParams.delete('page')
      }

      router.push(`${pathname}?${newParams.toString()}`, {scroll: false})
    },
    [searchParams, filterTabs, pathname, router, searchQuery]
  )

  // Handle filter changes
  const handleFilterChange = useCallback(
    (newState: HgFilterTabStateRecord) => {
      setFilterState(newState)
      updateURLParams(newState)
    },
    [updateURLParams]
  )

  // Handle search query changes
  useEffect(() => {
    const debouncedHandleQuery = debounce(() => {
      updateURLParams(filterState)
    }, 75)

    debouncedHandleQuery()

    return () => {
      debouncedHandleQuery.cancel()
    }
  }, [updateURLParams, filterState])

  const handleTagDismiss = useCallback(
    (tagId: string) => {
      const [key, value] = tagId.split('-')
      const newState = {...filterState}

      if (Array.isArray(newState[key])) {
        newState[key] = (newState[key] as string[]).filter(v => v !== value)
        if ((newState[key] as string[]).length === 0) {
          const {[key]: _, ...rest} = newState
          handleFilterChange(rest)
        } else {
          handleFilterChange(newState)
        }
      } else {
        const {[key]: _, ...rest} = newState
        handleFilterChange(rest)
      }
    },
    [filterState, handleFilterChange]
  )

  const filterTags = useMemo(() => {
    return Object.entries(filterState).flatMap(([key, value]) => {
      const tab = filterTabs.find(tab => tab.tabValue === key)
      if (!tab) return []

      if (Array.isArray(value)) {
        return value.map(v => ({
          id: `${key}-${v}`,
          body: (
            <p>
              {formatFilterLabel(key, v, tab.label as string, filterDisplayConfig)}
            </p>
          ),
          isDismissible: true,
          onDismiss: () => {
            handleTagDismiss(`${key}-${v}`)
          },
        }))
      } else if (typeof value === 'object' && value !== null) {
        return [
          {
            id: `${key}-range`,
            body: (
              <p>
                {formatFilterLabel(
                  key,
                  value as string,
                  tab.label as string,
                  filterDisplayConfig
                )}
              </p>
            ),
            isDismissible: true,
            onDismiss: () => {
              handleTagDismiss(`${key}-range`)
            },
          },
        ]
      } else if (value !== undefined) {
        return [
          {
            id: `${key}-${value}`,
            body: (
              <p>
                {formatFilterLabel(
                  key,
                  value,
                  tab.label as string,
                  filterDisplayConfig
                )}
              </p>
            ),
            isDismissible: true,
            onDismiss: () => {
              handleTagDismiss(`${key}-${value}`)
            },
          },
        ]
      }
      return []
    })
  }, [filterState, filterTabs, filterDisplayConfig, handleTagDismiss])

  const resetFilters = useCallback(() => {
    handleFilterChange({})
  }, [handleFilterChange])

  const filterCount = filterTags.length

  const {
    wrapper,
    gridStyles,
    filterButtonWrapper,
    filterCountWrapper,
    searchInput,
    popoverContent,
    dialogContent,
  } = HgFilterSearchVariants({isSticky})

  const filterContent = useMemo(
    () => (
      <HgFilter
        tabs={memoizedFilterTabs}
        onFilterChange={filters => {
          if (!isPopoverOpen && !isDialogOpen) {
            return
          }
          handleFilterChange(filters)
        }}
        closeMenu={() => {
          setIsPopoverOpen(false)
          setIsDialogOpen(false)
        }}
        initialState={filterState}
      />
    ),
    [
      filterState,
      handleFilterChange,
      isDialogOpen,
      isPopoverOpen,
      memoizedFilterTabs,
    ]
  )

  const renderPopover = useMemo(() => {
    return (
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <HgButton
            variant="frosted"
            size="default"
            iconProps={{iconType: 'filter', position: 'left'}}
            onClick={() => {
              setIsPopoverOpen(true)
            }}
            className="hidden md:flex"
          >
            Add Filters
          </HgButton>
        </PopoverTrigger>
        <PopoverContent className={popoverContent()} align="start" side="bottom">
          {filterContent}
        </PopoverContent>
      </Popover>
    )
  }, [isPopoverOpen, popoverContent, filterContent])

  const renderDialog = useMemo(
    () => (
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogTrigger asChild>
          <HgButton
            variant="frosted"
            size="default"
            iconProps={{iconType: 'filter', position: 'left'}}
            onClick={() => {
              setIsDialogOpen(true)
            }}
            className="md:hidden"
          >
            Add Filters
          </HgButton>
        </DialogTrigger>
        <DialogTitle className="sr-only">Filter Menu</DialogTitle>
        <DialogDescription className="sr-only">
          Apply filters to the table, click close when done
        </DialogDescription>
        <DialogContent className={dialogContent()}>{filterContent}</DialogContent>
      </Dialog>
    ),
    [dialogContent, filterContent, isDialogOpen]
  )

  return (
    <div className={wrapper({class: className})}>
      <div className={grid({class: gridStyles()})}>
        <div className={filterButtonWrapper()}>
          {renderPopover}
          {renderDialog}
          <div className={filterCountWrapper()}>
            {filterCount > 0 ? (
              <Button onClick={resetFilters} className={base()}>
                Reset filters ({filterCount})
              </Button>
            ) : (
              <span className="text-text-default">No filters applied</span>
            )}
          </div>
        </div>
        <div className={searchInput()}>
          <HgSearchInput
            placeholder="Search"
            onChange={setSearchQuery}
            value={searchQuery}
          />
        </div>
        {Boolean(filterTags.length) && <ExpandableTagGroup tags={filterTags} />}
      </div>
    </div>
  )
}
