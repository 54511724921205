const pressureFrag = /* glsl */ `
precision mediump float;
uniform sampler2D u_pressure;    // Texture storing the pressure at each point in the fluid
uniform sampler2D u_velocity;    // Texture storing the velocity of each point in the fluid
uniform vec2 u_px;               // Size of a pixel in texture coordinates
uniform float u_dt;              // Time step, representing a small change in time
varying vec2 vUv;                // Texture coordinates, varying for each pixel

void main() {
    float step = 1.0;

    // Retrieve the pressure values from the surrounding points
    float p0 = texture2D(u_pressure, vUv + vec2(u_px.x * step, 0)).r; // Right
    float p1 = texture2D(u_pressure, vUv - vec2(u_px.x * step, 0)).r; // Left
    float p2 = texture2D(u_pressure, vUv + vec2(0, u_px.y * step)).r; // Up
    float p3 = texture2D(u_pressure, vUv - vec2(0, u_px.y * step)).r; // Down

    // Retrieve the current velocity at this point
    vec2 v = texture2D(u_velocity, vUv).xy;

    // Calculate the gradient of the pressure (change in pressure across space)
    vec2 gradP = vec2(p0 - p1, p2 - p3) * 0.5;

    // Adjust the velocity by subtracting the pressure gradient, scaled by the time step
    // This step is crucial for ensuring that the fluid's velocity responds correctly to changes in pressure
    v = v - gradP * u_dt;

    // Set the fragment color to the new velocity value, with alpha set to 1.0
    gl_FragColor = vec4(v, 0.0, 1.0);
}
`
export default pressureFrag
