import {type FilterDisplayConfig} from '..'

export function formatFilterLabel(
  key: string,
  value: string,
  label: string,
  config: FilterDisplayConfig
): string {
  const filterConfig = config[key]
  if (!filterConfig) return String(value)

  const formattedValue = formatFilterValue(value, filterConfig)
  return filterConfig.showLabel ? `${label}: ${formattedValue}` : formattedValue
}

function formatFilterValue(
  value: string,
  config: FilterDisplayConfig[string]
): string {
  const {mode, customValues, prefix = '', suffix = ''} = config

  switch (mode) {
    case 'boolean':
      return customValues?.[String(value)] || String(value)
    case 'range':
      if (typeof value === 'object' && value !== null) {
        const {min, max} = value as {min?: string; max?: string}
        if (min && max) return `${prefix}${min}${suffix} - ${prefix}${max}${suffix}`
        if (min) return `>= ${prefix}${min}${suffix}`
        if (max) return `<= ${prefix}${max}${suffix}`
      }
      return String(value)
    case 'custom':
      return customValues?.[String(value)] || String(value)
    default:
      return `${prefix}${value}${suffix}`
  }
}
