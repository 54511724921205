import React from 'react'
import {type ExtractStrict} from 'type-zoo'

export type TextTagProps = {
  tag?: ExtractStrict<
    keyof React.ReactHTML,
    'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  >
} & React.HTMLAttributes<HTMLHeadingElement | HTMLParagraphElement>

export default function TextTag({
  tag = 'p',
  children,
  ...props
}: React.PropsWithChildren<TextTagProps>) {
  return React.createElement(tag, props, children)
}
