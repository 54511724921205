import NextLink, {type LinkProps as NextLinkProps} from 'next/link'
import {forwardRef, type PropsWithChildren} from 'react'
import {type OmitStrict} from 'type-zoo'

export type LinkWrapperProps = NextLinkProps &
  OmitStrict<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'color'> & {
    newTab?: boolean
  }

const LinkWrapper = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<LinkWrapperProps>
>(({children, href, newTab, rel, target, className, prefetch, ...rest}, ref) => {
  return (
    <NextLink
      ref={ref}
      {...rest}
      href={href}
      prefetch={prefetch}
      className={className}
      target={newTab ? '_blank' : target}
      rel={newTab ? `noopener noreferrer${rel ? ` ${rel}` : ''}` : rel}
    >
      {children}
    </NextLink>
  )
})
LinkWrapper.displayName = 'LinkWrapper'

export default LinkWrapper
