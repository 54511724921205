const mouseVert = /* glsl */ `
precision mediump float;

uniform vec2 u_px;
uniform vec2 u_mouseCoords;
uniform float u_cursorSize;
varying vec2 vUv;

void main() {
    vec2 scale = vec2(u_cursorSize);
    float cursorSizeX = u_cursorSize * u_px.x;
    float cursorSizeY = u_cursorSize * u_px.y;

    float centerX = float(min(max(u_mouseCoords.x, -1.0 + cursorSizeX), 1.0 - cursorSizeX));
    float centerY = float(min(max(u_mouseCoords.y, -1.0 + cursorSizeY), 1.0 - cursorSizeY));

    vec2 center = vec2(centerX, centerY);

    vec2 pos = position.xy * scale * 2.0 * u_px + center;
    vUv = uv;
    gl_Position = vec4(pos, 0.0, 1.0);
}
`

export default mouseVert
