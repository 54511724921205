'use client'

import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import {cn} from '~/design-system/utils'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons'

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({className, ...props}, ref) => (
  <AccordionPrimitive.Item ref={ref} className={className} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({className, children, ...props}, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn('group flex flex-1 items-center justify-between', className)}
      {...props}
    >
      {children}
      <FontAwesomeIcon
        icon={faChevronDown}
        className="shrink-0 transition-transform duration-200 group-data-[state=open]:rotate-180"
      />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({className, children, ...props}, ref) => {
  const innerRef = React.useRef<HTMLDivElement>(null)

  React.useImperativeHandle(ref, () => innerRef.current as HTMLDivElement)

  React.useEffect(() => {
    const ref = innerRef.current

    if (!ref) return
    handleInert()

    function handleInert() {
      if (!ref) return
      // Despite being a valid, accepted HTML attribute, React types does not recognize `inert`
      // Moreover, despite HTML recognizing this as a boolean attribute, React won't accept it unless it's a string
      // Support has been added to react-dom@experimental and Next canary, but neither are live yet.
      const state = ref.getAttribute('data-state')
      if (state === 'closed') {
        ref.setAttribute('inert', '')
      } else {
        ref.removeAttribute('inert')
      }
    }

    ref.addEventListener('transitionstart', handleInert)
    return () => {
      ref.removeEventListener('transitionstart', handleInert)
    }
  }, [])

  return (
    <AccordionPrimitive.Content ref={innerRef} className={className} {...props}>
      {children}
    </AccordionPrimitive.Content>
  )
})
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export {Accordion, AccordionItem, AccordionTrigger, AccordionContent}
