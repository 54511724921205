/* eslint-disable react/no-unknown-property */
import * as THREE from 'three'
import {useEffect, useMemo, useRef} from 'react'
import useMouse from '~/design-system/hg/components/StableFluidBackground/utils/useMouse'
import ExternalForce from '~/design-system/hg/components/StableFluidBackground/components/ExternalForce'
import Advection from '~/design-system/hg/components/StableFluidBackground/components/Advection'
import faceVert from '../shaders/face.vert'
import colorFrag from '../shaders/color.frag'
import {
  type StableFluidBackgroundSettings,
  type FrameBufferObject,
} from '~/design-system/hg/components/StableFluidBackground/types'
import Divergence from '~/design-system/hg/components/StableFluidBackground/components/Divergence'
import Poisson from '~/design-system/hg/components/StableFluidBackground/components/Poisson'
import Pressure from '~/design-system/hg/components/StableFluidBackground/components/Pressure'
import {useFrame, useThree} from '@react-three/fiber'
import {MeshPortalMaterial, useTexture} from '@react-three/drei'

type SimulationProps = {
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>
  gradientMap: string
  backgroundColor: string
} & Required<StableFluidBackgroundSettings>

// Shaders cannot accept hex values, so we need to convert them to a vector3
function hexToVector3(hex: string) {
  // Ensure the hex code is formatted properly
  hex = hex.replace(/^#/, '')

  // Parse the red, green, and blue components
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // Normalize the values to be between 0 and 1
  return new THREE.Vector3(r / 255, g / 255, b / 255)
}

export default function Simulation({
  canvasRef,
  gradientMap,
  backgroundColor,
  ...settings
}: SimulationProps) {
  const {coords, diff, clickPosition} = useMouse(canvasRef.current)
  const pressureRef = useRef<THREE.RawShaderMaterial | null>(null)
  const map = useTexture(gradientMap)
  const gl = useThree(state => state.gl)

  const {cellScale, fboSize} = useMemo(() => {
    if (!canvasRef.current) {
      return {cellScale: new THREE.Vector2(), fboSize: new THREE.Vector2()}
    }
    const width = Math.round(canvasRef.current.width * settings.resolution)
    const height = Math.round(canvasRef.current.height * settings.resolution)

    // (length of 1 cell) / (overall length)
    const pxX = 1.0 / Math.abs(width)
    const pxY = 1.0 / Math.abs(height)

    return {
      cellScale: new THREE.Vector2(pxX, pxY),
      fboSize: new THREE.Vector2(width, height),
    }
  }, [canvasRef, settings])

  const fbos = useMemo<FrameBufferObject>(() => {
    const type = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
      ? THREE.HalfFloatType
      : THREE.FloatType
    const initial: FrameBufferObject = {
      vel_0: null,
      vel_1: null,

      // for calc pressure
      div: null,

      // for calc poisson equation
      pressure_0: null,
      pressure_1: null,
    }
    for (const key in initial) {
      initial[key as keyof typeof initial] = new THREE.WebGLRenderTarget(
        fboSize.x,
        fboSize.y,
        {type}
      )
    }

    return initial
  }, [fboSize.x, fboSize.y])

  useFrame(({gl, scene, camera}) => {
    gl.setRenderTarget(null)
    gl.render(scene, camera)
  })

  useEffect(() => {
    return () => {
      gl.clear()
    }
  }, [gl])

  return (
    <>
      {/* Output */}
      <mesh>
        <planeGeometry args={[2.0, 2.0]} />
        <shaderMaterial
          vertexShader={faceVert}
          fragmentShader={colorFrag}
          uniforms={{
            u_velocity: {
              value: fbos.vel_0?.texture,
            },
            u_boundarySpace: {
              value: new THREE.Vector2(),
            },
            u_gradientMap: {
              value: map,
            },
            u_backgroundColor: {
              value: hexToVector3(backgroundColor),
            },
          }}
        />
      </mesh>
      {/* Shader Passes */}
      <MeshPortalMaterial>
        <Advection cellScale={cellScale} fboSize={fboSize} fbos={fbos} />
      </MeshPortalMaterial>
      <MeshPortalMaterial>
        <ExternalForce
          cellScale={cellScale}
          diff={diff}
          mouseCoords={coords}
          fbos={fbos}
          mouseForce={settings.mouseForce}
          cursorSize={settings.cursorSize}
          clickPosition={clickPosition}
        />
      </MeshPortalMaterial>
      <MeshPortalMaterial>
        <Divergence cellScale={cellScale} fbos={fbos} />
      </MeshPortalMaterial>
      <MeshPortalMaterial>
        <Poisson
          cellScale={cellScale}
          fbos={fbos}
          pressureRef={pressureRef}
          poissonIterations={settings.poissonIterations}
        />
      </MeshPortalMaterial>
      <MeshPortalMaterial>
        <Pressure cellScale={cellScale} fbos={fbos} pressureRef={pressureRef} />
      </MeshPortalMaterial>
    </>
  )
}
