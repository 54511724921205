const poissonFrag = /* glsl */ `
precision mediump float;
uniform sampler2D u_pressure;    // Texture storing the pressure at each point in the fluid
uniform sampler2D u_divergence;  // Texture storing the divergence at each point
uniform vec2 u_px;               // Size of a pixel in texture coordinates
varying vec2 vUv;                // Texture coordinates, varying for each pixel

void main() {
    // Retrieve the pressure from the neighboring points in the texture
    float p0 = texture2D(u_pressure, vUv + vec2(u_px.x * 2.0, 0)).r; // Right
    float p1 = texture2D(u_pressure, vUv - vec2(u_px.x * 2.0, 0)).r; // Left
    float p2 = texture2D(u_pressure, vUv + vec2(0, u_px.y * 2.0)).r; // Up
    float p3 = texture2D(u_pressure, vUv - vec2(0, u_px.y * 2.0)).r; // Down

    // Retrieve the divergence at the current point
    float div = texture2D(u_divergence, vUv).r;

    // Calculate the new pressure value based on the neighboring pressures and the divergence
    // This is the core of the Poisson equation
    float newP = (p0 + p1 + p2 + p3) / 4.0 - div;

    // Set the fragment color to the new pressure value
    gl_FragColor = vec4(newP);
}
`

export default poissonFrag
