import {TabsList} from '~/design-system/foundations/Tabs'
import HgFilterTab, {type HgFilterTabProps} from '../HgFilterTab'

type HgFilterMenuProps = {
  tabs: HgFilterTabProps[]
}

export default function HgFilterMenu({tabs}: HgFilterMenuProps) {
  return (
    <div className="flex flex-col">
      <TabsList className="flex w-full flex-1 flex-col gap-s1 overflow-y-auto p-s3">
        {tabs.map((tab, index) => (
          <HgFilterTab {...tab} key={`${tab.tabValue}-${index}`} />
        ))}
      </TabsList>
    </div>
  )
}
