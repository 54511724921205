'use client'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {tv} from 'tailwind-variants'
import {type ExtractStrict, type OmitStrict} from 'type-zoo'
import {
  HgNavigationExternalLink,
  type HgNavigationExternalLinkProps,
} from './HgNavigationExternalLink'
import {HgNavigationLink, type HgNavigationLinkProps} from './HgNavigationLink'

const footerVariants = tv({
  slots: {
    wrapper:
      'grid w-full grid-cols-1 items-center gap-y-s3 border-t border-border-frosted pt-24 md:gap-x-s6',
    columnList:
      'col-span-1 grid w-full max-w-[304px] items-center gap-y-s3 md:grid-cols-2 md:gap-x-s6',
    item: 'col-span-full',
  },
  variants: {
    layout: {
      row: {
        wrapper: 'md:flex md:flex-row md:flex-wrap ',
      },
      twoColumn: {
        wrapper:
          'md:grid-cols-[repeat(auto-fit,minmax(304px,1fr))] md:justify-between md:justify-items-start',
        item: 'col-span-1',
      },
      fourColumn: {
        wrapper:
          'md:grid-cols-[repeat(auto-fit,minmax(304px,1fr))] md:justify-between',
        item: 'col-span-1',
      },
    },
    columnLinkPosition: {
      1: {item: 'md:col-start-1'},
      2: {item: 'md:col-start-2'},
      3: {item: 'md:col-start-1'},
      4: {item: 'md:col-start-2'},
    },
  },
})

export type FooterLayout = keyof typeof footerVariants.variants.layout
export type FooterLinkPosition =
  keyof typeof footerVariants.variants.columnLinkPosition

export type HgNavigationFooterLink = (
  | (OmitStrict<HgNavigationLinkProps, 'description'> & {isExternal: false})
  | (HgNavigationExternalLinkProps & {
      isExternal: true
    })
) & {linkPosition: FooterLinkPosition}

export type HgNavigationFooterProps = {
  footerLinks: readonly [
    HgNavigationFooterLink,
    HgNavigationFooterLink?,
    HgNavigationFooterLink?,
    HgNavigationFooterLink?,
  ]
  layout?: FooterLayout
  closeNav?: () => void
} & NavigationMenu.NavigationMenuSubProps

const HgNavigationFooterItem = ({
  footerLink,
  className,
  closeNav,
}: {
  footerLink: HgNavigationFooterLink
  className?: string
  closeNav?: () => void
}) => {
  const {isExternal, linkPosition, ...linkProps} = footerLink

  return (
    <NavigationMenu.Item
      className={className}
      value={`subMenu-footer-${linkPosition}`}
    >
      {isExternal ? (
        <HgNavigationExternalLink closeNav={closeNav} {...linkProps} />
      ) : (
        <HgNavigationLink closeNav={closeNav} {...linkProps} />
      )}
    </NavigationMenu.Item>
  )
}

const MultiColumnFooter = ({
  footerLinks,
  layout,
  closeNav,
}: {
  footerLinks: HgNavigationFooterProps['footerLinks']
  layout: ExtractStrict<FooterLayout, 'twoColumn' | 'fourColumn'>
  closeNav?: () => void
}) => {
  const {wrapper, item, columnList} = footerVariants({layout})
  // NB: it is necessary to make sure that each link's linkPosition has the correct
  // "columnLinkPosition" class applied in order for it to appear in the correct column
  const [firstLink, secondLink, thirdLink, fourthLink] = Object.keys(
    footerVariants.variants.columnLinkPosition
  ).map(linkPosition =>
    footerLinks.find(footerLink => footerLink?.linkPosition === +linkPosition)
  )

  return (
    <div className={wrapper()}>
      <NavigationMenu.List className={columnList()}>
        {[firstLink, secondLink].map((footerLink, index) => {
          if (!footerLink) return null
          return (
            <HgNavigationFooterItem
              key={index}
              footerLink={footerLink}
              className={item({columnLinkPosition: footerLink.linkPosition})}
              closeNav={closeNav}
            />
          )
        })}
      </NavigationMenu.List>
      <NavigationMenu.List className={columnList()}>
        {[thirdLink, fourthLink].map((footerLink, index) => {
          if (!footerLink) return null
          return (
            <HgNavigationFooterItem
              key={index}
              footerLink={footerLink}
              className={item({columnLinkPosition: footerLink.linkPosition})}
              closeNav={closeNav}
            />
          )
        })}
      </NavigationMenu.List>
    </div>
  )
}

export const HgNavigationFooter = ({
  footerLinks,
  layout = 'row',
  closeNav,
  ...subMenuProps
}: HgNavigationFooterProps) => {
  const {wrapper, item} = footerVariants({layout})

  return (
    <NavigationMenu.Sub {...subMenuProps} className="w-auto max-w-[664px]">
      {layout === 'twoColumn' || layout === 'fourColumn' ? (
        <MultiColumnFooter footerLinks={footerLinks} layout={layout} />
      ) : (
        <NavigationMenu.List className={wrapper()}>
          {footerLinks.slice(0, 4).map((footerLink, index) => {
            if (!footerLink) return null
            return (
              <HgNavigationFooterItem
                key={index}
                footerLink={footerLink}
                className={item({layout})}
                closeNav={closeNav}
              />
            )
          })}
        </NavigationMenu.List>
      )}
    </NavigationMenu.Sub>
  )
}
