import {type ReactNode} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {TextTag, type TextTagProps} from '~/design-system/foundations'

const statisticWrapper = tv({
  base: 'flex flex-col space-y-s2',
  variants: {
    topSpacing: {
      s0: 'pt-s0',
      s4: 'pt-s4',
    },
  },
  defaultVariants: {
    topSpacing: 's0',
  },
})

type HgStatisticVariants = VariantProps<typeof statisticWrapper>

export type HgStatisticProps = {
  statistic: ReactNode
  blurb: ReactNode
  statisticTag?: TextTagProps['tag']
} & HgStatisticVariants

const HgStatistic = ({
  statistic,
  blurb,
  statisticTag,
  topSpacing,
}: HgStatisticProps) => {
  return (
    <div className={statisticWrapper({topSpacing})}>
      <TextTag className="text-text-default arcadia-subheading-2" tag={statisticTag}>
        {statistic}
      </TextTag>
      <p className="text-text-subdued arcadia-body-2">{blurb}</p>
    </div>
  )
}

export default HgStatistic
