/* eslint-disable react/no-unknown-property */
import * as THREE from 'three'
import mouseVert from '../shaders/mouse.vert'
import externalForceFrag from '../shaders/externalForce.frag'
import {type ShaderMaterialProps, extend, useFrame} from '@react-three/fiber'
import {useEffect, useRef} from 'react'
import {type ShaderPassProps} from '~/design-system/hg/components/StableFluidBackground/types'
import {shaderMaterial} from '@react-three/drei'

type ExternalForceProps = {
  mouseCoords: THREE.Vector2
  diff: THREE.Vector2
  mouseForce: number
  cursorSize: number
  clickPosition: THREE.Vector2
} & ShaderPassProps

type ExternalForceMaterialUniforms = {
  u_px: THREE.Vector2
  u_diff: THREE.Vector2
  u_mouseCoords: THREE.Vector2
  u_mouseForce: number
  u_cursorSize: number
  u_clickPosition: THREE.Vector2
}

const ExternalForceMaterial = shaderMaterial(
  {
    u_px: new THREE.Vector2(),
    u_diff: new THREE.Vector2(),
    u_mouseCoords: new THREE.Vector2(),
    u_mouseForce: 20,
    u_cursorSize: 15,
    u_clickPosition: new THREE.Vector2(-1, -1),
  },
  mouseVert,
  externalForceFrag
)

extend({ExternalForceMaterial})

const FORCE_SCALE_FACTOR = 10

export default function ExternalForce({
  mouseCoords,
  diff,
  cellScale,
  fbos,
  cursorSize,
  mouseForce,
  clickPosition,
}: ExternalForceProps) {
  const materialRef = useRef<THREE.RawShaderMaterial | null>(null)
  const shouldScaleForce = useRef(true)

  useFrame(({gl, scene, camera}) => {
    if (!materialRef.current) return
    const uniforms = materialRef.current.uniforms

    uniforms.u_mouseForce.value = mouseForce

    // make the force more noticeable when the user first enters the canvas
    if (shouldScaleForce.current) {
      uniforms.u_mouseForce.value = mouseForce * FORCE_SCALE_FACTOR
    }

    uniforms.u_diff.value = diff
    uniforms.u_cursorSize.value = cursorSize
    uniforms.u_mouseCoords.value = mouseCoords
    uniforms.u_clickPosition.value = clickPosition

    gl.setRenderTarget(fbos.vel_1)
    gl.render(scene, camera)
    gl.setRenderTarget(null)
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      shouldScaleForce.current = false
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      <mesh>
        <planeGeometry args={[1.0, 1.0]} />
        <externalForceMaterial
          ref={materialRef}
          blending={THREE.AdditiveBlending}
          u_mouseForce={mouseForce}
          u_diff={diff}
          u_mouseCoords={mouseCoords}
          u_cursorSize={cursorSize}
          u_px={cellScale}
          u_clickPosition={clickPosition}
        />
      </mesh>
    </>
  )
}

/*
React Three Fiber (R3F) utilizes the full catalog of ThreeJS classes. However, it lets you utilize the ThreeJS classes declaratively by denoting them with camelCase to differentiate them from custom user made components. This abstraction strips away a lot of the boiler plate with creating a scene in ThreeJS. Check out https://docs.pmnd.rs/react-three-fiber/getting-started/your-first-scene#adding-a-mesh-component for a good example of this abstraction.

R3F also lets you define your own custom ThreeJS components by using its `extend` function which can be seen at the top of this file. This effectively adds it to the catalog of ThreeJS components at your disposal (ExternalForceMaterial becomes <externalForceMaterial /> declaratively). Unfortunately, TypeScript can't keep up with this, so we must add a type definition for this new custom camelCase component.

Additionally, the `shaderMaterial()` function from @react-three/drei lets me create a reactive shader material declaratively which makes it easier to adjust the parameters using controls.

https://docs.pmnd.rs/react-three-fiber/tutorials/typescript#extend-usage
*/
declare module '@react-three/fiber' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ThreeElements {
    externalForceMaterial: ShaderMaterialProps & ExternalForceMaterialUniforms
  }
}
