'use client'
import {Carousel, CarouselContent, CarouselItem} from '~/design-system/foundations'
import {useCarousel} from '~/design-system/foundations/Carousel'
import grid, {gridItem} from '~/design-system/foundations/Grid'
import {HgUiControls} from '~/design-system/hg/components'
import HgTestimonial, {
  type HgTestimonialDefaultProps,
} from '~/design-system/hg/components/HgTestimonial'

export type HgTestimonialSliderTestimonial = Omit<
  HgTestimonialDefaultProps,
  'variant' | 'topSpacing' | 'alignment'
>

export type HgTestimonialSliderProps = {
  testimonials: HgTestimonialSliderTestimonial[]
}

function HgTestimonialInner(props: {
  testimonials: HgTestimonialSliderTestimonial[]
}) {
  const {testimonials} = props

  const {scrollNext, scrollPrev, currentIndex} = useCarousel()

  return (
    <>
      <CarouselContent>
        {testimonials.map((testimonial, index) => (
          <CarouselItem key={index}>
            <HgTestimonial
              {...testimonial}
              topSpacing="s9"
              variant="default"
              alignment="center"
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      {testimonials.length > 1 && (
        <div className="mt-s9 flex justify-center">
          <HgUiControls
            onBack={scrollPrev}
            onForward={scrollNext}
            currentIndex={currentIndex}
            numPages={testimonials.length}
          />
        </div>
      )}
    </>
  )
}

export default function HgTestimonialSlider(props: HgTestimonialSliderProps) {
  const {testimonials} = props
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <Carousel className={gridItem({size: 'heading'})} opts={{loop: true}}>
          <HgTestimonialInner testimonials={testimonials} />
        </Carousel>
      </div>
    </div>
  )
}
