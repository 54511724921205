import {type VariantProps, tv} from 'tailwind-variants'
import {grid, gridItem} from '~/design-system/foundations'
import HgCard, {
  type HgHorizontalCardProps,
  type HgVerticalCardProps,
} from '~/design-system/hg/components/HgCard'
import {cn, unreachableCase} from '~/design-system/utils'

export type HgWayfindingCardListingProps = {
  type: 'cardListing'
  cards: [HgVerticalCardProps, HgVerticalCardProps, HgVerticalCardProps?]
}

export type HgWayfindingCardGridProps = {
  type: 'cardGrid'
  cards: [HgHorizontalCardProps, HgVerticalCardProps, HgVerticalCardProps]
}

type HgWayfindingTypeDependentProps =
  | HgWayfindingCardListingProps
  | HgWayfindingCardGridProps

export type HgWayfindingProps = HgWayfindingTypeDependentProps &
  VariantProps<typeof hgWayfindingVariants>

const hgWayfindingVariants = tv({
  variants: {
    topSpacing: {
      s4: 'mt-s4',
      s9: 'mt-s9',
    },
  },
})

function renderCards(props: HgWayfindingTypeDependentProps) {
  switch (props.type) {
    case 'cardListing':
      return (
        <div
          className={gridItem({
            size: 'max',
            className: 'card-type-container flex flex-col gap-s4 sm:flex-row',
          })}
        >
          {props.cards.map(
            (card, index) =>
              card && <HgCard key={index} {...card} className="h-full" />
          )}
        </div>
      )
    case 'cardGrid':
      return (
        <div
          className={gridItem({
            size: 'max',
            className:
              'card-type-container grid gap-s4 sm:grid-cols-2 sm:grid-rows-[auto_auto]',
          })}
        >
          <HgCard {...props.cards[0]} className="h-full sm:col-span-2" />
          <HgCard {...props.cards[1]} className="h-full" />
          <HgCard {...props.cards[2]} className="h-full" />
        </div>
      )
    default:
      return unreachableCase(props)
  }
}

export default function HgWayfinding({
  topSpacing = 's9',
  ...typeDependentProps
}: HgWayfindingProps) {
  return (
    <div
      className={cn(
        hgWayfindingVariants({topSpacing}),
        grid({className: 'mx-auto'})
      )}
    >
      {renderCards(typeDependentProps)}
    </div>
  )
}
