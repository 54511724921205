/* eslint-disable react/no-unknown-property */
import {
  TIME_SCALE,
  type ShaderPassProps,
} from '~/design-system/hg/components/StableFluidBackground/types'
import pressureFrag from '../shaders/pressure.frag'
import faceVert from '../shaders/face.vert'
import {useFrame} from '@react-three/fiber'
import type * as THREE from 'three'

export default function Pressure({
  cellScale,
  fbos,
  pressureRef,
}: ShaderPassProps & {
  pressureRef: React.MutableRefObject<THREE.RawShaderMaterial | null>
}) {
  useFrame(({gl, scene, camera}) => {
    gl.setRenderTarget(fbos.vel_0)
    gl.render(scene, camera)
    gl.setRenderTarget(null)
  })

  return (
    <mesh>
      <shaderMaterial
        ref={pressureRef}
        vertexShader={faceVert}
        fragmentShader={pressureFrag}
        uniforms={{
          u_boundarySpace: {
            value: cellScale,
          },
          u_pressure: {
            value: fbos.pressure_0?.texture,
          },
          u_velocity: {
            value: fbos.vel_1?.texture,
          },
          u_px: {
            value: cellScale,
          },
          u_dt: {
            value: TIME_SCALE,
          },
        }}
      />
      <planeGeometry args={[2.0, 2.0]} />
    </mesh>
  )
}
