import {Slot} from '@radix-ui/react-slot'
import {themeToClassName, type Theme} from '~/design-system/hg/tokens/colors'

export type HgThemeSelectorProps = {
  theme?: Theme
  children: React.ReactNode
}

export default function HgThemeSelector({theme, children}: HgThemeSelectorProps) {
  return <Slot className={theme && themeToClassName[theme]}>{children}</Slot>
}
