import {Slot} from '@radix-ui/react-slot'
import {tv} from 'tailwind-variants'
import grid, {gridItem} from '~/design-system/foundations/Grid'
import {
  HgFeaturedText,
  type FeaturedTextBaseProps,
} from '~/design-system/hg/components'
import ProductSummaryGraphic, {
  type ProductGraphicProps,
} from './ProductSummaryGraphic'

export type HgProductSummaryProps = FeaturedTextBaseProps &
  ProductGraphicProps & {
    body?: string | React.ReactNode
  }

const hgProductSummaryVariants = tv({
  slots: {
    asset: 'h-full w-full',
    assetContainer: 'sm:col-span-4 xl:col-span-3',
    contentContainer:
      'justify-center sm:col-span-2 sm:grid sm:grid-cols-6 xl:col-span-3',
    featuredText:
      'col-span-full items-center text-center sm:items-start sm:justify-center sm:text-left xl:col-span-5',
  },
})

export default function HgProductSummary({
  title,
  body,
  buttonProps,
  ...productGraphicProps
}: HgProductSummaryProps) {
  const {assetContainer, asset, contentContainer, featuredText} =
    hgProductSummaryVariants()
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <div
          className={gridItem({
            size: 'max',
            class:
              'mt-s9 grid justify-center gap-s9 text-sm sm:grid-cols-6 sm:gap-s4',
          })}
        >
          <div className={contentContainer()}>
            <HgFeaturedText
              title={title}
              body={body}
              buttonProps={buttonProps}
              className={featuredText()}
            />
          </div>
          <div className={assetContainer()}>
            <Slot className={asset()}>
              <ProductSummaryGraphic {...productGraphicProps} />
            </Slot>
          </div>
        </div>
      </div>
    </div>
  )
}
