import {type OmitStrict} from 'type-zoo'
import grid, {gridItem} from '~/design-system/foundations/Grid'
import {HgListItem, type HgListItemProps} from '~/design-system/hg/components'

type HgFeaturedUnorderedListItemProps = OmitStrict<HgListItemProps, 'topSpacing'>

export type HgFeaturedUnorderedListProps = {
  items: [
    HgFeaturedUnorderedListItemProps,
    HgFeaturedUnorderedListItemProps,
    HgFeaturedUnorderedListItemProps,
    ...HgFeaturedUnorderedListItemProps[],
  ]
}

export default function HgFeaturedUnorderedList(
  props: HgFeaturedUnorderedListProps
) {
  const {items} = props
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <ul
          className={gridItem({
            size: 'max',
            className: 'border-b border-border-subdued pb-s6',
          })}
        >
          {items.map((item, index) => (
            <HgListItem
              key={index}
              {...item}
              topSpacing={index === 0 ? 's9' : 's6'}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}
