import {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {tv, type VariantProps} from 'tailwind-variants'
import {LinkWrapper, type LinkWrapperProps} from '~/design-system/foundations'
import {TableCell, TableHead, TableRow} from '~/design-system/foundations/Table'
import {
  HgIndexTableBodyCell,
  type HgIndexTableBodyCellContentProps,
} from './HgIndexTableBodyCell'
import {
  HgIndexTableTitleCell,
  type HgIndexTableTitleCellContentProps,
} from './HgIndexTableTitleCell'

export type HgIndexTableRowCellProps = [
  HgIndexTableBodyCellContentProps,
  HgIndexTableBodyCellContentProps?,
  HgIndexTableBodyCellContentProps?,
  HgIndexTableBodyCellContentProps?,
  HgIndexTableBodyCellContentProps?,
  HgIndexTableBodyCellContentProps?,
]

export type IndexTableVariantProps = VariantProps<typeof indexTableVariants>

export type HgIndexTableRowProps = {linkProps: LinkWrapperProps} & {
  id: string
  row: HgIndexTableRowCellProps
} & IndexTableVariantProps

export type HgIndexTableTitleRowCellProps = [
  HgIndexTableTitleCellContentProps,
  HgIndexTableTitleCellContentProps?,
  HgIndexTableTitleCellContentProps?,
  HgIndexTableTitleCellContentProps?,
  HgIndexTableTitleCellContentProps?,
  HgIndexTableTitleCellContentProps?,
]

export type HgIndexTableTitleRowProps = {
  row: HgIndexTableTitleRowCellProps
} & IndexTableVariantProps

const indexTableVariants = tv({
  slots: {
    cellContent: 'flex justify-start p-0 py-s3',
    tableRow:
      'relative grid grid-flow-col grid-cols-[repeat(1,minmax(0,1fr)),_minmax(0,auto)] border-y border-transparent-transparent transition-none before:absolute before:-inset-y-1 before:-left-12 before:-z-10 before:w-12 before:rounded-s-xl before:border-y before:border-s before:border-transparent-transparent after:absolute after:-inset-y-1 after:-right-12 after:-z-10 after:w-12 after:rounded-e-xl after:border-y after:border-e after:border-transparent-transparent [&_td:not(:last-child)]:pe-s4 [&_td:nth-child(n+2):not(:last-child)]:hidden md:[&_td:nth-child(n+2):not(:last-child)]:flex md:[&_td:nth-child(n+4):not(:last-child)]:hidden lg:[&_td:nth-child(n+4):not(:last-child)]:flex [&_td]:border-t [&_td]:border-border-subdued [&_th:not(:last-child)]:pe-s4 [&_th:nth-child(n+2):not(:last-child)]:hidden md:[&_th:nth-child(n+2):not(:last-child)]:flex md:[&_th:nth-child(n+4):not(:last-child)]:hidden lg:[&_th:nth-child(n+4):not(:last-child)]:flex [&_th]:border-t [&_th]:border-border-subdued',
  },
  variants: {
    hasTopMargin: {
      true: {
        tableRow: 'mt-s3',
      },
    },
    isClickable: {
      true: {
        tableRow:
          'hover:border-border-subdued hover:bg-background-secondary hover:before:border-border-subdued hover:before:bg-background-secondary hover:after:border-border-subdued hover:after:bg-background-secondary active:outline-none has-[a:active]:border-border-subdued has-[a:active]:bg-transparent-transparent has-[a:active]:before:border-0 has-[a:active]:before:bg-transparent-transparent has-[a:active]:after:border-0 has-[a:active]:after:bg-transparent-transparent focus-within:has-[a#table-row-link:focus-visible]:border-border-focus focus-within:has-[a#table-row-link:focus-visible]:bg-background-secondary focus-within:has-[a#table-row-link:focus-visible]:before:border-border-focus focus-within:has-[a#table-row-link:focus-visible]:before:bg-background-secondary focus-within:has-[a#table-row-link:focus-visible]:after:border-border-focus focus-within:has-[a#table-row-link:focus-visible]:after:bg-background-secondary focus-within:has-[a#table-row-link:focus-visible]:hover:border-border-focus focus-within:has-[a#table-row-link:focus-visible]:hover:has-[a:active]:border-border-subdued focus-within:has-[a#table-row-link:focus-visible]:hover:before:border-border-focus focus-within:has-[a#table-row-link:focus-visible]:hover:after:border-border-focus [&:focus-within:has(a#table-row-link:focus-visible)+tr_td]:border-transparent-transparent [&:focus-within:has(a#table-row-link:focus-visible)_>_td]:border-transparent-transparent [&:hover_+_tr_td]:border-transparent-transparent [&:hover_>_td]:border-transparent-transparent',
      },
    },
    lastColumnAlignment: {
      left: {cellContent: ''},
      right: {
        cellContent:
          '[&:nth-last-of-type(2)>*]:justify-end [&:nth-last-of-type(2)_p]:justify-end',
      },
    },
    numColumns: {
      1: {
        tableRow: 'md:grid-cols-[repeat(1,minmax(0,1fr)),_minmax(0,24px)]',
      },
      2: {
        tableRow: 'md:grid-cols-[repeat(2,minmax(0,1fr)),_minmax(0,24px)]',
      },
      3: {
        tableRow: 'md:grid-cols-[repeat(3,minmax(0,1fr)),_minmax(0,24px)]',
      },
      4: {
        tableRow:
          'md:grid-cols-[repeat(3,minmax(0,1fr)),_minmax(0,24px)] lg:grid-cols-[repeat(4,minmax(0,1fr)),_minmax(0,24px)]',
      },
      5: {
        tableRow:
          'md:grid-cols-[repeat(3,minmax(0,1fr)),_minmax(0,24px)] lg:grid-cols-[repeat(5,minmax(0,1fr)),_minmax(0,24px)]',
      },
      6: {
        tableRow:
          'md:grid-cols-[repeat(3,minmax(0,1fr)),_minmax(0,24px)] lg:grid-cols-[repeat(6,minmax(0,1fr)),_minmax(0,24px)]',
      },
    },
    variant: {
      asymmetrical: {
        tableRow:
          'lg:grid-cols-[repeat(3,minmax(0,1fr)),_minmax(0,24px)] xl:grid-cols-[2fr_2fr_1fr_1fr,_minmax(0,24px)] lg:[&_td:nth-child(n+4):not(:last-child)]:hidden xl:[&_td:nth-child(n+4):not(:last-child)]:flex lg:[&_th:nth-child(n+4):not(:last-child)]:hidden xl:[&_th:nth-child(n+4):not(:last-child)]:flex',
      },
      simple: {
        tableRow: '',
      },
    },
  },
})

export const HgIndexTableTitleRow = ({
  row,
  hasTopMargin,
  lastColumnAlignment,
  variant = 'simple',
}: HgIndexTableTitleRowProps) => {
  const {cellContent, tableRow} = indexTableVariants({
    hasTopMargin,
    lastColumnAlignment,
    numColumns: row.length,
    variant,
  })

  return (
    <TableRow className={tableRow()}>
      {row.map(
        (cell, index) =>
          cell && (
            <TableHead scope="col" key={index} className={cellContent()}>
              <HgIndexTableTitleCell {...cell} />
            </TableHead>
          )
      )}
      {/* empty column to account for link column in table body rows */}
      <TableHead />
    </TableRow>
  )
}

export const HgIndexTableRow = ({
  row,
  hasTopMargin,
  lastColumnAlignment,
  linkProps,
  variant = 'simple',
}: HgIndexTableRowProps) => {
  const {cellContent, tableRow} = indexTableVariants({
    hasTopMargin,
    isClickable: true,
    lastColumnAlignment,
    numColumns: row.length,
    variant,
  })

  return (
    <SkeletonTheme baseColor="#ededf3" highlightColor="#fbfcfd">
      <TableRow className={tableRow()}>
        {row.map((cell, index) => (
          <TableCell key={index} className={cellContent()}>
            <HgIndexTableBodyCell {...cell} />
          </TableCell>
        ))}
        <TableCell className={cellContent()}>
          <LinkWrapper
            id="table-row-link"
            className="outline-none after:absolute after:-inset-x-12 after:-inset-y-1"
            {...linkProps}
          >
            <HgIndexTableBodyCell className="justify-end" iconType="chevron-right" />
          </LinkWrapper>
        </TableCell>
      </TableRow>
    </SkeletonTheme>
  )
}
