import React, {type ReactNode} from 'react'
import {tv} from 'tailwind-variants'

export type HgDetailContentProps = {
  variant: 'oneColumn' | 'twoColumn' | 'threeColumn'
  children: ReactNode
}

const hgDetailContentVariants = tv({
  base: 'grid w-full gap-s4',
  variants: {
    numCols: {
      oneColumn: 'grid-cols-1',
      twoColumn: 'grid-cols-2',
      threeColumn: 'grid-cols-3',
    },
  },
})

const HgDetailContent: React.FC<HgDetailContentProps> = ({variant, children}) => {
  return (
    <div className={hgDetailContentVariants({numCols: variant})}>{children}</div>
  )
}

export default HgDetailContent
