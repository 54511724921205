import {tv, type VariantProps} from 'tailwind-variants'
import {Tabs, type TabsProps} from '~/design-system/foundations/'
import {cn} from '~/design-system/utils'
import {CHROMATIC_IGNORE_CLASSNAME} from '~/utils/chromatic'

const hgTabs = tv({
  slots: {
    tabsContainer: 'justify-center',
    tabsListWrapper: 'm-auto flex h-fit w-fit border-border-subdued',
    tabsTrigger:
      'relative py-12 text-text-subdued transition-colors duration-300 ease-out arcadia-ui-1 hover:text-text-emphasized data-[state=active]:text-text-emphasized',
    tabsContentContainer: 'grid',
    tabsContent:
      'col-span-full row-start-1 mt-40 opacity-0 transition-[visibility] data-[state=active]:animate-fadeIn data-[state=inactive]:animate-fadeOut',
    indicator:
      'absolute z-[1] w-full bg-border-emphasized transition-[height,_transform,_width] duration-300 ease-out',
    border: 'absolute bottom-0 bg-border-subdued',
  },
  variants: {
    orientation: {
      horizontal: {
        tabsContainer: 'justify-center',
        tabsListWrapper: 'h-fit max-w-full overflow-x-auto sm:overflow-x-hidden',
        tabsTrigger: 'px-16',
        tabsContent: 'mt-40',
        indicator: 'bottom-0 left-0 h-2',
        border: 'h-2 w-full',
      },
      vertical: {
        tabsContainer: 'align-center',
        tabsTrigger: 'py-16 pl-12 text-left',
        tabsContent: 'ml-40',
        indicator: 'left-0 top-0 w-2',
        border: 'h-full w-2',
      },
    },
    topSpacing: {
      s0: {
        tabsContainer: 'pt-s0',
      },
      s6: {
        tabsContainer: 'pt-s6',
      },
    },
  },
})

export default function HgTabs({
  tabs,
  verticalTabs,
  topSpacing = 's0',
}: TabsProps & VariantProps<typeof hgTabs>) {
  const orientation = verticalTabs ? 'vertical' : 'horizontal'
  const {
    tabsContainer,
    tabsContent,
    tabsContentContainer,
    tabsListWrapper,
    tabsTrigger,
    indicator,
    border,
  } = hgTabs({
    orientation,
    topSpacing,
  })

  return (
    <Tabs
      tabs={tabs}
      classNames={{
        container: tabsContainer(),
        listWrapper: tabsListWrapper(),
        trigger: tabsTrigger(),
        content: cn(tabsContent(), CHROMATIC_IGNORE_CLASSNAME),
        contentContainer: tabsContentContainer(),
        indicator: indicator(),
        border: border(),
      }}
      verticalTabs={verticalTabs}
    />
  )
}
