import {Slot} from '@radix-ui/react-slot'
import {type Theme} from '~/design-system/hg/tokens/colors'

export type HgThemeSwitcherProps = {
  theme: Theme
  applyGlobally?: boolean
  children: React.ReactNode
}

export const THEME_SWITCHER_ID = 'theme-switcher'

export default function HgThemeSwitcher({
  theme,
  children,
  applyGlobally = true,
}: HgThemeSwitcherProps) {
  return (
    <Slot
      data-id={THEME_SWITCHER_ID}
      data-theme={theme}
      data-apply-globally={applyGlobally}
    >
      {children}
    </Slot>
  )
}
