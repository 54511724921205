import {type PropsWithChildren} from 'react'
import {cn} from '~/design-system/utils'

export type HgBackgroundColorProps = PropsWithChildren<{
  backgroundColor?: 'bg-inherit' | 'bg-background-secondary'
  className?: string
}>

export default function HgBackgroundColor({
  backgroundColor = 'bg-inherit',
  className,
  children,
}: HgBackgroundColorProps) {
  return <div className={cn(backgroundColor, className)}>{children}</div>
}
