import React, {type PropsWithChildren} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {gridItem} from '~/design-system/foundations'

const bodyVariants = tv({
  base: 'text-text-default',
  variants: {
    variant: {
      'body-1': 'tiempos-body-1',
      'body-2': 'tiempos-body-2',
      'body-3': 'tiempos-body-3',
    },
  },
})

type BodyTextVariant = VariantProps<typeof bodyVariants>['variant']

export type HgBodyTextProps = PropsWithChildren<{
  variant: BodyTextVariant
  className?: string
}>

export const HgBodyText = ({
  variant = 'body-2',
  className,
  children,
}: HgBodyTextProps) => {
  return <p className={bodyVariants({variant, class: className})}>{children}</p>
}

export const HgArticleWYSIWYGBodyText = (props: HgBodyTextProps) => {
  return (
    <HgBodyText
      {...props}
      className={gridItem({size: 'heading', className: props.className})}
    />
  )
}
