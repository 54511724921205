'use client'

import * as React from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    icon: React.ReactNode
  }
>(({className, children, icon, ...props}, ref) => (
  <SelectPrimitive.Trigger ref={ref} className={className} {...props}>
    {children}
    <SelectPrimitive.Icon asChild>{icon}</SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton> & {
    icon: React.ReactNode
  }
>(({className, icon, ...props}, ref) => (
  <SelectPrimitive.ScrollUpButton ref={ref} className={className} {...props}>
    {icon}
  </SelectPrimitive.ScrollUpButton>
))
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton> & {
    icon: React.ReactNode
  }
>(({className, icon, ...props}, ref) => (
  <SelectPrimitive.ScrollDownButton ref={ref} className={className} {...props}>
    {icon}
  </SelectPrimitive.ScrollDownButton>
))
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    scrollUpButtonIcon: React.ReactNode
    scrollDownButtonIcon: React.ReactNode
  }
>(
  (
    {
      className,
      children,
      position,
      scrollUpButtonIcon,
      scrollDownButtonIcon,
      ...props
    },
    ref
  ) => (
    <SelectPrimitive.Portal className="z-10">
      <SelectPrimitive.Content
        ref={ref}
        className={className}
        position={position}
        {...props}
      >
        <SelectScrollUpButton
          className="flex items-center justify-center"
          icon={scrollUpButtonIcon}
        />
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
        <SelectScrollDownButton
          className="flex items-center justify-center"
          icon={scrollDownButtonIcon}
        />
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  )
)
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({className, ...props}, ref) => (
  <SelectPrimitive.Label ref={ref} className={className} {...props} />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
    indicatorIcon?: React.ReactNode
  }
>(({className, children, indicatorIcon, ...props}, ref) => (
  <SelectPrimitive.Item ref={ref} className={className} {...props}>
    {indicatorIcon && (
      <SelectPrimitive.ItemIndicator asChild>
        {indicatorIcon}
      </SelectPrimitive.ItemIndicator>
    )}

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({className, ...props}, ref) => (
  <SelectPrimitive.Separator ref={ref} className={className} {...props} />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton,
}
