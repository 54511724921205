/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TSLint doesn't know about exhaustive switch cases.
// this is a nice helper to use in a `default` case, where
// the compiler can actually warn us if it will ever be hit, so
// we can catch bugs in cases where, i.e., we add a value to an enum but don't
// account for it somewhere
// source: https://github.com/palantir/tslint/issues/696#issuecomment-468640023
// once ESLint implements this and our other rules, we might want to switch to it:
//   https://github.com/typescript-eslint/typescript-eslint/issues/281

import {notifyBugsnag, type NotifyBugsnagOpts} from './Bugsnag/notify'

/** We want to safely handle (rather than crash) some unreachable cases, for things like backend-generated
    string unions that may be added to in the future. */
export const safeUnreachableCase = <T>(
  v: never,
  retVal: T,
  bugsnagOpts?: Pick<NotifyBugsnagOpts, 'team' | 'metadata'>
): T => {
  // if this code is running, v didn't turn out to be `never` after all, so tell TS that
  const castedV = v as unknown

  // try and create a nice grouping hash from the value, if it is easy to do so
  let groupingHash = 'safeUnreachableCase'

  if (!(typeof castedV === 'object' || typeof castedV === 'function')) {
    try {
      const stringVal = String(castedV)
      groupingHash += ':' + stringVal
    } catch {}
  }

  notifyBugsnag(
    `Reached a (safe) unreachable case: ${v}. Perhaps you need to generate types for the frontend?`,
    {
      team: bugsnagOpts?.team,
      metadata: {
        ...bugsnagOpts?.metadata,
        unreachableCase: v,
      },
      groupingHash,
    }
  )
  console.error(`Reached a (safe) unreachable case: ${v}`)
  return retVal
}
