'use client'

import {Slot} from '@radix-ui/react-slot'
import React, {forwardRef, useState} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {
  Button,
  LinkWrapper,
  type LinkWrapperProps,
} from '~/design-system/foundations'
import {HgIcon} from '~/design-system/hg/components'

const tagVariants = tv({
  slots: {
    wrapper:
      'flex w-fit max-w-full items-center justify-center rounded text-text-default outline-2 -outline-offset-2 outline-border-focus arcadia-ui-2 has-[a:focus-visible]:outline md:arcadia-ui-1',
    textWrapper: 'peer truncate rounded bg-surface-emphasized p-s2 outline-none',
    closeButton:
      'h-full rounded-e bg-surface-emphasized p-s2 text-icon-default outline-2 -outline-offset-2 outline-border-focus hover:bg-surface-emphasized-hover hover:text-icon-subdued focus-visible:rounded focus-visible:outline active:bg-surface-emphasized-active active:text-icon-emphasized',
  },
  variants: {
    isDismissed: {
      true: {wrapper: 'hidden'},
    },
    isDismissible: {
      true: {textWrapper: 'rounded-e-none pe-0'},
    },
    hasLink: {
      true: {
        textWrapper:
          'hover:bg-surface-emphasized-hover focus-visible:bg-surface-emphasized-hover active:bg-surface-emphasized-active',
        closeButton:
          'peer-hover:bg-surface-emphasized-hover peer-focus-visible:bg-surface-emphasized-hover peer-active:bg-surface-emphasized-active',
      },
    },
  },
})

type HgTagVariantProps = VariantProps<typeof tagVariants>

export type HgTagProps = {
  id: string
  body: React.ReactNode
  linkProps?: LinkWrapperProps
  onDismiss?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
} & HgTagVariantProps

export const HgTag = forwardRef<HTMLDivElement, HgTagProps>(
  ({isDismissible, linkProps, onDismiss, body, className}, ref) => {
    const [isDismissed, setIsDismissed] = useState(false)
    const {wrapper, textWrapper, closeButton} = tagVariants({
      isDismissed,
      isDismissible,
      hasLink: !!linkProps?.href,
    })

    return (
      <div className={wrapper({class: className})} ref={ref}>
        <Slot className={textWrapper()}>
          {linkProps ? (
            <LinkWrapper {...linkProps}>{body}</LinkWrapper>
          ) : (
            <span>{body}</span>
          )}
        </Slot>
        {isDismissible && (
          <Button
            className={closeButton()}
            onClick={event => {
              setIsDismissed(true)
              onDismiss?.(event)
            }}
          >
            <HgIcon iconType="xmark" size="small" className="md:hidden" />
            <HgIcon iconType="xmark" size="regular" className="hidden md:block" />
            <span className="sr-only">Dismiss {body} tag</span>
          </Button>
        )}
      </div>
    )
  }
)
HgTag.displayName = 'HgTag'

export default HgTag
