/* eslint-disable no-useless-escape */
/**
 * This file contains refinements with more human friendly error messages.
 * There may be built in zod functions that do these checks but these functions are preferred.
 */

export const refineNonEmpty = [
  (value: string) => value.trim().length > 0,
  'Please complete this field',
] as const

/**
 * This regex is consistent with the backend and postgres
 * https://github.com/MercuryTechnologies/mercury-web-backend/blob/master/src/Model/Email.hs#L38
 */
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

/**
 * The EMAIl_REGEX above is the same regex we use in the backend and PostgresQL.
 * However we've noticed that it allows domains that don't have a TLD which is technically valid, but unrealistic.
 * The regex below is a bit stricter and only enforced in the frontend but hopefully removes the majority of user mis-inputs.
 * The following regex was pulled in from: https://emailregex.com (scroll to the "Javascript" section) and tested in `index.test.ts`
 */
export const STRICTER_EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const refineEmail = [
  (value: string) => {
    if (value.trim().length === 0) {
      return false
    }
    if (!value.includes('@')) {
      return false
    }
    if (!EMAIL_REGEX.test(value)) {
      return false
    }
    if (!STRICTER_EMAIL_REGEX.test(value)) {
      return false
    }
    return true
  },
  'Please submit a valid email',
] as const
