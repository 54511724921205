import HgDropdown from '../HgDropdown'
import {cn} from '~/design-system/utils'

export type HgItemsPerPageProps = {
  perPageOptions?: number[]
  onChange: (perPage: string) => void
  perPage: number | 'All'
  className?: string
}

function formatNumber(number: number) {
  return number.toString().padStart(2, '0')
}

export default function HgItemsPerPage(props: HgItemsPerPageProps) {
  const {perPageOptions = [10, 25, 50], onChange, perPage = 10, className} = props
  return (
    <div
      className={cn('flex w-[310px] items-center gap-s3 arcadia-body-3', className)}
    >
      <HgDropdown
        options={[...perPageOptions, 'All'].map(option => ({
          value: option.toString(),
          label: typeof option === 'number' ? formatNumber(option) : option,
        }))}
        value={perPage.toString()}
        onChange={onChange}
        classNames={{trigger: '[&_span]:w-16'}}
      />
      <span>Items per page</span>
    </div>
  )
}
