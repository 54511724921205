import {type VariantProps, tv} from 'tailwind-variants'
import {HgAspectRatio} from '~/design-system/hg/components'

export type HgHeroSplitImageProps = {
  type: 'splitImage'
  logoAsset: React.ReactNode
  size: 'long' | 'short'
  assetFourFiveRatio: React.ReactNode
} & VariantProps<typeof splitImageVariants>

const splitImageVariants = tv({
  base: 'col-span-full lg:col-span-8 lg:col-start-9 [&_img]:rounded-xl [&_video]:rounded-xl',
  variants: {
    size: {
      long: '',
      short: 'xl:col-span-6 xl:col-start-9',
    },
  },
})

export default function HgHeroSplitImageVariant(props: HgHeroSplitImageProps) {
  return (
    <HgAspectRatio
      aspectRatio="4:5"
      topSpacing="s9"
      classNames={{
        wrapper: splitImageVariants({size: props.size}),
        assetWrapper: 'object-position-top h-full',
      }}
    >
      {props.assetFourFiveRatio}
    </HgAspectRatio>
  )
}
