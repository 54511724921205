'use client'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'

// determines where & how menu content renders:
// https://www.radix-ui.com/primitives/docs/components/navigation-menu#viewport
export const HgNavigationViewport = () => (
  <div className="absolute left-0 top-full flex w-full justify-center">
    <NavigationMenu.Viewport className="relative mt-16 h-[var(--radix-navigation-menu-viewport-height)] w-[var(--radix-navigation-menu-viewport-width)] origin-[top_center] overflow-hidden rounded-2xl bg-surface-elevated shadow-[0px_0px_6px_0px_rgba(86,86,118,0.10)] transition-[width,_height] duration-[250ms] motion-safe:data-[state=closed]:animate-scaleOut motion-safe:data-[state=open]:animate-scaleIn motion-reduce:transition-none" />
  </div>
)
