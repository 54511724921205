'use client'

import {useEffect, useRef, useState} from 'react'
import {themeToClassName, type Theme} from '~/design-system/hg/tokens/colors'
import {cn} from '~/design-system/utils'
import {THEME_SWITCHER_ID} from './HgThemeSwitcher'

export type HgThemeSwitchScrollerProps = {
  observerOptions?: IntersectionObserverInit
  children: React.ReactNode
  temporaryChange?: boolean
}

export default function HgThemeSwitchScroller({
  observerOptions,
  children,
}: HgThemeSwitchScrollerProps) {
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [currentTheme, setCurrentTheme] = useState('neutral' as Theme)

  useEffect(() => {
    const initialTheme =
      (document
        .querySelectorAll(
          `[data-id=${THEME_SWITCHER_ID}][data-theme][data-apply-globally=true]`
        )[0]
        ?.getAttribute('data-theme') as Theme) || 'neutral'
    // Set initial global theme
    setCurrentTheme(initialTheme)
  }, [])

  const shouldUpdate = (section: IntersectionObserverEntry) => {
    return section.isIntersecting
  }

  useEffect(() => {
    if (!containerRef.current) return

    const observer = new IntersectionObserver(sections => {
      sections.forEach(section => {
        const targetSectionTheme = section.target.getAttribute('data-theme')
        const shouldApplyGlobally =
          section.target.getAttribute('data-apply-globally') !== 'false'

        if (shouldUpdate(section) && shouldApplyGlobally && targetSectionTheme) {
          setCurrentTheme(targetSectionTheme as Theme)
        }
      })
    }, observerOptions)

    const pageSections = document.querySelectorAll(
      `[data-id=${THEME_SWITCHER_ID}][data-theme]`
    )
    pageSections.forEach(section => {
      observer.observe(section)
    })

    intersectionObserverRef.current = observer

    return () => {
      if (intersectionObserverRef.current) {
        intersectionObserverRef.current.disconnect()
      }
    }
  }, [currentTheme, observerOptions])

  return (
    <div
      ref={containerRef}
      className={cn(
        'bg-background-default transition-colors duration-[350ms]',
        themeToClassName[currentTheme]
      )}
      id="theme-switch-scroller"
      data-global-theme={themeToClassName[currentTheme]}
    >
      {children}
    </div>
  )
}
