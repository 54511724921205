import {type OmitStrict} from 'type-zoo'
import {grid, gridItem} from '~/design-system/foundations'
import {HgCaption, type HgCaptionProps} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'

export type HgTextListingItemProps = OmitStrict<HgCaptionProps, 'topSpacing'>

export type HgTextListingProps = {
  listItems: [
    HgTextListingItemProps,
    HgTextListingItemProps,
    HgTextListingItemProps,
    HgTextListingItemProps?,
    HgTextListingItemProps?,
    HgTextListingItemProps?,
  ]
}

const HgTextListing = ({listItems}: HgTextListingProps) => {
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <div
          className={gridItem({
            size: 'max',
            class: cn(
              'grid gap-x-s4',
              listItems.length === 4
                ? 'sm:grid-cols-2 lg:grid-cols-4'
                : 'sm:grid-cols-3',
              '*:pt-s6 *:sm:pt-s9 [&>:first-child]:pt-s9'
            ),
          })}
        >
          {listItems.map(
            (listItem, index) =>
              listItem && <HgCaption key={index} {...listItem} alignment="center" />
          )}
        </div>
      </div>
    </div>
  )
}

export default HgTextListing
