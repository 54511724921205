import {type IconProp} from '@fortawesome/fontawesome-svg-core'
import {
  faArrowDown as faArrowDownLight,
  faArrowDownRight as faArrowDownRightLight,
  faArrowDownToLine as faArrowDownToLineLight,
  faArrowDownWideShort as faArrowDownWideShortLight,
  faArrowLeft as faArrowLeftLight,
  faArrowProgress as faArrowProgressLight,
  faArrowRightArrowLeft as faArrowRightArrowLeftLight,
  faArrowRight as faArrowRightLight,
  faArrowRotateRight as faArrowRotateRightLight,
  faArrowUp as faArrowUpLight,
  faArrowUpRightDots as faArrowUpRightDotsLight,
  faArrowUpRight as faArrowUpRightLight,
  faArrowUpShortWide as faArrowUpShortWideLight,
  faArrowsRotate as faArrowsRotateLight,
  faAtom as faAtomLight,
  faAtomSimple as faAtomSimpleLight,
  faBank as faBankLight,
  faBars as faBarsLight,
  faBell as faBellLight,
  faBookOpenCover as faBookOpenCoverLight,
  faBrainCircuit as faBrainCircuitLight,
  faBrowser as faBrowserLight,
  faBrowsers as faBrowsersLight,
  faBullseyeArrow as faBullseyeArrowLight,
  faCertificate as faCertificateLight,
  faChartWaterfall as faChartWaterfallLight,
  faCheckCircle as faCheckCircleLight,
  faCheck as faCheckLight,
  faChevronDown as faChevronDownLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faChevronUp as faChevronUpLight,
  faCircleCheck as faCircleCheckLight,
  faCircleExclamation as faCircleExclamationLight,
  faCircleInfo as faCircleInfoLight,
  faCircle as faCircleLight,
  faCircleNodes as faCircleNodesLight,
  faCircleXmark as faCircleXmarkLight,
  faCopy as faCopyLight,
  faClock as faClockLight,
  faCreditCard as faCreditCardLight,
  faDash as faDashLight,
  faDollar as faDollarLight,
  faEdit as faEditLight,
  faEllipsis as faEllipsisLight,
  faEnvelopeOpenText as faEnvelopeOpenTextLight,
  faFileCheck as faFileCheckLight,
  faFileInvoiceDollar as faFileInvoiceDollarLight,
  faFileInvoice as faFileInvoiceLight,
  faFileLock as faFileLockLight,
  faFileUser as faFileUserLight,
  faFileZipper as faFileZipperLight,
  faFilter as faFilterLight,
  faGear as faGearLight,
  faGears as faGearsLight,
  faGlobe as faGlobeLight,
  faHandHoldingMagic as faHandHoldingMagicLight,
  faHandSparkles as faHandSparklesLight,
  faHandshake as faHandshakeLight,
  faHeart as faHeartLight,
  faImage as faImageLight,
  faInfinity as faInfinityLight,
  faInfoCircle as faInfoCircleLight,
  faLayerGroup as faLayerGroupLight,
  faLightbulb as faLightbulbLight,
  faLink as faLinkLight,
  faLock as faLockLight,
  faMagnifyingGlassDollar as faMagnifyingGlassDollarLight,
  faMagnifyingGlass as faMagnifyingGlassLight,
  faMessagesQuestion as faMessagesQuestionLight,
  faMinus as faMinusLight,
  faMoneyBill as faMoneyBillLight,
  faMoneyBillTransfer as faMoneyBillTransferLight,
  faPaperPlane as faPaperPlaneLight,
  faPaste as faPasteLight,
  faPlus as faPlusLight,
  faReceipt as faReceiptLight,
  faRectangleList as faRectangleListLight,
  faScrewdriverWrench as faScrewdriverWrenchLight,
  faShield as faShieldLight,
  faSparkle as faSparkleLight,
  faSparkles as faSparklesLight,
  faSpinnerThird as faSpinnerThirdLight,
  faStar as faStarLight,
  faTable as faTableLight,
  faUserCheck as faUserCheckLight,
  faUserGroupSimple as faUserGroupSimpleLight,
  faUser as faUserLight,
  faUserTie as faUserTieLight,
  faUsers as faUsersLight,
  faVault as faVaultLight,
  faVolume as faVolumeLight,
  faVolumeSlash as faVolumeSlashLight,
  faXmark as faXmarkLight,
  faCalendar as faCalendarLight,
} from '@fortawesome/pro-light-svg-icons'
import {
  faArrowDown as faArrowDownRegular,
  faArrowDownRight as faArrowDownRightRegular,
  faArrowDownToLine as faArrowDownToLineRegular,
  faArrowDownWideShort as faArrowDownWideShortRegular,
  faArrowLeft as faArrowLeftRegular,
  faArrowProgress as faArrowProgressRegular,
  faArrowRightArrowLeft as faArrowRightArrowLeftRegular,
  faArrowRight as faArrowRightRegular,
  faArrowRotateRight as faArrowRotateRightRegular,
  faArrowUp as faArrowUpRegular,
  faArrowUpRightDots as faArrowUpRightDotsRegular,
  faArrowUpRight as faArrowUpRightRegular,
  faArrowUpShortWide as faArrowUpShortWideRegular,
  faArrowsRotate as faArrowsRotateRegular,
  faAtom as faAtomRegular,
  faAtomSimple as faAtomSimpleRegular,
  faBank as faBankRegular,
  faBars as faBarsRegular,
  faBell as faBellRegular,
  faClock as faClockRegular,
  faBookOpenCover as faBookOpenCoverRegular,
  faBrainCircuit as faBrainCircuitRegular,
  faBrowser as faBrowserRegular,
  faBrowsers as faBrowsersRegular,
  faBullseyeArrow as faBullseyeArrowRegular,
  faCertificate as faCertificateRegular,
  faChartWaterfall as faChartWaterfallRegular,
  faCheckCircle as faCheckCircleRegular,
  faCheck as faCheckRegular,
  faChevronDown as faChevronDownRegular,
  faChevronLeft as faChevronLeftRegular,
  faChevronRight as faChevronRightRegular,
  faChevronUp as faChevronUpRegular,
  faCircleCheck as faCircleCheckRegular,
  faCircleExclamation as faCircleExclamationRegular,
  faCircleInfo as faCircleInfoRegular,
  faCircleNodes as faCircleNodesRegular,
  faCircle as faCircleRegular,
  faCircleXmark as faCircleXmarkRegular,
  faCopy as faCopyRegular,
  faCreditCard as faCreditCardRegular,
  faDash as faDashRegular,
  faDollar as faDollarRegular,
  faEdit as faEditRegular,
  faEllipsis as faEllipsisRegular,
  faEnvelopeOpenText as faEnvelopeOpenTextRegular,
  faFileCheck as faFileCheckRegular,
  faFileInvoiceDollar as faFileInvoiceDollarRegular,
  faFileInvoice as faFileInvoiceRegular,
  faFileLock as faFileLockRegular,
  faFileUser as faFileUserRegular,
  faFileZipper as faFileZipperRegular,
  faFilter as faFilterRegular,
  faGear as faGearRegular,
  faGears as faGearsRegular,
  faGlobe as faGlobeRegular,
  faHandHoldingMagic as faHandHoldingMagicRegular,
  faHandSparkles as faHandSparklesRegular,
  faHandshake as faHandshakeRegular,
  faHeart as faHeartRegular,
  faImage as faImageRegular,
  faInfinity as faInfinityRegular,
  faInfoCircle as faInfoCircleRegular,
  faLayerGroup as faLayerGroupRegular,
  faLightbulb as faLightbulbRegular,
  faLink as faLinkRegular,
  faLock as faLockRegular,
  faMagnifyingGlassDollar as faMagnifyingGlassDollarRegular,
  faMagnifyingGlass as faMagnifyingGlassRegular,
  faMessagesQuestion as faMessagesQuestionRegular,
  faMinus as faMinusRegular,
  faMoneyBill as faMoneyBillRegular,
  faMoneyBillTransfer as faMoneyBillTransferRegular,
  faPaperPlane as faPaperPlaneRegular,
  faPaste as faPasteRegular,
  faPlus as faPlusRegular,
  faReceipt as faReceiptRegular,
  faRectangleList as faRectangleListRegular,
  faScrewdriverWrench as faScrewdriverWrenchRegular,
  faShield as faShieldRegular,
  faSparkle as faSparkleRegular,
  faSparkles as faSparklesRegular,
  faSpinnerThird as faSpinnerThirdRegular,
  faStar as faStarRegular,
  faTable as faTableRegular,
  faUserCheck as faUserCheckRegular,
  faUserGroupSimple as faUserGroupSimpleRegular,
  faUser as faUserRegular,
  faUserTie as faUserTieRegular,
  faUsers as faUsersRegular,
  faVault as faVaultRegular,
  faVolume as faVolumeRegular,
  faVolumeSlash as faVolumeSlashRegular,
  faXmark as faXmarkRegular,
  faCalendar as faCalendarRegular,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faPause as faPauseHeavy,
  faPlay as faPlayHeavy,
  faCaretUp as faCaretUpHeavy,
  faCaretDown as faCaretDownHeavy,
  faSort as faSortHeavy,
} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {tv, type VariantProps} from 'tailwind-variants'
import {cn, unreachableCase} from '~/design-system/utils'
import Placeholder from './_icons/placeholder.svg'

const iconSize = tv({
  base: 'inline-block overflow-visible text-inherit',
  variants: {
    size: {
      'xs': 'h-[0.75rem] [font-size:0.75rem]',
      'small': 'h-[0.875rem] [font-size:0.875rem]',
      'regular': 'h-[1rem] [font-size:1rem]',
      'large': 'h-[1.125rem] [font-size:1.125rem]',
      'xl': 'h-[1.5rem] [font-size:1.5rem]',
      '2xl': 'h-[2rem] [font-size:2rem]',
    },
    placeholder: {
      true: 'fill-none text-inherit',
    },
  },
})

type HgIconSize = NonNullable<VariantProps<typeof iconSize>['size']>

export const HgCustomIcons = ['placeholder'] as const

export const HgFAUIIcons = [
  'arrow-down',
  'arrow-down-right-angle',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'arrow-up-right-angle',
  'audio-off',
  'audio-on',
  'calendar',
  'caret-down',
  'caret-up',
  'caret-up-down',
  'checkmark',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clock',
  'copy',
  'dash',
  'download',
  'edit',
  'ellipsis',
  'error',
  'file-zipper',
  'filter',
  'info',
  'info-circle',
  'link',
  'loading',
  'menu',
  'minus',
  'paste',
  'pause',
  'play',
  'plus',
  'search',
  'settings',
  'user',
  'xmark',
  'xmark-circle',
  'success',
  'star',
] as const

export const HgFADecorativeIcons = [
  'arrow-down-wide-short',
  'arrow-right-arrow-left',
  'arrows-rotate',
  'arrow-rotate-right',
  'arrow-up-short-wide',
  'atom',
  'atom-simple',
  'bank',
  'bell',
  'book-open-cover',
  'brain',
  'browser',
  'browsers',
  'bullseye-arrow',
  'certificate',
  'chart-waterfall',
  'credit-card',
  'circle-check',
  'circle-nodes',
  'dollar',
  'envelope-open-text',
  'file-check',
  'file-invoice',
  'file-lock',
  'file-user',
  'gears',
  'globe',
  'growth-chart',
  'handshake',
  'hand-sparkles',
  'heart',
  'image',
  'infinity',
  'invoice',
  'lightbulb',
  'lock',
  'magnifying-glass-dollar',
  'messages-question',
  'money-bill',
  'money-bill-transfer',
  'paper-plane',
  'pathway',
  'receipt',
  'rectangle-list',
  'screwdriver-wrench',
  'shield',
  'sparkle',
  'sparkles',
  'stack',
  'table',
  'user-check',
  'user-group-simple',
  'user-tie',
  'users',
  'vault',
  'wise-hand',
] as const

type HgFAUIIconType = (typeof HgFAUIIcons)[number]
type HgFADecorativeIconType = (typeof HgFADecorativeIcons)[number]
type HgFAIconType = HgFADecorativeIconType | HgFAUIIconType
type HgCustomIconType = (typeof HgCustomIcons)[number]

export type HgIconType = HgFAIconType | HgCustomIconType

type HgIconProps = {
  className?: string
  iconType: HgIconType
  size: HgIconSize
}

type HgFAIconProps = {
  className?: string
  iconType: HgFAIconType
  size: HgIconSize
}

const FA_UI_ICON_MAP: Record<
  // 'loading' is a special case of 2 FA icons being combined rather than the 1:1 mapping of the other FA icons
  Exclude<HgFAUIIconType, 'loading'>,
  Record<HgIconSize, IconProp>
> = {
  'arrow-down': {
    'xs': faArrowDownRegular,
    'small': faArrowDownRegular,
    'regular': faArrowDownRegular,
    'large': faArrowDownLight,
    'xl': faArrowDownLight,
    '2xl': faArrowDownLight,
  },
  'arrow-down-right-angle': {
    'xs': faArrowDownRightRegular,
    'small': faArrowDownRightRegular,
    'regular': faArrowDownRightRegular,
    'large': faArrowDownRightLight,
    'xl': faArrowDownRightLight,
    '2xl': faArrowDownRightLight,
  },
  'arrow-left': {
    'xs': faArrowLeftRegular,
    'small': faArrowLeftRegular,
    'regular': faArrowLeftRegular,
    'large': faArrowLeftLight,
    'xl': faArrowLeftLight,
    '2xl': faArrowLeftLight,
  },
  'arrow-right': {
    'xs': faArrowRightRegular,
    'small': faArrowRightRegular,
    'regular': faArrowRightRegular,
    'large': faArrowRightLight,
    'xl': faArrowRightLight,
    '2xl': faArrowRightLight,
  },
  'arrow-up': {
    'xs': faArrowUpRegular,
    'small': faArrowUpRegular,
    'regular': faArrowUpRegular,
    'large': faArrowUpLight,
    'xl': faArrowUpLight,
    '2xl': faArrowUpLight,
  },
  'arrow-up-right-angle': {
    'xs': faArrowUpRightRegular,
    'small': faArrowUpRightRegular,
    'regular': faArrowUpRightRegular,
    'large': faArrowUpRightLight,
    'xl': faArrowUpRightLight,
    '2xl': faArrowUpRightLight,
  },
  'audio-off': {
    'xs': faVolumeSlashRegular,
    'small': faVolumeSlashRegular,
    'regular': faVolumeSlashRegular,
    'large': faVolumeSlashLight,
    'xl': faVolumeSlashLight,
    '2xl': faVolumeSlashLight,
  },
  'audio-on': {
    'xs': faVolumeRegular,
    'small': faVolumeRegular,
    'regular': faVolumeRegular,
    'large': faVolumeLight,
    'xl': faVolumeLight,
    '2xl': faVolumeLight,
  },
  'calendar': {
    'xs': faCalendarRegular,
    'small': faCalendarRegular,
    'regular': faCalendarRegular,
    'large': faCalendarLight,
    'xl': faCalendarLight,
    '2xl': faCalendarLight,
  },
  'caret-down': {
    'xs': faCaretDownHeavy,
    'small': faCaretDownHeavy,
    'regular': faCaretDownHeavy,
    'large': faCaretDownHeavy,
    'xl': faCaretDownHeavy,
    '2xl': faCaretDownHeavy,
  },
  'caret-up': {
    'xs': faCaretUpHeavy,
    'small': faCaretUpHeavy,
    'regular': faCaretUpHeavy,
    'large': faCaretUpHeavy,
    'xl': faCaretUpHeavy,
    '2xl': faCaretUpHeavy,
  },
  'caret-up-down': {
    'xs': faSortHeavy,
    'small': faSortHeavy,
    'regular': faSortHeavy,
    'large': faSortHeavy,
    'xl': faSortHeavy,
    '2xl': faSortHeavy,
  },
  'checkmark': {
    'xs': faCheckRegular,
    'small': faCheckRegular,
    'regular': faCheckRegular,
    'large': faCheckLight,
    'xl': faCheckLight,
    '2xl': faCheckLight,
  },
  'chevron-down': {
    'xs': faChevronDownRegular,
    'small': faChevronDownRegular,
    'regular': faChevronDownRegular,
    'large': faChevronDownLight,
    'xl': faChevronDownLight,
    '2xl': faChevronDownLight,
  },
  'chevron-left': {
    'xs': faChevronLeftRegular,
    'small': faChevronLeftRegular,
    'regular': faChevronLeftRegular,
    'large': faChevronLeftLight,
    'xl': faChevronLeftLight,
    '2xl': faChevronLeftLight,
  },
  'chevron-right': {
    'xs': faChevronRightRegular,
    'small': faChevronRightRegular,
    'regular': faChevronRightRegular,
    'large': faChevronRightLight,
    'xl': faChevronRightLight,
    '2xl': faChevronRightLight,
  },
  'chevron-up': {
    'xs': faChevronUpRegular,
    'small': faChevronUpRegular,
    'regular': faChevronUpRegular,
    'large': faChevronUpLight,
    'xl': faChevronUpLight,
    '2xl': faChevronUpLight,
  },
  'clock': {
    'xs': faClockRegular,
    'small': faClockRegular,
    'regular': faClockRegular,
    'large': faClockLight,
    'xl': faClockLight,
    '2xl': faClockLight,
  },
  'copy': {
    'xs': faCopyRegular,
    'small': faCopyRegular,
    'regular': faCopyRegular,
    'large': faCopyLight,
    'xl': faCopyLight,
    '2xl': faCopyLight,
  },
  'dash': {
    'xs': faDashRegular,
    'small': faDashRegular,
    'regular': faDashRegular,
    'large': faDashLight,
    'xl': faDashLight,
    '2xl': faDashLight,
  },
  'download': {
    'xs': faArrowDownToLineRegular,
    'small': faArrowDownToLineRegular,
    'regular': faArrowDownToLineRegular,
    'large': faArrowDownToLineLight,
    'xl': faArrowDownToLineLight,
    '2xl': faArrowDownToLineLight,
  },
  'edit': {
    'xs': faEditRegular,
    'small': faEditRegular,
    'regular': faEditRegular,
    'large': faEditLight,
    'xl': faEditLight,
    '2xl': faEditLight,
  },
  'ellipsis': {
    'xs': faEllipsisRegular,
    'small': faEllipsisRegular,
    'regular': faEllipsisRegular,
    'large': faEllipsisLight,
    'xl': faEllipsisLight,
    '2xl': faEllipsisLight,
  },
  'error': {
    'xs': faCircleExclamationRegular,
    'small': faCircleExclamationRegular,
    'regular': faCircleExclamationRegular,
    'large': faCircleExclamationLight,
    'xl': faCircleExclamationLight,
    '2xl': faCircleExclamationLight,
  },
  'file-zipper': {
    'xs': faFileZipperRegular,
    'small': faFileZipperRegular,
    'regular': faFileZipperRegular,
    'large': faFileZipperLight,
    'xl': faFileZipperLight,
    '2xl': faFileZipperLight,
  },
  'filter': {
    'xs': faFilterRegular,
    'small': faFilterRegular,
    'regular': faFilterRegular,
    'large': faFilterLight,
    'xl': faFilterLight,
    '2xl': faFilterLight,
  },
  'info': {
    'xs': faCircleInfoRegular,
    'small': faCircleInfoRegular,
    'regular': faCircleInfoRegular,
    'large': faCircleInfoLight,
    'xl': faCircleInfoLight,
    '2xl': faCircleInfoLight,
  },
  'info-circle': {
    'xs': faInfoCircleRegular,
    'small': faInfoCircleRegular,
    'regular': faInfoCircleRegular,
    'large': faInfoCircleLight,
    'xl': faInfoCircleLight,
    '2xl': faInfoCircleLight,
  },
  'link': {
    'xs': faLinkRegular,
    'small': faLinkRegular,
    'regular': faLinkRegular,
    'large': faLinkLight,
    'xl': faLinkLight,
    '2xl': faLinkLight,
  },
  'menu': {
    'xs': faBarsRegular,
    'small': faBarsRegular,
    'regular': faBarsRegular,
    'large': faBarsLight,
    'xl': faBarsLight,
    '2xl': faBarsLight,
  },
  'minus': {
    'xs': faMinusRegular,
    'small': faMinusRegular,
    'regular': faMinusRegular,
    'large': faMinusLight,
    'xl': faMinusLight,
    '2xl': faMinusLight,
  },
  'paste': {
    'xs': faPasteRegular,
    'small': faPasteRegular,
    'regular': faPasteRegular,
    'large': faPasteLight,
    'xl': faPasteLight,
    '2xl': faPasteLight,
  },
  'pause': {
    'xs': faPauseHeavy,
    'small': faPauseHeavy,
    'regular': faPauseHeavy,
    'large': faPauseHeavy,
    'xl': faPauseHeavy,
    '2xl': faPauseHeavy,
  },
  'play': {
    'xs': faPlayHeavy,
    'small': faPlayHeavy,
    'regular': faPlayHeavy,
    'large': faPlayHeavy,
    'xl': faPlayHeavy,
    '2xl': faPlayHeavy,
  },
  'plus': {
    'xs': faPlusRegular,
    'small': faPlusRegular,
    'regular': faPlusRegular,
    'large': faPlusLight,
    'xl': faPlusLight,
    '2xl': faPlusLight,
  },
  'search': {
    'xs': faMagnifyingGlassRegular,
    'small': faMagnifyingGlassRegular,
    'regular': faMagnifyingGlassRegular,
    'large': faMagnifyingGlassLight,
    'xl': faMagnifyingGlassLight,
    '2xl': faMagnifyingGlassLight,
  },
  'settings': {
    'xs': faGearRegular,
    'small': faGearRegular,
    'regular': faGearRegular,
    'large': faGearLight,
    'xl': faGearLight,
    '2xl': faGearLight,
  },
  'star': {
    'xs': faStarRegular,
    'small': faStarRegular,
    'regular': faStarRegular,
    'large': faStarLight,
    'xl': faStarLight,
    '2xl': faStarLight,
  },
  'success': {
    'xs': faCheckCircleRegular,
    'small': faCheckCircleRegular,
    'regular': faCheckCircleRegular,
    'large': faCheckCircleLight,
    'xl': faCheckCircleLight,
    '2xl': faCheckCircleLight,
  },
  'user': {
    'xs': faUserRegular,
    'small': faUserRegular,
    'regular': faUserRegular,
    'large': faUserLight,
    'xl': faUserLight,
    '2xl': faUserLight,
  },
  'xmark': {
    'xs': faXmarkRegular,
    'small': faXmarkRegular,
    'regular': faXmarkRegular,
    'large': faXmarkLight,
    'xl': faXmarkLight,
    '2xl': faXmarkLight,
  },
  'xmark-circle': {
    'xs': faCircleXmarkRegular,
    'small': faCircleXmarkRegular,
    'regular': faCircleXmarkRegular,
    'large': faCircleXmarkLight,
    'xl': faCircleXmarkLight,
    '2xl': faCircleXmarkLight,
  },
}

const FA_DECORATIVE_ICON_MAP: Record<
  HgFADecorativeIconType,
  Record<HgIconSize, IconProp>
> = {
  'arrow-down-wide-short': {
    'xs': faArrowDownWideShortRegular,
    'small': faArrowDownWideShortRegular,
    'regular': faArrowDownWideShortRegular,
    'large': faArrowDownWideShortLight,
    'xl': faArrowDownWideShortLight,
    '2xl': faArrowDownWideShortLight,
  },
  'arrow-right-arrow-left': {
    'xs': faArrowRightArrowLeftRegular,
    'small': faArrowRightArrowLeftRegular,
    'regular': faArrowRightArrowLeftRegular,
    'large': faArrowRightArrowLeftLight,
    'xl': faArrowRightArrowLeftLight,
    '2xl': faArrowRightArrowLeftLight,
  },
  'arrow-rotate-right': {
    'xs': faArrowRotateRightRegular,
    'small': faArrowRotateRightRegular,
    'regular': faArrowRotateRightRegular,
    'large': faArrowRotateRightLight,
    'xl': faArrowRotateRightLight,
    '2xl': faArrowRotateRightLight,
  },
  'arrow-up-short-wide': {
    'xs': faArrowUpShortWideRegular,
    'small': faArrowUpShortWideRegular,
    'regular': faArrowUpShortWideRegular,
    'large': faArrowUpShortWideLight,
    'xl': faArrowUpShortWideLight,
    '2xl': faArrowUpShortWideLight,
  },
  'arrows-rotate': {
    'xs': faArrowsRotateRegular,
    'small': faArrowsRotateRegular,
    'regular': faArrowsRotateRegular,
    'large': faArrowsRotateLight,
    'xl': faArrowsRotateLight,
    '2xl': faArrowsRotateLight,
  },
  'atom': {
    'xs': faAtomRegular,
    'small': faAtomRegular,
    'regular': faAtomRegular,
    'large': faAtomLight,
    'xl': faAtomLight,
    '2xl': faAtomLight,
  },
  'atom-simple': {
    'xs': faAtomSimpleRegular,
    'small': faAtomSimpleRegular,
    'regular': faAtomSimpleRegular,
    'large': faAtomSimpleLight,
    'xl': faAtomSimpleLight,
    '2xl': faAtomSimpleLight,
  },
  'bank': {
    'xs': faBankRegular,
    'small': faBankRegular,
    'regular': faBankRegular,
    'large': faBankLight,
    'xl': faBankLight,
    '2xl': faBankLight,
  },
  'bell': {
    'xs': faBellRegular,
    'small': faBellRegular,
    'regular': faBellRegular,
    'large': faBellLight,
    'xl': faBellLight,
    '2xl': faBellLight,
  },
  'brain': {
    'xs': faBrainCircuitRegular,
    'small': faBrainCircuitRegular,
    'regular': faBrainCircuitRegular,
    'large': faBrainCircuitLight,
    'xl': faBrainCircuitLight,
    '2xl': faBrainCircuitLight,
  },
  'book-open-cover': {
    'xs': faBookOpenCoverRegular,
    'small': faBookOpenCoverRegular,
    'regular': faBookOpenCoverRegular,
    'large': faBookOpenCoverLight,
    'xl': faBookOpenCoverLight,
    '2xl': faBookOpenCoverLight,
  },
  'browser': {
    'xs': faBrowserRegular,
    'small': faBrowserRegular,
    'regular': faBrowserRegular,
    'large': faBrowserLight,
    'xl': faBrowserLight,
    '2xl': faBrowserLight,
  },
  'browsers': {
    'xs': faBrowsersRegular,
    'small': faBrowsersRegular,
    'regular': faBrowsersRegular,
    'large': faBrowsersLight,
    'xl': faBrowsersLight,
    '2xl': faBrowsersLight,
  },
  'bullseye-arrow': {
    'xs': faBullseyeArrowRegular,
    'small': faBullseyeArrowRegular,
    'regular': faBullseyeArrowRegular,
    'large': faBullseyeArrowLight,
    'xl': faBullseyeArrowLight,
    '2xl': faBullseyeArrowLight,
  },
  'certificate': {
    'xs': faCertificateRegular,
    'small': faCertificateRegular,
    'regular': faCertificateRegular,
    'large': faCertificateLight,
    'xl': faCertificateLight,
    '2xl': faCertificateLight,
  },
  'chart-waterfall': {
    'xs': faChartWaterfallRegular,
    'small': faChartWaterfallRegular,
    'regular': faChartWaterfallRegular,
    'large': faChartWaterfallLight,
    'xl': faChartWaterfallLight,
    '2xl': faChartWaterfallLight,
  },
  'circle-check': {
    'xs': faCircleCheckRegular,
    'small': faCircleCheckRegular,
    'regular': faCircleCheckRegular,
    'large': faCircleCheckLight,
    'xl': faCircleCheckLight,
    '2xl': faCircleCheckLight,
  },
  'circle-nodes': {
    'xs': faCircleNodesRegular,
    'small': faCircleNodesRegular,
    'regular': faCircleNodesRegular,
    'large': faCircleNodesLight,
    'xl': faCircleNodesLight,
    '2xl': faCircleNodesLight,
  },
  'credit-card': {
    'xs': faCreditCardRegular,
    'small': faCreditCardRegular,
    'regular': faCreditCardRegular,
    'large': faCreditCardLight,
    'xl': faCreditCardLight,
    '2xl': faCreditCardLight,
  },
  'dollar': {
    'xs': faDollarRegular,
    'small': faDollarRegular,
    'regular': faDollarRegular,
    'large': faDollarLight,
    'xl': faDollarLight,
    '2xl': faDollarLight,
  },
  'envelope-open-text': {
    'xs': faEnvelopeOpenTextRegular,
    'small': faEnvelopeOpenTextRegular,
    'regular': faEnvelopeOpenTextRegular,
    'large': faEnvelopeOpenTextLight,
    'xl': faEnvelopeOpenTextLight,
    '2xl': faEnvelopeOpenTextLight,
  },
  'file-check': {
    'xs': faFileCheckRegular,
    'small': faFileCheckRegular,
    'regular': faFileCheckRegular,
    'large': faFileCheckLight,
    'xl': faFileCheckLight,
    '2xl': faFileCheckLight,
  },
  'file-invoice': {
    'xs': faFileInvoiceRegular,
    'small': faFileInvoiceRegular,
    'regular': faFileInvoiceRegular,
    'large': faFileInvoiceLight,
    'xl': faFileInvoiceLight,
    '2xl': faFileInvoiceLight,
  },
  'file-lock': {
    'xs': faFileLockRegular,
    'small': faFileLockRegular,
    'regular': faFileLockRegular,
    'large': faFileLockLight,
    'xl': faFileLockLight,
    '2xl': faFileLockLight,
  },
  'file-user': {
    'xs': faFileUserRegular,
    'small': faFileUserRegular,
    'regular': faFileUserRegular,
    'large': faFileUserLight,
    'xl': faFileUserLight,
    '2xl': faFileUserLight,
  },
  'gears': {
    'xs': faGearsRegular,
    'small': faGearsRegular,
    'regular': faGearsRegular,
    'large': faGearsLight,
    'xl': faGearsLight,
    '2xl': faGearsLight,
  },
  'globe': {
    'xs': faGlobeRegular,
    'small': faGlobeRegular,
    'regular': faGlobeRegular,
    'large': faGlobeLight,
    'xl': faGlobeLight,
    '2xl': faGlobeLight,
  },
  'growth-chart': {
    'xs': faArrowUpRightDotsRegular,
    'small': faArrowUpRightDotsRegular,
    'regular': faArrowUpRightDotsRegular,
    'large': faArrowUpRightDotsLight,
    'xl': faArrowUpRightDotsLight,
    '2xl': faArrowUpRightDotsLight,
  },
  'handshake': {
    'xs': faHandshakeRegular,
    'small': faHandshakeRegular,
    'regular': faHandshakeRegular,
    'large': faHandshakeLight,
    'xl': faHandshakeLight,
    '2xl': faHandshakeLight,
  },
  'hand-sparkles': {
    'xs': faHandSparklesRegular,
    'small': faHandSparklesRegular,
    'regular': faHandSparklesRegular,
    'large': faHandSparklesLight,
    'xl': faHandSparklesLight,
    '2xl': faHandSparklesLight,
  },
  'heart': {
    'xs': faHeartRegular,
    'small': faHeartRegular,
    'regular': faHeartRegular,
    'large': faHeartLight,
    'xl': faHeartLight,
    '2xl': faHeartLight,
  },
  'image': {
    'xs': faImageRegular,
    'small': faImageRegular,
    'regular': faImageRegular,
    'large': faImageLight,
    'xl': faImageLight,
    '2xl': faImageLight,
  },
  'infinity': {
    'xs': faInfinityRegular,
    'small': faInfinityRegular,
    'regular': faInfinityRegular,
    'large': faInfinityLight,
    'xl': faInfinityLight,
    '2xl': faInfinityLight,
  },
  'invoice': {
    'xs': faFileInvoiceDollarRegular,
    'small': faFileInvoiceDollarRegular,
    'regular': faFileInvoiceDollarRegular,
    'large': faFileInvoiceDollarLight,
    'xl': faFileInvoiceDollarLight,
    '2xl': faFileInvoiceDollarLight,
  },
  'lightbulb': {
    'xs': faLightbulbRegular,
    'small': faLightbulbRegular,
    'regular': faLightbulbRegular,
    'large': faLightbulbLight,
    'xl': faLightbulbLight,
    '2xl': faLightbulbLight,
  },
  'lock': {
    'xs': faLockRegular,
    'small': faLockRegular,
    'regular': faLockRegular,
    'large': faLockLight,
    'xl': faLockLight,
    '2xl': faLockLight,
  },
  'magnifying-glass-dollar': {
    'xs': faMagnifyingGlassDollarRegular,
    'small': faMagnifyingGlassDollarRegular,
    'regular': faMagnifyingGlassDollarRegular,
    'large': faMagnifyingGlassDollarLight,
    'xl': faMagnifyingGlassDollarLight,
    '2xl': faMagnifyingGlassDollarLight,
  },
  'messages-question': {
    'xs': faMessagesQuestionRegular,
    'small': faMessagesQuestionRegular,
    'regular': faMessagesQuestionRegular,
    'large': faMessagesQuestionLight,
    'xl': faMessagesQuestionLight,
    '2xl': faMessagesQuestionLight,
  },
  'money-bill': {
    'xs': faMoneyBillRegular,
    'small': faMoneyBillRegular,
    'regular': faMoneyBillRegular,
    'large': faMoneyBillLight,
    'xl': faMoneyBillLight,
    '2xl': faMoneyBillLight,
  },
  'money-bill-transfer': {
    'xs': faMoneyBillTransferRegular,
    'small': faMoneyBillTransferRegular,
    'regular': faMoneyBillTransferRegular,
    'large': faMoneyBillTransferLight,
    'xl': faMoneyBillTransferLight,
    '2xl': faMoneyBillTransferLight,
  },
  'paper-plane': {
    'xs': faPaperPlaneRegular,
    'small': faPaperPlaneRegular,
    'regular': faPaperPlaneRegular,
    'large': faPaperPlaneLight,
    'xl': faPaperPlaneLight,
    '2xl': faPaperPlaneLight,
  },
  'pathway': {
    'xs': faArrowProgressRegular,
    'small': faArrowProgressRegular,
    'regular': faArrowProgressRegular,
    'large': faArrowProgressLight,
    'xl': faArrowProgressLight,
    '2xl': faArrowProgressLight,
  },
  'receipt': {
    'xs': faReceiptRegular,
    'small': faReceiptRegular,
    'regular': faReceiptRegular,
    'large': faReceiptLight,
    'xl': faReceiptLight,
    '2xl': faReceiptLight,
  },
  'rectangle-list': {
    'xs': faRectangleListRegular,
    'small': faRectangleListRegular,
    'regular': faRectangleListRegular,
    'large': faRectangleListLight,
    'xl': faRectangleListLight,
    '2xl': faRectangleListLight,
  },
  'screwdriver-wrench': {
    'xs': faScrewdriverWrenchRegular,
    'small': faScrewdriverWrenchRegular,
    'regular': faScrewdriverWrenchRegular,
    'large': faScrewdriverWrenchLight,
    'xl': faScrewdriverWrenchLight,
    '2xl': faScrewdriverWrenchLight,
  },
  'shield': {
    'xs': faShieldRegular,
    'small': faShieldRegular,
    'regular': faShieldRegular,
    'large': faShieldLight,
    'xl': faShieldLight,
    '2xl': faShieldLight,
  },
  'sparkle': {
    'xs': faSparkleRegular,
    'small': faSparkleRegular,
    'regular': faSparkleRegular,
    'large': faSparkleLight,
    'xl': faSparkleLight,
    '2xl': faSparkleLight,
  },
  'sparkles': {
    'xs': faSparklesRegular,
    'small': faSparklesRegular,
    'regular': faSparklesRegular,
    'large': faSparklesLight,
    'xl': faSparklesLight,
    '2xl': faSparklesLight,
  },
  'stack': {
    'xs': faLayerGroupRegular,
    'small': faLayerGroupRegular,
    'regular': faLayerGroupRegular,
    'large': faLayerGroupLight,
    'xl': faLayerGroupLight,
    '2xl': faLayerGroupLight,
  },
  'table': {
    'xs': faTableRegular,
    'small': faTableRegular,
    'regular': faTableRegular,
    'large': faTableLight,
    'xl': faTableLight,
    '2xl': faTableLight,
  },
  'user-check': {
    'xs': faUserCheckRegular,
    'small': faUserCheckRegular,
    'regular': faUserCheckRegular,
    'large': faUserCheckLight,
    'xl': faUserCheckLight,
    '2xl': faUserCheckLight,
  },
  'user-group-simple': {
    'xs': faUserGroupSimpleRegular,
    'small': faUserGroupSimpleRegular,
    'regular': faUserGroupSimpleRegular,
    'large': faUserGroupSimpleLight,
    'xl': faUserGroupSimpleLight,
    '2xl': faUserGroupSimpleLight,
  },
  'user-tie': {
    'xs': faUserTieRegular,
    'small': faUserTieRegular,
    'regular': faUserTieRegular,
    'large': faUserTieLight,
    'xl': faUserTieLight,
    '2xl': faUserTieLight,
  },
  'users': {
    'xs': faUsersRegular,
    'small': faUsersRegular,
    'regular': faUsersRegular,
    'large': faUsersLight,
    'xl': faUsersLight,
    '2xl': faUsersLight,
  },
  'vault': {
    'xs': faVaultRegular,
    'small': faVaultRegular,
    'regular': faVaultRegular,
    'large': faVaultLight,
    'xl': faVaultLight,
    '2xl': faVaultLight,
  },
  'wise-hand': {
    'xs': faHandHoldingMagicRegular,
    'small': faHandHoldingMagicRegular,
    'regular': faHandHoldingMagicRegular,
    'large': faHandHoldingMagicLight,
    'xl': faHandHoldingMagicLight,
    '2xl': faHandHoldingMagicLight,
  },
} as const

const SANCTIONED_FA_ICON_MAP: Record<
  Exclude<HgFAIconType, 'loading'>,
  Record<HgIconSize, IconProp>
> = {
  ...FA_UI_ICON_MAP,
  ...FA_DECORATIVE_ICON_MAP,
}

const LoadingFaIcon = ({size}: {size: HgIconSize}) => {
  return (
    <div className="grid">
      <FontAwesomeIcon
        className={iconSize({
          size,
          className:
            'col-start-1 col-end-auto row-start-1 row-end-auto text-inherit opacity-25',
        })}
        icon={size === 'large' ? faCircleLight : faCircleRegular}
      />
      <FontAwesomeIcon
        className={iconSize({
          size,
          className:
            'z-1 col-start-1 col-end-auto row-start-1 row-end-auto animate-spin text-inherit',
        })}
        icon={size === 'large' ? faSpinnerThirdLight : faSpinnerThirdRegular}
      />
    </div>
  )
}

const HgFAIcon = ({className, iconType, size}: HgFAIconProps) => {
  if (iconType === 'loading') {
    return <LoadingFaIcon size={size} />
  }

  return (
    <FontAwesomeIcon
      className={iconSize({size, className})}
      icon={SANCTIONED_FA_ICON_MAP[iconType]?.[size]}
    />
  )
}

function isHgFAIcon(iconType: HgIconType): iconType is HgFAIconType {
  return [...HgFAUIIcons, ...HgFADecorativeIcons].includes(iconType as HgFAIconType)
}

function isHgCustomIcon(iconType: HgIconType): iconType is HgCustomIconType {
  return HgCustomIcons.includes(iconType as HgCustomIconType)
}

const HgIcon = ({className, iconType, size}: HgIconProps) => {
  if (isHgFAIcon(iconType)) {
    return (
      <HgFAIcon
        className={cn(className, iconType === 'play' && 'relative -right-1')}
        iconType={iconType}
        size={size}
      />
    )
  }

  if (isHgCustomIcon(iconType)) {
    switch (iconType) {
      case 'placeholder':
        return (
          <Placeholder
            className={iconSize({size, placeholder: true, class: className})}
          />
        )
      default:
        return null
    }
  }

  return unreachableCase(iconType)
}

export default HgIcon
