'use client'
import {type OmitStrict} from 'type-zoo'
import grid, {gridItem} from '~/design-system/foundations/Grid'
import {
  HgHeading,
  type HgHeadingButtonGroupProps,
  type HgHeadingContentTypeProps,
  type HgHeadingEmailProps,
  type HgHeadingNoContent,
  type HgHeadingProps,
} from '~/design-system/hg/components'

export type HgSectionTitleContentTypeProps =
  | Omit<HgHeadingEmailProps, 'form'>
  | HgHeadingButtonGroupProps
  | HgHeadingNoContent

export type HgSectionTitleProps = {
  title: React.ReactNode
} & OmitStrict<
  Extract<HgHeadingProps, {variation: 'section'}>,
  'headline' | 'variation' | 'topSpacing' | 'alignment'
> &
  HgSectionTitleContentTypeProps

const HgSectionTitle = (props: HgSectionTitleProps) => {
  function getHeadingProps(): HgHeadingContentTypeProps {
    switch (props.contentType) {
      case 'emailCapture': {
        return {
          ...props,
          contentType: 'emailCapture',
          emailCaptureProps: props.emailCaptureProps,
        }
      }
      case 'buttonGroup':
        return {
          contentType: 'buttonGroup',
          primaryButtonProps: props.primaryButtonProps,
          secondaryButtonProps: props.secondaryButtonProps,
        }
      case 'none':
      default:
        return {
          contentType: 'none',
        }
    }
  }
  return (
    <div className="w-full">
      <div className={grid({class: 'mx-auto'})}>
        <HgHeading
          {...getHeadingProps()}
          headline={props.title}
          subhead={props.subhead}
          topSpacing="s12"
          variation="section"
          alignment="center"
          className={gridItem({size: 'heading'})}
        />
      </div>
    </div>
  )
}

export default HgSectionTitle
