'use client'

import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import {cn} from '~/design-system/utils'

const TooltipProvider = TooltipPrimitive.Provider

const TooltipRoot = TooltipPrimitive.Root

const TooltipTrigger = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>
>(({className, children, ...props}, ref) => (
  <TooltipPrimitive.Trigger ref={ref} className={className} {...props} asChild>
    {children}
  </TooltipPrimitive.Trigger>
))

TooltipTrigger.displayName = 'TooltipTrigger'

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({className, children, ...props}, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    className={cn(className, 'max-w-[300px]')}
    {...props}
  >
    {children}
  </TooltipPrimitive.Content>
))

TooltipContent.displayName = 'TooltipContent'

const TooltipArrow = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Arrow>
>(({className, ...props}, ref) => (
  <TooltipPrimitive.Arrow ref={ref} className={className} {...props} />
))

TooltipArrow.displayName = 'TooltipArrow'

export {TooltipProvider, TooltipArrow, TooltipTrigger, TooltipContent, TooltipRoot}
