export type AuraColors = Exclude<keyof typeof colors, 'transparent'>

export type AuraBaseColorScale = keyof (typeof colors)[AuraColors]['base']
export type AuraMagicColorScale = keyof (typeof colors)[AuraColors]['magic']

export type AuraColorScaleTokens =
  | `base-${AuraBaseColorScale}`
  | `magic-${AuraMagicColorScale}`

export type AuraColorToken = `${AuraColors}-${AuraColorScaleTokens}`

export const red = {
  base: {
    1000: '#151011',
    950: '#201418',
    900: '#2f161e',
    800: '#411a28',
    700: '#5a2236',
    600: '#803b53',
    500: '#a4546f',
    400: '#d2849c',
    300: '#eab2c2',
    200: '#fdd0dc',
    150: '#fbe8ed',
    100: '#fdf2f5',
    50: '#fefbfc',
    0: '#ffffff',
  },
  magic: {
    800: '#93004c',
    700: '#b0175f',
    600: {
      DEFAULT: '#d03275',
      alpha: {
        5: 'rgba(208, 50, 117, .22)',
        4: 'rgba(208, 50, 117, .16)',
        3: 'rgba(208, 50, 117, .10)',
        2: 'rgba(208, 50, 117, .06)',
        1: 'rgba(208, 50, 117, .02)',
      },
    },
    400: {
      DEFAULT: '#fc92b4',
      alpha: {
        5: 'rgba(252, 146, 180, .36)',
        4: 'rgba(252, 146, 180, .28000000000000004)',
        3: 'rgba(252, 146, 180, .20)',
        2: 'rgba(252, 146, 180, .12)',
        1: 'rgba(252, 146, 180, .08)',
      },
    },
    300: '#fdb2c8',
    200: '#fdcbd9',
  },
}

export const orange = {
  base: {
    1000: '#15100f',
    950: '#1c1715',
    900: '#281c17',
    800: '#38231a',
    700: '#4e2f21',
    600: '#764833',
    500: '#9c6045',
    400: '#d18b6c',
    300: '#edb69d',
    200: '#f8d6c7',
    150: '#faeae3',
    100: '#fdf3ef',
    50: '#fffbf9',
    0: '#ffffff',
  },
  magic: {
    800: '#863400',
    700: '#a44200',
    600: {
      DEFAULT: '#c45000',
      alpha: {
        5: 'rgba(196, 80, 0, .22)',
        4: 'rgba(196, 80, 0, .16)',
        3: 'rgba(196, 80, 0, .10)',
        2: 'rgba(196, 80, 0, .06)',
        1: 'rgba(196, 80, 0, .02)',
      },
    },
    400: {
      DEFAULT: '#fc9b6f',
      alpha: {
        5: 'rgba(252, 155, 111, .36)',
        4: 'rgba(252, 155, 111, .28000000000000004)',
        3: 'rgba(252, 155, 111, .20)',
        2: 'rgba(252, 155, 111, .12)',
        1: 'rgba(252, 155, 111, .08)',
      },
    },
    300: '#ffb392',
    200: '#fecdb7',
  },
}

export const beige = {
  base: {
    1000: '#121212',
    950: '#181818',
    900: '#201f1c',
    800: '#2a2924',
    700: '#3a3831',
    600: '#5a5548',
    500: '#767165',
    400: '#a39e91',
    300: '#c8c4b8',
    200: '#e0ded7',
    150: '#efeee9',
    100: '#f6f5f2',
    50: '#fcfcfa',
    0: '#ffffff',
  },
  magic: {
    800: '#5d4e27',
    700: '#736131',
    600: {
      DEFAULT: '#8a753c',
      alpha: {
        5: 'rgba(138, 117, 60, .22)',
        4: 'rgba(138, 117, 60, .16)',
        3: 'rgba(138, 117, 60, .10)',
        2: 'rgba(138, 117, 60, .06)',
        1: 'rgba(138, 117, 60, .02)',
      },
    },
    400: {
      DEFAULT: '#c3b389',
      alpha: {
        5: 'rgba(195, 179, 137, .36)',
        4: 'rgba(195, 179, 137, .28000000000000004)',
        3: 'rgba(195, 179, 137, .20)',
        2: 'rgba(195, 179, 137, .12)',
        1: 'rgba(195, 179, 137, .08)',
      },
    },
    300: '#d5c69f',
    200: '#e7dab6',
  },
}

export const green = {
  base: {
    1000: '#0c0f0e',
    950: '#161917',
    900: '#1a211d',
    800: '#1f2d24',
    700: '#293d31',
    600: '#405d4c',
    500: '#5b7a67',
    400: '#88a794',
    300: '#b4cbbc',
    200: '#d3e3d8',
    150: '#e7f1ea',
    100: '#f1f7f3',
    50: '#fafcfb',
    0: '#ffffff',
  },
  magic: {
    800: '#0A5736',
    700: '#036E43',
    600: {
      DEFAULT: '#188554',
      alpha: {
        5: 'rgba(24, 133, 84, .22)',
        4: 'rgba(24, 133, 84, .16)',
        3: 'rgba(24, 133, 84, .10)',
        2: 'rgba(24, 133, 84, .06)',
        1: 'rgba(24, 133, 84, .02)',
      },
    },
    400: {
      DEFAULT: '#77C599',
      alpha: {
        5: 'rgba(119, 197, 153, .36)',
        4: 'rgba(119, 197, 153, .28000000000000004)',
        3: 'rgba(119, 197, 153, .20)',
        2: 'rgba(119, 197, 153, .12)',
        1: 'rgba(119, 197, 153, .08)',
      },
    },
    300: '#95D5AF',
    200: '#BBE7CC',
  },
}

export const blue = {
  base: {
    1000: '#0c1316',
    950: '#111a1d',
    900: '#112228',
    800: '#0f2d37',
    700: '#183d4a',
    600: '#335c6b',
    500: '#547886',
    400: '#84a4b1',
    300: '#b0c9d3',
    200: '#d1e1e8',
    150: '#e4f0f5',
    100: '#eef7fa',
    50: '#f8fcfd',
    0: '#ffffff',
  },
  magic: {
    800: '#075462',
    700: '#00697b',
    600: {
      DEFAULT: '#007f95',
      alpha: {
        5: 'rgba(0, 120, 185, .22)',
        4: 'rgba(0, 120, 185, .16)',
        3: 'rgba(0, 120, 185, .10)',
        2: 'rgba(0, 120, 185, .06)',
        1: 'rgba(0, 120, 185, .02)',
      },
    },
    400: {
      DEFAULT: '#77becf',
      alpha: {
        5: 'rgba(118, 189, 255, .36)',
        4: 'rgba(118, 189, 255, .28000000000000004)',
        3: 'rgba(118, 189, 255, .20)',
        2: 'rgba(118, 189, 255, .12)',
        1: 'rgba(118, 189, 255, .08)',
      },
    },
    300: '#8fd0e1',
    200: '#a9e6f6',
  },
}

export const purple = {
  base: {
    1000: '#11101a',
    950: '#181623',
    900: '#1f1d2d',
    800: '#28253b',
    700: '#383255',
    600: '#554e7b',
    500: '#716b96',
    400: '#9d98c3',
    300: '#c3c0df',
    200: '#dddbf4',
    150: '#edecfb',
    100: '#f5f4fd',
    50: '#fbfbff',
    0: '#ffffff',
  },
  magic: {
    800: '#3442a6',
    700: '#4354c8',
    600: {
      DEFAULT: '#5266eb',
      alpha: {
        5: 'rgba(82, 102, 235, .22)',
        4: 'rgba(82, 102, 235, .16)',
        3: 'rgba(82, 102, 235, .10)',
        2: 'rgba(82, 102, 235, .06)',
        1: 'rgba(82, 102, 235, .02)',
      },
    },
    400: {
      DEFAULT: '#9cb4e8',
      alpha: {
        5: 'rgba(156, 180, 232, .36)',
        4: 'rgba(156, 180, 232, .28000000000000004)',
        3: 'rgba(156, 180, 232, .20)',
        2: 'rgba(156, 180, 232, .12)',
        1: 'rgba(156, 180, 232, .08)',
      },
    },
    300: '#b4c9f8',
    200: '#cdddff',
  },
}

export const neutral = {
  base: {
    1000: '#10101a',
    950: '#171721',
    900: '#1e1e2a',
    800: '#272735',
    700: '#363644',
    600: '#535461',
    500: '#70707d',
    400: '#9d9da8',
    300: '#c3c3cc',
    200: '#dddde5',
    150: '#ededf3',
    100: '#f4f5f9',
    50: '#fbfcfd',
    0: '#ffffff',
  },
  magic: {
    800: '#4a4c64',
    700: '#5c5f7b',
    600: {
      DEFAULT: '#707393',
      alpha: {
        5: 'rgba(112, 115, 147, .22)',
        4: 'rgba(112, 115, 147, .16)',
        3: 'rgba(112, 115, 147, .10)',
        2: 'rgba(112, 115, 147, .06)',
        1: 'rgba(112, 115, 147, .02)',
      },
    },
    400: {
      DEFAULT: '#afb2ce',
      alpha: {
        5: 'rgba(175, 178, 206, .36)',
        4: 'rgba(175, 178, 206, .28000000000000004)',
        3: 'rgba(175, 178, 206, .20)',
        2: 'rgba(175, 178, 206, .12)',
        1: 'rgba(175, 178, 206, .08)',
      },
    },
    300: '#c2c5de',
    200: '#d6d9ef',
  },
}

export const transparent = {
  transparent: 'transparent',
}

export const colors = {
  red,
  orange,
  beige,
  green,
  blue,
  purple,
  neutral,
  transparent,
}
