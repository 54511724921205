import {tv} from 'tailwind-variants'

const grid = tv({
  base: 'grid max-w-[1888px] grid-cols-12 gap-x-16 px-s5 lg:grid-cols-16 lg:gap-x-32',
  variants: {
    margins: {
      true: 'mx-24 lg:mx-32 3xl:mx-auto',
    },
  },
  defaultVariants: {
    margins: false,
  },
})

export const gridItem = tv({
  base: 'col-span-full',
  variants: {
    size: {
      max: 'col-span-full lg:col-span-12 lg:col-start-3',
      heading: 'col-span-full md:col-span-8 md:col-start-3 lg:col-start-5',
    },
  },
})

export default grid
