import {useId} from 'react'
import {tv} from 'tailwind-variants'
import {
  RadioGroupItem,
  type RadioGroupItemProps,
} from '~/design-system/foundations/Radio'

const hgRadioVariants = tv({
  slots: {
    radioGroup:
      'group peer h-20 w-20 shrink-0 rounded-full border border-solid border-border-input bg-surface-input hover:bg-surface-input-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-border-focus disabled:bg-surface-input-disabled [&:hover:not([data-disabled])_>_.indicator]:border-surface-primary-hover',
    label:
      'pl-8 text-text-subdued arcadia-ui-1 hover:cursor-pointer hover:text-text-default peer-focus-visible:text-text-default peer-disabled:cursor-default peer-disabled:!text-text-disabled peer-data-[state=checked]:text-text-default',
    indicator:
      'indicator h-full w-full rounded-full border-[6px] bg-icon-on-primary group-disabled:border-surface-primary-disabled data-[state=checked]:border-surface-primary',
  },
  variants: {
    focused: {
      true: {
        radioGroup: 'outline outline-2 outline-offset-2 outline-border-focus',
        label: 'text-text-default',
      },
    },
    hovered: {
      true: {
        radioGroup: 'bg-surface-input-hover',
        label: 'text-text-default',
        indicator:
          'border-surface-primary-hover data-[state=checked]:border-surface-primary-hover',
      },
    },
  },
})

export type HgRadioButtonProps = {
  label: string
  selected?: boolean
  focused?: boolean
  hovered?: boolean
} & Omit<RadioGroupItemProps, 'indicator' | 'id'>

export default function HgRadioButton({
  label,
  selected,
  ...props
}: HgRadioButtonProps) {
  const id = useId()
  const {
    label: labelClassname,
    radioGroup,
    indicator,
  } = hgRadioVariants({
    focused: props.focused,
    hovered: props.hovered,
  })
  return (
    <div className="flex items-center">
      <RadioGroupItem
        className={radioGroup()}
        id={id}
        indicator={<div className={indicator()} />}
        checked={selected}
        {...props}
      />
      <label htmlFor={id} className={labelClassname()}>
        {label}
      </label>
    </div>
  )
}
