import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/design-system/foundations/Dropdown'
import {type HgBreadcrumbProps} from '.'
import HgIcon from '../HgIcon'
import HgTextLink from '../HgTextLink'

export function HgBreadcrumbOverflowMenu({breadcrumbs}: HgBreadcrumbProps) {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger
        className="flex h-32 w-32 items-center justify-center rounded-full text-text-subdued arcadia-ui-1 hover:bg-surface-elevated-hover"
        aria-label="Show hidden links"
        title="Show hidden links"
      >
        <HgIcon iconType="ellipsis" size="regular" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="group z-10 mt-1 rounded-2xl bg-surface-elevated shadow-[0px_0px_6px_0px_rgba(86,86,118,0.10)] motion-safe:data-[state=closed]:animate-scaleOut motion-safe:data-[state=open]:animate-scaleIn">
        <div className="flex flex-col p-s1 motion-safe:group-data-[state=open]:animate-enterMenu">
          {breadcrumbs.map((breadcrumb, index) => (
            <DropdownMenuItem
              key={index}
              className="px-s3 py-8 outline-offset-0"
              asChild
            >
              <HgTextLink href={breadcrumb.link.href}>{breadcrumb.label}</HgTextLink>
            </DropdownMenuItem>
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
