'use client'

import {useEffect, useRef, useState} from 'react'
import {semanticSpacingToCssVarMap} from '~/design-system/hg/tokens'
import {cn} from '~/design-system/utils'

export type MovingCards = React.ReactNode[]

type InfiniteMovingCardsProps = {
  items: MovingCards
  direction?: 'left' | 'right'
  /**
   * How many seconds it should take for each item in the marquee to scroll across the screen
   */
  itemScrollDurationSeconds?: number
  pauseOnHover?: boolean
  itemGap: keyof typeof semanticSpacingToCssVarMap
  classNames?: {
    container?: string
    scroller?: string
    scrollerItem?: string
  }
}

export const InfiniteMovingCards = ({
  items,
  direction = 'left',
  itemScrollDurationSeconds = 10,
  pauseOnHover = false,
  itemGap,
  classNames,
}: InfiniteMovingCardsProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollerRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    function addAnimation() {
      if (!containerRef.current || !scrollerRef.current) return
      const scrollerContent = Array.from(scrollerRef.current.children)

      setDirection()
      setSpeed(scrollerContent.length)
      setItemGap()
      setStart(true)
    }

    function setDirection() {
      if (!containerRef.current) return
      containerRef.current.style.setProperty(
        '--animation-direction',
        direction === 'left' ? 'forwards' : 'reverse'
      )
    }

    function setSpeed(itemCount: number) {
      if (!containerRef.current) return
      const totalDurationSeconds = itemScrollDurationSeconds * itemCount
      containerRef.current.style.setProperty(
        '--animation-duration',
        `${totalDurationSeconds}s`
      )
    }

    function setItemGap() {
      if (!containerRef.current) return
      containerRef.current.style.setProperty(
        '--gap',
        semanticSpacingToCssVarMap[itemGap]
      )
    }

    addAnimation()
  }, [direction, itemScrollDurationSeconds, itemGap])
  const [start, setStart] = useState(false)

  return (
    <div
      ref={containerRef}
      className={cn(
        'relative motion-safe:overflow-hidden motion-safe:[mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)] motion-reduce:overflow-x-auto',
        classNames?.container
      )}
    >
      <ul
        ref={scrollerRef}
        className={cn(
          classNames?.scroller,
          start &&
            'motion-safe:animate-[scroll_var(--animation-duration)_var(--animation-direction)_linear_infinite]',
          pauseOnHover && 'hover:[animation-play-state:paused]',
          'flex w-max gap-[var(--gap)]'
        )}
        tabIndex={0}
      >
        {items.map((item, idx) => (
          <li className={cn(classNames?.scrollerItem)} key={idx}>
            {item}
          </li>
        ))}
        {items.map((item, idx) => (
          <li
            aria-hidden="true"
            className={cn(
              classNames?.scrollerItem,
              'motion-reduce:aria-hidden:hidden'
            )}
            key={idx}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}
