'use client'

import grid, {gridItem} from '~/design-system/foundations/Grid'
import {HgAspectRatio} from '../../components'

export type HgFeaturedCTAProps = {
  assetTwoOneRatio: React.ReactNode
  assetFourFiveRatio: React.ReactNode
}

export default function HgFeaturedCTA(props: HgFeaturedCTAProps) {
  const {assetTwoOneRatio, assetFourFiveRatio} = props

  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <HgAspectRatio
          aspectRatio="2:1"
          topSpacing="s9"
          classNames={{
            wrapper: gridItem({
              size: 'max',
              class: 'col-span-full hidden sm:inline-flex',
            }),
          }}
        >
          {assetTwoOneRatio}
        </HgAspectRatio>
        <HgAspectRatio
          aspectRatio="4:5"
          topSpacing="s9"
          classNames={{
            wrapper: gridItem({
              size: 'max',
              class: 'col-span-full sm:hidden',
            }),
          }}
        >
          {assetFourFiveRatio}
        </HgAspectRatio>
      </div>
    </div>
  )
}
