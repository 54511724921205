import {type PropsWithChildren} from 'react'
import {gridItem, TextTag} from '~/design-system/foundations'
import {tv, type VariantProps} from 'tailwind-variants'
import {Slot} from '@radix-ui/react-slot'

const headingVariants = tv({
  base: 'text-text-default',
  variants: {
    tag: {
      h2: 'arcadia-subheading-2',
      h3: 'arcadia-subheading-5',
      h4: 'arcadia-heading-9',
    },
  },
})

type Tag = NonNullable<VariantProps<typeof headingVariants>['tag']>

export type HgHeadingTagProps = PropsWithChildren<{
  tag: Tag
  className?: string
}>

export const HgHeadingTag = ({tag, className, children}: HgHeadingTagProps) => {
  return (
    <TextTag className={headingVariants({tag, className})} tag={tag}>
      {children}
    </TextTag>
  )
}

export const HgArticleWYSIWYGHeading = (props: HgHeadingTagProps) => {
  return (
    <Slot className={gridItem({size: 'heading'})}>
      <HgHeadingTag {...props} />
    </Slot>
  )
}
